import {  Dialog } from "@material-ui/core";
import { useState } from "react";
import { 
  useStyles, 
  ButtonsWrapper, 
  Container, 
  Title, 
  Descroption, 
  CheckboxList,
  Content,
  RdiosWrapper
} from "./styles";

import {
  Button,
   FormControl,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core";

import { CgExport } from "react-icons/cg"
import ReportCheckbox from "./components/Checkbox";
import CustomToggleDatePicker from "../CustomToggleDatePicker";
import { exportRoutine } from "./services";
import { Loading } from "../Loading";

const EXPORT_OPTIONS = ["xlsx", "pdf"]

type ModalDeleteProps = {
  headerToRows: {key: string, label: string}[],
  endpoint: string;
  filter?: object;
  title?: string
};
const GenericExport = ({
  headerToRows,
  endpoint,
  filter,
  title
}: ModalDeleteProps) => {
  const [selectedOption, setselectedOption] = useState("xlsx")
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const [headerToUse, setHeaderToUse] = useState(headerToRows?.map(item => ({ ...item, show: true })))

  const handleExport = async () => {
    const formatDate = headerToUse?.filter(item => item.show)

    await exportRoutine(
      setLoading, 
      `export-${endpoint}-${selectedOption}`, 
      formatDate?.map(item => ({ key: item?.key, header: item?.label})),
      () => setOpenModal(false),
      filter
    )
  }

  return (
    <>
      <Button
        startIcon={<CgExport size={16} color="#fff" />}
        className={classes.buttonExport}
        onClick={() => setOpenModal(true)}
      >
        Exportar
      </Button>
    
      <Dialog open={openModal} onClose={() => null} style={{ zIndex: 10 }}>
        <Container>
          <Content>
            <CgExport size={36} />
            <Title>{title || "Exportar dados"}</Title>

            <Descroption>Selecione os campos que deseja exportar</Descroption>

            <CheckboxList>
              {headerToUse?.map((item, index) => {
                //console.log("O valor:",item)
                return(
                  <ReportCheckbox
                    key={`row-${index}`}
                    label={item?.label}
                    checked={item?.show}
                    value={item.key as any}
                    onToggle={isActive => {
                      //console.log("Mudando:", isActive)
                      let prevData = headerToUse
                      prevData[index] = {
                        ...item,
                        show: isActive
                      }
                      setHeaderToUse(prevData)
                    }}
                  />
                )
              })}
            </CheckboxList>

            {/* <RdiosWrapper>

              <CustomToggleDatePicker
                label="Selcionar Intervalo de tempo"
                //style={{marginLeft: 10}}
                value={new Date()}
                rangeValues={undefined}
                onChangeDate={e => {
                  
                 // handleAddParamsToQuery("emissao", e ? moment(new Date(e)).format('YYYY-MM-DD') : null as unknown as string)
                }}
                onClear={() => {}}
                onChangeDateRange={e => {
                  
                }}
              />
            </RdiosWrapper> */}

            <RdiosWrapper style={{ marginTop: 30, marginBottom: 30 }}>
              {/* <ReportCheckbox
                isRadio
                label={"XLSX"}
                checked={selectedOption === "xlsx"}
                onToggle={isChecked => setselectedOption(isChecked ?  "xlsx" : "pdf")}
              />
              <ReportCheckbox
                isRadio
                label={"PDF"}
                checked={selectedOption === "pdf"}
                onToggle={isChecked => setselectedOption(isChecked ? "pdf" : "xlsx")}
              /> */}
            
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Opções</InputLabel>
                <Select
                  style={{maxHeight: 40, display: "flex", alignItems: "center"}}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedOption}
                  defaultValue="xlsx"
                  onChange={e => {
                    setselectedOption(e.target.value as any)
                  }}
                  label="Opções"
                >
                  {EXPORT_OPTIONS.map(option => 
                    <MenuItem key={option} value={option}>
                      {option?.toUpperCase()}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </RdiosWrapper>
          </Content>

          <ButtonsWrapper> 
            <Button
              className={classes.buttons}
              style={{ backgroundColor: "#FF3C40", color: "#fff" }}
              onClick={() => setOpenModal(false)}
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttons}
              style={{ backgroundColor: "#6FED8B", color: "#fff" }}
              onClick={() => handleExport()}
            >
              {loading ? <Loading /> : "Exportar"}
            </Button>
          </ButtonsWrapper>
        </Container>
      </Dialog>
    </>
  );
};
export default GenericExport