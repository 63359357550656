import { Grid, Typography } from "@material-ui/core";
import { ImageOutlined as ImageIcon } from "@material-ui/icons/";
import { CustomSwitch, useStyles } from "./styles";
import ImageComponent from "../ImageComponent";

interface ImageModel {
  imageName: string;
  imagePath: string;
  fileName: string;
  required: boolean;
  order: number;
  imagem_name?: string
}

type ImageCheckboxProps = {
  image: ImageModel;
  switchName: string;
  switchChecked: boolean;
  switchOnChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  disabled: boolean;
};

export const ImageCheckbox = ({
  image,
  switchName,
  switchChecked,
  switchOnChange,
  disabled,
}: ImageCheckboxProps) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Typography className={classes.imageText}>{image?.imageName || image?.imagem_name}</Typography>
      <div
        className={classes.imageContainer}
        style={{ border: image.imagePath ? "none" : "2px dashed #BCBCBC" }}
      >
        {image.imagePath ? (
          <ImageComponent 
            imageKey={image.imagePath} 
            imageStyles={{maxWidth: "100%"}}
            onlyImage
            isVideo={false}
          />
        ) : (
          <>
            <ImageIcon />
            <Typography>Imagem</Typography>
          </>
        )}
      </div>
    
      <div className={classes.switchContainer}>
        <CustomSwitch
          name={switchName}
          checked={switchChecked}
          onChange={switchOnChange}
          disabled={disabled}
        />
        <Typography className={classes.switchText}>Obrigatório</Typography>
      </div>
    </Grid>
  );
};
