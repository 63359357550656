import { baseURL } from "../../../services/api";
import { successMessage } from "../../Messages";
import { askToGenerateReport } from "./askToGenerateReport";
import { getStatus } from "./getStatus";

export const exportRoutine = async (
  toggleLoading: (loading: boolean) => void,
  endpoint: string, 
  columns: {header: string, key: string}[],
  onSuccess: () => void,
  filter?: object
  ) => {
    toggleLoading(true)
    try {
      const { status, code } = await askToGenerateReport(endpoint, columns, filter);
  
      if (status === 201) {
        const statusResponse = await getStatus(code);
  
        if (statusResponse.status === 200) {

          try {

            const hiddenElement = document.createElement('a');
          hiddenElement.href = `${baseURL}export/${code}`;
          hiddenElement.click();
    
          successMessage("Relatório gerado com sucesso");
          toggleLoading(false)
          onSuccess()
          }  catch (error){
            console.log("erro", error)
          }
        }
      }

      } catch (error) {
        console.log("erro", error)
      } finally {
        toggleLoading(false)
      }

}