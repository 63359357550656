import {
  createContext,
  Dispatch,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import { errorMessage } from "../components/Messages";
import { api } from "../services/api";
import moment from "moment";
import { useRequestSWR } from "../services/useSWR";
moment.locale("pt-br");

type Props = {
  children: ReactNode;
};

type Notification = {
  id: string;
  dateHour: string;
  osNumber: string;
  operationType: string;
  //   status: string;
  status: boolean;
};

type PropsNotificationsContext = {
  listNotifications: Notification[];
  //getNotifications: () => void;
  loading: boolean;
  total: number;
  totalUnread: number;
  pagination: {
    index: number;
    rowsPerPage: number;
    pagesQuantity: number;
  };
  handleChangePagination: (
    value: number
  ) => void;
  handleChangeRowsPerPage: (value: number) => void;
};

const SKIPLIMIT = 10;

const initialState = {
  listNotifications: [],
  //getNotifications: () => {},
  total: 0,
  totalUnread: 0,
  loading: true,
  pagination: {
    index: 1,
    rowsPerPage: 1,
    pagesQuantity: 0,
  },
  handleChangePagination: (value: number) => {},
  handleChangeRowsPerPage: () => {}
};

const NotificationsContext =
  createContext<PropsNotificationsContext>(initialState);

interface chackIfNotificaationWasReadProps {
  listOfRead: [{admin_id: string, didRead: boolean}], 
  userId: string,
  oldStatus?: boolean,
}

const chackIfNotificaationWasRead = (props : chackIfNotificaationWasReadProps) => {
  const {listOfRead, userId, oldStatus} = props;

  if(!listOfRead || !listOfRead?.length) {
    return oldStatus
  }

  if(listOfRead?.length <= 0 || !userId) {
    return false;
  }

  let getElement = listOfRead?.find(item => item.admin_id == userId.toString())

  return getElement?.didRead
}  

const NotificationsContextProvider = ({ children }: Props) => {
  //const [loading, setLoading] = useState<boolean>(true);
  const [listNotifications, setListNotifications] = useState<Notification[]>(
    []
  );
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [index, setIndex] = useState<number>()
  const [total, setTotal] = useState<number>(0)
  const [totalUnread, setTotalUnread] = useState<number>(0);
  const [pagesQuantity, setPagesQuantity] = useState(0)

  const UserStorage: string = localStorage?.getItem("userId") as string
  
  const {data, loading} = useRequestSWR(`notificacoes/admin?skip=${index || 0}&limit=${rowsPerPage}`, { refreshInterval: 5000 })  

  const handleChangePagination = (value: number) => {
    setIndex(value)
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value)
  }

  useEffect(() => {
    if(data) {
      setListNotifications(
        data?.results?.map((result: any) => ({
          id: result._id,
          dateHour: moment(result.createdAt).format("DD/MM/YYYY - HH:mm"),
          osNumber: result.os_numero,
          operationType: result.operacao,
          status: chackIfNotificaationWasRead({
            listOfRead: result?.readList, 
            userId: JSON.parse(UserStorage), 
            oldStatus: result?.status
          }),
          os_id: result?.os_id,
          readList: result?.readList
        }))
      );

      setTotal(data?.total)
      setTotalUnread(data?.totalUnread)
      setPagesQuantity(data?.total ? Math.ceil(data?.total / rowsPerPage) : 0)
    }
  
  }, [data])

  return (
    <NotificationsContext.Provider
      value={{
        listNotifications,
        loading,
        pagination: {
          index,
          rowsPerPage,
          pagesQuantity
        } as any,
        handleChangePagination,
        handleChangeRowsPerPage,
        total,
        totalUnread,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsContextProvider };
