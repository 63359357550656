import styled from 'styled-components';

interface ContainerPorops {
  color: string,
  bgColor: string,
}

export const Container = styled.div<ContainerPorops>`
  width: 100%;
  min-height: 50px;
  max-height: 55px;
  padding: 5px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 11px;
  color: ${({color}) => color };
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;

  background-color: ${({bgColor}) => bgColor};

  padding: 0 20px;

  @media (min-width: 1024px){
    height: 75px;
    font-size: 14px;
  }
`;

export const PaginationInfo = styled.span`
  margin: 0 10px;
`;

export const ButtonLeft = styled.button<{ disabled: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  background-color: transparent;

  cursor: pointer;

  pointer-events: ${({disabled}) => disabled ? 'none' : 'all'};

  &:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:disabled {
    background-color: transparent;
  }
`;

export const RightButton = styled(ButtonLeft)`
  //transform: rotate(180deg);
`;

export const RowsPerPageButton = styled.button`
  font-size: 14px;
  color: ${({color}) => color };
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;

  border: none;
  background: transparent;

  position: relative;

  margin-left: 5px;

  cursor: pointer;

  svg{
    margin-left: 6px;
  }
`;

export const RowsPerPageOptions = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: row;

  background-color: ${({bgColor}) => bgColor};

  padding: 10px;

  span{
    margin: 0 5px;
    cursor: pointer
  }
`;
