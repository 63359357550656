import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
  })
);
