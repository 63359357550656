import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: "flex",

      width: 180,
      fontSize: 14,
      fontWeight: "bold",

      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 24,
      paddingLeft: 24,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    popoverItem: {
      display: "flex",

      width: "100%",
      fontSize: 14,
      fontWeight: "bold",
      cursor: "pointer",
      padding: 8,
      color: "#808080",
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.1)",
      },
      textDecoration: "none",
    },
  })
);

