import { useState, useMemo, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  Paper,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  ImageOutlined as ImageIcon,
  Search as SearchIcon,
} from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { useStyles } from "./styles";
import { TablePagination } from "../../../../components/TablePagination";
import { useDrivers } from "../../../../hooks/Users/useDrivers";
import { dateToShow, inCreationOrEditing, onlyNumbers } from "../../../../utils";
import { InputMask } from "../../../../components/InputMask";
import { Loading } from "../../../../components/Loading";
import { api } from "../../../../services/api";
import ImageComponent from "../../../../components/ImageComponent";
import GenericTable from "../../../../components/GenericTable";
import GenericExport from "../../../../components/GenericExport";

const tableHeader = [
  {key: 'name', label: 'Ordem de Serviço'},
  {key: 'type', label: 'Tipo'},
  {key: 'emissao_data', label: 'Data de Emissão'},
  {key: 'status', label: 'Status'},
];

const tableHeaderToExport = [
  {key: 'numero', label: 'Ordem de Serviço'},
  {key: 'veiculo_name', label: 'Veículo'},
  {key: 'veiculo_placa', label: 'Placa do Veículo'},
  {key: 'programacao_name', label: 'Programação'},
  {key: 'checklist_name', label: 'Checklist'},
  {key: 'emissao_data', label: 'Data de Emissão'},
  {key: 'cliente_name', label: 'Cliente'},

  {key: 'navio_aviao', label: 'Navio/Avião'},
  {key: 'solicitante', label: 'Solicitante'},
  {key: 'ctac', label: 'CTAC/DI/Booking'},
  {key: 'observacao', label: 'Oservação'},
  {key: 'carga', label: 'Carga'},

  {key: 'status', label: 'Status'},
]

type ServiceOrder = {
  id: string;
  name: string;
  type: string;
  initialDate: string;
  finalDate: string;
  status: boolean;
};

export const DriverForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const {
    loading,
    setLoading,
    driverData,
    addDriver,
    updateDriver,
    getAll,
    getAllProfileType,
    handleSearchUserDriver,
  } = useDrivers();

  const [searchCpf, setSearchCpf] = useState("");

  const [totalItems, setTotalItems] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [index, setIndex] = useState(0)

  const [loadingSOs, setLoadingSOS] = useState(false);

  const [listServiceOrder, setListServiceOrder] = useState<ServiceOrder[]>([]);

  const filterToExport = {
    assignedTo: driverData.id,
  }

  const getServiceOrdersByDriver = async () => {
    setLoadingSOS(true)
    try {
      const { data } = await api.get(
        `ordem-servico/byAssignement?skip=${index}&limit=${rowsPerPage}&assignedTo=${driverData.id}`
      );
    
      setListServiceOrder(
        data.data.results.map((result: any) => ({
          id: result?._id,
          name: result?.numero,
          type: result?.programacao?.nome || "",
          status: result?.status,
          returnStatus: result?.status,
          emissao_data: dateToShow(result?.emissao_data),
          //driver: result?.motorista?.nome,
        }))
      );
      setTotalItems(data?.data?.total || 0)
    } catch (error) {
      console.log(error);
    } finally {
       setLoadingSOS(false)
    }
  };

  useEffect(() => {
    if (driverData.id) {
      getServiceOrdersByDriver();
    }
  }, [driverData, rowsPerPage, index]);

  useEffect(() => {
    if (id) {
      getAll(id);
    }
    if (location.pathname.includes("new")) {
      getAllProfileType();
      setLoading(false);
    }
  }, [id, location]);

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar motorista";
    } else if (location.pathname.includes("edit")) {
      return "Editar motorista";
    } else {
      return "Motorista";
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [showDataSection, setShowDataSection] = useState(true);

  if (!driverData.idUser && location.pathname.includes("new")) {
    return (
      <Grid container spacing={2}>
        <Grid item lg={3}>
          <Typography className={classes.pageTitle}>Motoristas</Typography>
        </Grid>
        <Grid item lg={12}>
          <Paper className={classes.paper}>
            <div className={classes.actionBar}>
              <div className={classes.actionBarLeftContent}>
                <IconButton
                  onClick={() => navigate("/drivers")}
                  className={classes.buttonBack}
                >
                  <KeyboardArrowLeftIcon fontSize="medium" />
                </IconButton>
                <Typography className={classes.pageSubtitle}>
                  {returnedTitlePage(location)}
                </Typography>
              </div>

              <div>
                <Button
                  className={classes.buttonSave}
                  onClick={() => {
                    handleSearchUserDriver(onlyNumbers(searchCpf));
                  }}
                >
                  Avançar
                </Button>
                <Button
                  className={classes.buttonDiscard}
                  onClick={() => navigate("/drivers")}
                >
                  Descartar
                </Button>
              </div>
            </div>

            <div
              className={classes.actionTab}
              style={{
                display: location.pathname.includes("new") ? "none" : "flex",
              }}
            >
              <Typography
                style={{
                  marginRight: 8,
                }}
                className={classes.actionTabText2}
              >
                Dados pessoais
              </Typography>
              <Typography className={classes.actionTabText2}>
                Histórico de ordens de serviço
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item lg={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={1} className={classes.formContainer}>
              <Grid item lg={12}>
                <InputLabel className={classes.inputLabel}>
                  Buscar por CPF
                </InputLabel>
                <TextField
                  name="cpf"
                  value={searchCpf}
                  onChange={(e) => setSearchCpf(e.target.value)}
                  className={classes.searchBarInput}
                  placeholder="Pesquisar"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    inputComponent: InputMask as any,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearchUserDriver(onlyNumbers(searchCpf));
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={driverData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addDriver(values);
        } else {
          updateDriver(values);
        }
      }}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>Motoristas</Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/drivers")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    {!showDataSection && (
                      <GenericExport
                        headerToRows={tableHeaderToExport}
                        endpoint="motorista-os-history"
                        title="Exportar lista de histórico de OS"  
                        filter={filterToExport}
                      />
                    )}
                    <Button
                      type="submit"
                      className={classes.buttonSave}
                      style={{ marginLeft: 20 }}
                      disabled={loading}
                    >
                      {loading ? (
                        <Loading />
                      ) : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/drivers")}
                      disabled={loading}
                    >
                      Descartar
                    </Button>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    {!showDataSection && (
                      <GenericExport
                        headerToRows={tableHeaderToExport}
                        endpoint="motorista-os-history"
                        title="Exportar lista de histórico de OS"  
                        filter={filterToExport}
                      />
                    )}
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/drivers/${id}/edit`)}
                      style={{ marginLeft: 20 }}
                    >
                      Editar
                    </Button>
                  </div>
                </div>

                <div
                  className={classes.actionTab}
                  style={{
                    display: location.pathname.includes("new")
                      ? "none"
                      : "flex",
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 8,
                      color: showDataSection ? "#2F4D7C" : "",
                    }}
                    className={classes.actionTabText}
                    onClick={() => setShowDataSection(true)}
                  >
                    Dados pessoais
                  </Typography>
                  <Typography
                    style={{
                      color: showDataSection ? "" : "#2F4D7C",
                    }}
                    className={classes.actionTabText}
                    onClick={() => setShowDataSection(false)}
                  >
                    Histórico de ordens de serviço
                  </Typography>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
              <Paper className={classes.paper}>
                {showDataSection ? (
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados da Empresa
                      </Typography>
                    </Grid>

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="name"
                        value={values.name}
                        // onChange={handleChange}
                        placeholder="Nome*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        // required
                        disabled
                      />
                    </Grid>
                    {!location.pathname.includes("new") ? (
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          Data de cadastro
                        </InputLabel>
                        <TextField
                          name="createdAt"
                          value={values.createdAt}
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    ) : null}

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Crachá
                      </InputLabel>
                      <TextField
                        name="badge"
                        value={values.badge}
                        onChange={handleChange}
                        placeholder="Crachá"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>

                    {/* <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Senha padrão*
                      </InputLabel>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid> */}

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados da habilitação
                      </Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        CNH*
                      </InputLabel>
                      <TextField
                        name="cnhNumber"
                        value={values.cnhNumber}
                        onChange={handleChange}
                        placeholder="CNH*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    {/* <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Categoria da CNH*
                      </InputLabel>
                      <TextField
                        name="cnhCategory"
                        value={values.cnhCategory}
                        onChange={handleChange}
                        placeholder="Categoria da CNH"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid> */}
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Categoria da CNH*
                      </InputLabel>
                      <TextField
                        name="cnhCategory"
                        value={values.cnhCategory === "Selecionar" ? "" : values.cnhCategory}
                        onChange={handleChange}
                        variant="outlined"
                        placeholder="Selecionar"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!inCreationOrEditing(location)}
                      >
                        <MenuItem value="Selecionar" disabled>
                          Selecionar
                        </MenuItem>
                        {listCnhCategory.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Validade da CNH*
                      </InputLabel>
                      <TextField
                        name="cnhExpirationDate"
                        value={values.cnhExpirationDate}
                        onChange={handleChange}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      style={{
                        display: location.pathname.includes("new")
                          ? "none"
                          : "flex",
                      }}
                    >
                      <Typography className={classes.formSectionTitle}>
                        Upload de Imagens
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      //lg={2}
                      style={{
                        display: location.pathname.includes("new")
                          ? "none"
                          : "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography className={classes.imageText}>
                        Imagem da CNH
                      </Typography>

                      <ImageComponent 
                        imageKey={values.imageCnh.imageKey || values.imageCnh.imagePath || ""}
                        alt="Imagem da CNH"
                        isDocument={values?.imageCnh?.isDocument}
                      />
                    </Grid>

                    <Grid
                      item
                     // lg={2}
                      style={{
                        display: location.pathname.includes("new")
                          ? "none"
                          : "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography className={classes.imageText}>
                        Comprovante de Endereço
                      </Typography>

                      <ImageComponent 
                        imageKey={values.imageAddress.imageKey || values.imageAddress.imagePath || ""}
                        alt="Comprovante de Endereço"
                        isDocument={values?.imageAddress?.isDocument}
                      />

                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <GenericTable
                        header={tableHeader}
                        data={listServiceOrder}
                        isLoading={loadingSOs}
                        onClick={(item) => navigate(`/service-order/${item?.id}`)}
                        total={totalItems}
                        onChangeIndex={(index) =>  setIndex(index)}
                        onChangeRowsPerPage={(value) => setRowsPerPage(value)}
                      />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};

const listCnhCategory = ["A", "AB", "B", "C", "D", "E", "AC", "AD", "AE"];
