import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../components/Messages";
import { api } from "../services/api";
import { TypeModal } from "../types/managementServiceOrderTypes";

type ModalType = {
  id: string;
  name: string;
  status: boolean;
};

const SKIPLIMIT = 10;

export function useTypeModal() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [typeModalData, setTypeModalData] = useState<TypeModal>({
    id: "",
    name: "",
    status: "Ativo",
  });

  const getTypeModal = async (id: string) => {
    try {
      const { data } = await api.get(`modals/${id}`);
      setTypeModalData({
        id: data.data._id,
        name: data.data.nome,
        status: data.data.status ? "Ativo" : "Inativo",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addTypeModal = async (typeModalData: TypeModal) => {
    setLoading(true);
    try {
      await api.post("modals", {
        nome: typeModalData.name,
        status: typeModalData.status === "Ativo",
      });
      navigate("/modal-type");
      successMessage("Tipo de modal adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error.response.data.message[0]) {
        errorMessage(error.response.data.message[0])
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível adicionar tipo de modal");
      setLoading(false);
    }
  };

  const updateTypeModal = async (typeModalData: TypeModal) => {
    setLoading(true);
    try {
      await api.patch(`modals/${id}`, {
        nome: typeModalData.name,
        status: typeModalData.status === "Ativo",
      });
      navigate("/modal-type");
      successMessage("Tipo de modal atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar tipo de modal");
      setLoading(false);
    }
  };

  const deleteTypeModal = async (id: string) => {
    try {
      await api.delete(`modals/${id}`);
      getAllTypeModal();
      setRefresh(prev => !prev);
      successMessage("Tipo de carga excluído com sucesso!");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [listTypeModal, setListTypeModal] = useState<ModalType[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllTypeModal(value);
  };

  interface GetAllTypeOfModal {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number;
  }

  const getAllTypeOfModal = async <T>({name, paginationIndex, rowsPerPage = 10} : GetAllTypeOfModal) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)


    const nameToQuery = `${name ? `&nome=${name}` : ''}`;

    try {
      const response = await api.get(`modals?limit=${rowsPerPage}&skip=${paginationIndex}${nameToQuery}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result._id,
            name: result.nome,
            status: result.status,
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],  
      totalItems,
    };
  }

  const getAllTypeModal = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `modals?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListTypeModal(
        data.data.results.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    typeModalData,
    listTypeModal,
    getTypeModal,
    addTypeModal,
    updateTypeModal,
    deleteTypeModal,
    getAllTypeModal,
    loadingData,
    getAllTypeOfModal,
    refresh
  };
}
