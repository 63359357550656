import {
  Dispatch,
  Fragment,
  SetStateAction,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility as VisibilityIcon } from "@material-ui/icons/";
import {
  ServiceOrder,
  StepChecklist,
} from "../../../../../../types/managementServiceOrderTypes";
import { useStyles } from "./styles";
import { dateToShow } from "../../../../../../utils";
import ImageComponent from "../../../../../../components/ImageComponent";
import { CHECKLIST_STATUS } from "../../../../../../consts/checklistSteps";
import { useAnalyzeVision } from "../../../../../../contexts/AnalyzeVisionContext";
import { useLocation as useRouterLocation, useParams } from "react-router-dom";
import { Loading } from "../../../../../../components/Loading";
import { useServiceOrder } from "../../../../../../hooks/useServiceOrder";

type ChecklistStepProps = {
  isClient?: boolean;
  setServiceOrderData?: Dispatch<SetStateAction<ServiceOrder>>;
  step: any;
  hideStepButtons: boolean;
  handleOpenModal: (step: StepChecklist) => void;
  setModalType: (type: "start" | "validate") => void;
  handleChangeValidationModalTitle?: (txt: string) => void;
  index: number;
};

const feedBack = (
  value: string,
  status: string,
  startedAtAdmin: boolean,
  validatedAtAdmin: boolean
) => {
  if (status === CHECKLIST_STATUS.CONCLUIDO) {
    if (validatedAtAdmin) return "Concluída no Admin";

    return value || "Sem informação";
  }

  if (status === CHECKLIST_STATUS["EM ANDAMENTO"]) {
    if (startedAtAdmin) return "Iniciada no Admin";

    return value || "Sem informação";
  }

  return value || "Aguardando informação do motorista";
};

export const ChecklistStep: FC<ChecklistStepProps> = ({
  step,
  hideStepButtons,
  handleOpenModal,
  setModalType,
  isClient,
  handleChangeValidationModalTitle,
  index,
}) => {
  const classes = useStyles();
  const { setCurrentIndexStep, currentStepList, setCurrentStepList } =
    useAnalyzeVision();
  const { getServiceOrder, updateServiceOrder } = useServiceOrder();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useRouterLocation();
  const { id } = useParams();

  const updateVisionFields = async () => {
    if (id) {
      const serviceOrderResponse = await getServiceOrder(id);
      if (serviceOrderResponse) {
        serviceOrderResponse.checklist.steps = currentStepList;

        await updateServiceOrder(serviceOrderResponse);
      }
    }
  };

  return (
    <Fragment key={step.order}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography className={classes.overviewSectionSubtitle}>
          Etapa: {step.name}
        </Typography>

        {!isClient && (
          <div>
            <Typography
              className={classes.validateStep}
              onClick={() => {
                handleOpenModal(step);
                if (step.status === CHECKLIST_STATUS.PENDENTE) {
                  setModalType("start");
                  return;
                }
                handleChangeValidationModalTitle &&
                  handleChangeValidationModalTitle(
                    `Tem certeza de que deseja validar a etapa ${step?.name}`
                  );
                setModalType("validate");
              }}
              style={{
                display:
                  step.status === CHECKLIST_STATUS.CONCLUIDO || hideStepButtons
                    ? "none"
                    : "flex",
              }}
            >
              {step.status === CHECKLIST_STATUS.PENDENTE
                ? "Iniciar Etapa"
                : "Validar Etapa"}
            </Typography>
            <Typography
              className={classes.validateStep}
              onClick={() => {
                handleOpenModal(step);
                handleChangeValidationModalTitle &&
                  handleChangeValidationModalTitle(
                    `Tem certeza de que deseja atualizar imagens da etapa ${step?.name}`
                  );
                setModalType("validate");
                setCurrentIndexStep(index);
              }}
              style={{
                display:
                  hideStepButtons || step.status === CHECKLIST_STATUS.PENDENTE
                    ? "none"
                    : "flex",
              }}
            >
              Alterar algum arquivo
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.imageInfoContainer}>
        {(step as any).status === "EM ANDAMENTO" && (
          <Typography className={classes.stepValue}>
            Etapa em andamento
          </Typography>
        )}
        <div className={classes.row}>
          <Typography>Localização da conclusão da etapa: </Typography>{" "}
          <Typography className={classes.stepValue}>
            {feedBack(
              step?.location?.latitude &&
                ` ${step.location.latitude || "00.000000"}, ${
                  step.location.longitude || "00.000000"
                }`,
              step.status,
              step?.startedAtAdmin || false,
              step?.validatedAtAdmin || false
            )}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography>Data/Hora de início: </Typography>{" "}
          <Typography className={classes.stepValue}>
            {feedBack(
              dateToShow(step?.startDate || "", true),
              step.status,
              step?.startedAtAdmin || false,
              step?.validatedAtAdmin || false
            )}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography>Data/Hora da conclusão: </Typography>{" "}
          <Typography className={classes.stepValue}>
            {feedBack(
              dateToShow(step?.date || "", true),
              step.status,
              step?.startedAtAdmin || false,
              step?.validatedAtAdmin || false
            )}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography>Desempenho da etapa: </Typography>{" "}
          <Typography className={classes.stepValue}>
            {feedBack(
              step?.statusMessage || "",
              step.status,
              step?.startedAtAdmin || false,
              step?.validatedAtAdmin || false
            )}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography>Descrição do motorista: </Typography>{" "}
          <Typography className={classes.stepValue}>
            {feedBack(
              step?.driverDescription,
              step.status,
              step?.startedAtAdmin || false,
              step?.validatedAtAdmin || false
            )}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography>Classificação da etapa: </Typography>{" "}
          <Typography className={classes.stepValue}>
            {" "}
            {(step as any)?.classification || ""}
          </Typography>
        </div>
      </div>

      <div
        style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: 20 }}
      >
        {step?.images?.length
          ? step?.images?.map((image: any, index: number) => {
              return (
                <div key={`image-${index}`}>
                  <Grid item style={{ width: "100%" }}>
                    <Typography className={classes.overviewSectionSubtitle}>
                      {image?.isVideo ? "Video:" : "Imagem:"}
                      <Typography className={classes.imageName}>
                        {image?.imageName}
                      </Typography>
                    </Typography>
                  </Grid>
                  {image?.imagePath && (
                    <ImageComponent
                      imageKey={image.imagePath}
                      imageStyles={{ maxWidth: 400 }}
                      onlyImage
                      isVideo={image?.isVideo}
                    />
                  )}
                  <Grid item style={{ width: "100%" }}>
                    {image?.fileName && (
                      <div className={classes.imageInfoContainer}>
                        <Typography className={classes.stepValue}>
                          {image.fileName}
                        </Typography>
                        <VisibilityIcon className={classes.stepIcon} />
                      </div>
                    )}
                  </Grid>
                </div>
              );
            })
          : null}
      </div>
      {currentStepList[index]?.images.map((img) => {
        if (img.imageName == "PORTA DO CONTAINER") {
          return (
            <div className={classes.containerDataWrapper}>
              <div>
                <InputLabel className={classes.inputLabel}>
                  CÓDIGO DO CONTAINER*
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  value={currentStepList[index]?.containerCode}
                  placeholder="ABC123"
                  disabled={!location.pathname.includes("edit")}
                  onChange={(e) => {
                    const updatedChecklist = [...currentStepList];
                    updatedChecklist[index] = {
                      ...updatedChecklist[index],
                      containerCode: e.target.value,
                    };
                    setCurrentStepList(updatedChecklist);
                  }}
                />
              </div>
              <div>
                <InputLabel className={classes.inputLabel}>
                  MAX. GROSS*
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  value={currentStepList[index]?.maxGross}
                  placeholder="0"
                  disabled={!location.pathname.includes("edit")}
                  onChange={(e) => {
                    const updatedChecklist = [...currentStepList];
                    updatedChecklist[index] = {
                      ...updatedChecklist[index],
                      maxGross: e.target.value,
                    };
                    setCurrentStepList(updatedChecklist);
                  }}
                />
              </div>
              <div>
                <InputLabel className={classes.inputLabel}>TARE*</InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  value={currentStepList[index]?.tare}
                  placeholder="0"
                  disabled={!location.pathname.includes("edit")}
                  onChange={(e) => {
                    const updatedChecklist = [...currentStepList];
                    updatedChecklist[index] = {
                      ...updatedChecklist[index],
                      tare: e.target.value,
                    };
                    setCurrentStepList(updatedChecklist);
                  }}
                />
              </div>
              {location.pathname.includes("edit") && (
                <div className={classes.WrapperBtnAtualizar}>
                  <Button
                    className={classes.btnAtualizar}
                    variant="text"
                    disabled={loading}
                    onClick={() => updateVisionFields()}
                  >
                    {loading ? <Loading /> : "Atualizar"}
                  </Button>
                </div>
              )}
            </div>
          );
        } else if (img.imageName == "FOTO DO LACRE DA PORTA")
          return (
            <div className={classes.lacreDataWrapper}>
              <InputLabel className={classes.inputLabel}>
                CÓDIGO LACRE*
              </InputLabel>
              <TextField
                variant="outlined"
                size="small"
                value={currentStepList[index]?.sealCode}
                placeholder="ABC123"
                disabled={!location.pathname.includes("edit")}
                onChange={(e) => {
                  const updatedChecklist = [...currentStepList];
                  updatedChecklist[index] = {
                    ...updatedChecklist[index],
                    sealCode: e.target.value,
                  };
                  setCurrentStepList(updatedChecklist);
                }}
              />
            </div>
          );
      })}

      <Grid item style={{ width: "100%" }}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>
    </Fragment>
  );
};
