import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: 24,
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    pageSubtitle: {
      color: "#252525",
      fontSize: 20,
      marginLeft: theme.spacing(2),
    },
    actionBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 12,
    },
    actionBarLeftContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    actionTab: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: 12,
      paddingLeft: 12,
      paddingBottom: 12,
    },
    actionTabText: {
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": {
        color: "rgba(47,77,124,0.8)",
      },
    },
    actionTabTextNew: {
      fontWeight: "bold",
    },
    inputError: {
      borderRadius: 5,
      border: "1px solid red !important",
      outline: "none",
      "&:focus": {
        border: "1px solid red !important",
      },
    },
    inputLabelError: {
      marginTop: 5,
      marginBottom: theme.spacing(1),
      color: "red",
    },
    buttonBack: {
      borderRadius: "20%",
      color: "#FFF",
      backgroundColor: "#2F4D7C",
      transition: ".5 ease",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
    },
    buttonSave: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonDiscard: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      marginLeft: theme.spacing(2),
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#FF3C40",
      "&:hover": {
        backgroundColor: "rgba(255,60,64,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonExport: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      marginRight: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#76A1E8",
      "&:hover": {
        backgroundColor: "#6991CF",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    imageText: {
      color: "#6A6A6A",
      fontWeight: 500,
      fontSize: 14,
    },
    imageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      gap: 10,

      width: 200,
      height: 200,
      marginTop: 8,
      marginBottom: 8,

      background: "#FFFFFF",

      border: "2px dashed #BCBCBC",
      borderRadius: 8,
    },
    searchBarInput: {
      width: 250,
      marginRight: 12,
    },
  })
);
