import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { AuthUserContext } from "../../contexts/AuthUserContext";
import { useStyles } from "./styles";

import imageShipper from "../../assets/fortallog_shipper.svg";
import imageLogo from "../../assets/fortallog_logo.svg";
import imageBackground from "../../assets/fortallog_background.svg";
import { api } from "../../services/api";
import { errorMessage, successMessage, warningMessage } from "../../components/Messages";
import { onlyNumbers } from "../../utils";
import { Loading } from "../../components/Loading";
import { LOCAL_STORAGE_KEY } from "../../consts";

interface LoginData {
  cpf: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
  token: string;
}

const removeSpecialCharacters = (str: string) => {
  if(!str) {
    return ''
  }

  return  str.replace(/[^\w\s]/gi, '')
}

export const Login = () => {
  const classes = useStyles();
  const { setIsSigned } = useContext(AuthUserContext);
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState("");
  const [code, setCode] = useState("");

  const [loginData, setLoginData] = useState<LoginData>({
    // cpf: "22469716080",
    cpf: "",
    // password: "12345678",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
    token: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  // const [showPassword, setShowPassword] = useState(false);
  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [step, setStep] = useState(1);
  const setCurrentStep = async (step: number) => {
    localStorage.setItem("STEP", JSON.stringify(step));
  };

  const getCurrentStep = async () => {
    const currentStep = localStorage.getItem("STEP");
    if (currentStep) {
      setStep(Number(currentStep));
    }
  };

  useEffect(() => {
    getCurrentStep();
  }, []);

  useEffect(() => {
    getCurrentStep();
  }, [step]);

  const handleLogin = async (loginData: LoginData) => {
    api.defaults.headers.common["Authorization"] = "";
    localStorage.clear();
    setLoading(true);

    const objectToUse = removeSpecialCharacters(loginData.cpf).length >= 14 ? {
      cnpj: removeSpecialCharacters(loginData.cpf),
      password: loginData.password,
    } : {
      cpf: removeSpecialCharacters(loginData.cpf),
      password: loginData.password,
    }

    try {
      const { data } = await api.post("auth/login", objectToUse);
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.data.access_token}`;


      if (data.data.usuario) {
        localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ID, JSON.stringify(data.data.usuario._id));
        localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_NAME, JSON.stringify(data?.data?.usuario?.nome || ""));
      }
      if(data?.data?.cliente) {
         localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_CLIENT_ID, JSON.stringify(data.data.cliente._id)); 
         localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_NAME, JSON.stringify(data?.data?.cliente?.nome || ""));           
      }
      localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_AUTH_TOKEN, data.data.access_token);
      localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_IS_SIGNED, JSON.stringify(true));
      localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE, JSON.stringify(data?.data?.usuario?.role?.nome || ""));

      setIsSigned(true);

      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data?.data?.access_token}`;
      setLoading(false);
    } catch (error: any) {
      if(error?.response?.data?.message[0]) {
        errorMessage(error?.response?.data?.message[0])
        setLoading(false);
        return
      }
      errorMessage("Erro ao tentar fazer login, por favor, tente novamente!")
      console.log(error);
      setLoading(false);
    }
  };

  const handleForgotPassword = async (loginData: LoginData) => {
    if (onlyNumbers(loginData.cpf).length !== 11) {
      return warningMessage("CPF precisa conter 11 dígitos!");
    }
    setLoading(true);
    try {
      const response = await api.post("auth/forgot-password", {
        cpf: onlyNumbers(loginData.cpf),
      });

      setToken(response?.data?.data?.token)

      setStep(3);
      setCurrentStep(3);
    } catch (error) {
      console.log(error);
    } finally {
      errorMessage("Erro ao tentar verificar CPF, por favor, tente novamente!")
      setLoading(false);
    }
  };

  const HandleResetPassword = async (loginData: LoginData) => {
    setLoading(true);
    try {
      await api.post("auth/reset-password", {
        new_password: loginData.confirmNewPassword,
        token,
        cpf: onlyNumbers(loginData.cpf),
        code
      });
      successMessage("Senha atualizada com sucesso");
      setStep(1);
      setCurrentStep(1);
    } catch (error) {
      console.log(error);
    } finally {
      errorMessage("Erro ao tentar Atualizar Senha, por favor, tente novamente!")
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={loginData}
      onSubmit={(values) => {
        if (step === 1) {
          handleLogin(values);
        } else if (step === 2) {
          handleForgotPassword(values);
        } else HandleResetPassword(values);
      }}
    >
      {({ handleChange, values, handleSubmit }) => (
        <FormikForm>
          <Grid
            container
            style={{
              height: "100vh",
              backgroundImage: `url(${imageBackground})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid item lg={5} className={classes.contentWrapper}>
              <div className={classes.content}>
                <img src={imageShipper} alt="fortallog" />
                {/* <Typography style={{ color: "#2F4D7C", fontSize: 36 }}>
                  Fortallog
                </Typography>
                <Typography style={{ color: "#FFF", fontSize: 24 }}>
                  Transporte de cargas
                </Typography> */}
              </div>
            </Grid>
            <Grid item lg={2} className={classes.divSpace} />
            <Grid item lg={5} className={classes.formContainer}>
              <img src={imageLogo} alt="fortallog" />
              <Paper className={classes.paper}>
                {step === 1 && (
                  <div className={classes.loginContentContainer}>
                    <div>
                      <InputLabel className={classes.inputLabel}>
                        CPF ou CNPJ (cliente)
                      </InputLabel>
                      <TextField
                        name="cpf"
                        value={values.cpf}
                        onChange={handleChange}
                        placeholder="CPF"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                      />
                    </div>
                    <div style={{ marginTop: 12 }}>
                      <InputLabel className={classes.inputLabel}>
                        Senha
                      </InputLabel>
                      <TextField
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        type={showPasswords.password ? "text" : "password"}
                        placeholder="Senha"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowPasswords((prevState) => ({
                                    ...prevState,
                                    password: !prevState.password,
                                  }))
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPasswords.password ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <Button
                      className={classes.buttonLogin}
                      onClick={() => handleSubmit()}
                      style={{ marginTop: 12 }}
                      fullWidth
                      disabled={loading}
                      type="submit"
                    >
                      {loading ? <Loading/> : "Entrar"}
                    </Button>

                    <div className={classes.resetPasswdContainer}>
                      <Typography style={{ fontSize: 14 }}>
                        Esqueceu senha?
                      </Typography>
                      <Typography
                        className={classes.resetPasswdText}
                        onClick={() => setStep(2)}
                      >
                        Solicitar
                      </Typography>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className={classes.loginContentContainer}>
                    <Typography className={classes.tipText}>
                      Digite seu CPF para solicitar nova senha
                    </Typography>
                    <div>
                      <InputLabel className={classes.inputLabel}>
                        CPF
                      </InputLabel>
                      <TextField
                        name="cpf"
                        value={values.cpf}
                        onChange={handleChange}
                        placeholder="CPF"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                      />
                    </div>

                    <Button
                      className={classes.buttonLogin}
                      onClick={() => handleSubmit()}
                      style={{ marginTop: 12 }}
                      fullWidth
                    >
                       {loading ? <Loading/> : "Enviar"}
                    </Button>

                    <div className={classes.resetPasswdContainer}>
                      <Typography style={{ fontSize: 14 }}>
                        Lembrou sua senha?
                      </Typography>
                      <Typography
                        className={classes.resetPasswdText}
                        onClick={() => setStep(1)}
                      >
                        Login
                      </Typography>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className={classes.loginContentContainer}>
                    <Typography className={classes.tipText}>
                      Digite o código de recuperação que você{"\n"} recebeu por
                      SMS
                    </Typography>
                    <div>
                      <InputLabel className={classes.inputLabel}>
                        Código
                      </InputLabel>
                      <TextField
                        name="token"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        placeholder="Código"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                      />
                    </div>

                    <Button
                      className={classes.buttonLogin}
                      onClick={() => {
                        setStep(4)
                        setCurrentStep(4);
                      }}
                      style={{ marginTop: 12 }}
                      fullWidth
                    >
                      Confirmar
                    </Button>

                    <div className={classes.resetPasswdContainer}>
                      <Typography style={{ fontSize: 14 }}>
                        Lembrou sua senha?
                      </Typography>
                      <Typography
                        className={classes.resetPasswdText}
                        onClick={() => setStep(1)}
                      >
                        Login
                      </Typography>
                    </div>
                  </div>
                )}
                {step === 4 && (
                  <div className={classes.loginContentContainer}>
                    <Typography className={classes.tipText}>
                      Digite sua nova senha
                    </Typography>
                    <div>
                      <InputLabel className={classes.inputLabel}>
                        Nova Senha
                      </InputLabel>
                      <TextField
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        type={showPasswords.newPassword ? "text" : "password"}
                        placeholder="Nova Senha"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowPasswords((prevState) => ({
                                    ...prevState,
                                    newPassword: !prevState.newPassword,
                                  }))
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPasswords.newPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginTop: 12 }}>
                      <InputLabel className={classes.inputLabel}>
                        Repetir nova senha
                      </InputLabel>
                      <TextField
                        name="confirmNewPassword"
                        value={values.confirmNewPassword}
                        onChange={handleChange}
                        type={
                          showPasswords.confirmNewPassword ? "text" : "password"
                        }
                        placeholder="Repetir nova senha"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowPasswords((prevState) => ({
                                    ...prevState,
                                    confirmNewPassword:
                                      !prevState.confirmNewPassword,
                                  }))
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPasswords.confirmNewPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <Button
                      className={classes.buttonLogin}
                      onClick={() => handleSubmit()}
                      style={{ marginTop: 12 }}
                      fullWidth
                    >
                      {loading ? <Loading/> : "Salvar"}
                    </Button>

                    <div className={classes.resetPasswdContainer}>
                      <Typography style={{ fontSize: 14 }}>
                        Lembrou sua senha?
                      </Typography>
                      <Typography
                        className={classes.resetPasswdText}
                        onClick={() => setStep(1)}
                      >
                        Login
                      </Typography>
                    </div>
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
