import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage, warningMessage } from "../components/Messages";
import { api } from "../services/api";
import { TypeOccurrency } from "../types/managementServiceOrderTypes";

type Occurrence = {
  id: string;
  name: string;
  photosAmount: string;
  status: boolean;
};

const SKIPLIMIT = 10;

export function useOccurrency() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [occurrencyData, setOccurrencyData] = useState<TypeOccurrency>({
    id: "",
    name: "",
    status: "Ativo",
    description: "",
    driverDescription: "Sim",
    photosQuantity: "",
    images: [],
  });

  const getOccurrency = async (id: string) => {
    try {
      const { data } = await api.get(`ocorrencias/${id}`);
      setOccurrencyData({
        id: data.data._id,
        name: data.data.nome,
        status: data.data.status ? "Ativo" : "Inativo",
        description: data.data.descricao_ocorrencia,
        driverDescription: data.data.pode_descricao ? "Sim" : "Não",
        photosQuantity: data.data.qtd_fotos,
        images: data.data.start_imagens
          .map((image: any) => {
            return {
              imageName: image.imagem_name,
              required: image.obrigatorio,
              order: image.ordem,
            };
          })
          .sort((image: any, nextImage: any) => {
            return image.order - nextImage.order;
          }),
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addOccurrency = async (occurrencyData: TypeOccurrency) => {
    // if (occurrencyData.images.length < 1) {
    //   return warningMessage(
    //     "Para criar uma ocorrência é necessário ter pelo menos 1 imagem"
    //   );
    // }
    setLoading(true);
    try {
      await api.post("ocorrencias", {
        nome: occurrencyData.name,
        descricao_ocorrencia: occurrencyData.description,
        pode_descricao: occurrencyData.driverDescription === "Sim",
        status: occurrencyData.status === "Ativo",
        qtd_fotos: Number(occurrencyData.images.length),
        start_imagens: occurrencyData.images.map((image) => ({
          imagem_name: image.imageName,
          obrigatorio: image.required,
          ordem: image.order,
          data_hora: "00/00/00",
          localizacao: {
            latitude: 0,
            longitude: 0,
          },
        })),
        end_imagens: occurrencyData.images.map((image) => ({
          imagem_name: image.imageName,
          obrigatorio: image.required,
          ordem: image.order,
          data_hora: "00/00/00",
          localizacao: {
            latitude: 0,
            longitude: 0,
          },
        })),
      });

      navigate("/occurrences");
      successMessage("Tipo de ocorrência adicionada com sucesso!");
      setLoading(false);
    } catch (error : any) {
      console.log(error);
      if(error.response.data.message[0]) {
        errorMessage(error.response.data.message[0])
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível adicionar tipo de ocorrência");
      setLoading(false);
    }
  };

  const updateOccurrency = async (occurrencyData: TypeOccurrency) => {
    // if (occurrencyData.images.length < 1) {
    //   return warningMessage(
    //     "Uma ocorrência deve ter pelo menos 1 imagem"
    //   );
    // }
    
    setLoading(true);

    try {
      await api.patch(`ocorrencias/${id}`, {
        nome: occurrencyData.name,
        status: occurrencyData.status === "Ativo",
        descricao_ocorrencia: occurrencyData.description,
        pode_descricao: occurrencyData.driverDescription === "Sim",
        qtd_fotos: occurrencyData.photosQuantity,
        start_imagens: occurrencyData.images.map((image) => ({
          imagem_name: image.imageName,
          obrigatorio: image.required,
          ordem: image.order,
        })),
        end_imagens: occurrencyData.images.map((image) => ({
          imagem_name: image.imageName,
          obrigatorio: image.required,
          ordem: image.order,
        })),
      });
      navigate("/occurrences");
      successMessage("Tipo de ocorrência atualizada com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar tipo de ocorrência");
      setLoading(false);
    }
  };

  const deleteOccurrency = async (id: string) => {
    try {
      await api.delete(`ocorrencias/${id}`);
      getAllOccurrences();
      setRefresh(prev => !prev);
      successMessage("Tipo de ocorrência excluída com sucesso!");
    } catch (error : any) {
      if(error.response.data.message[0]) {
        errorMessage(error.response.data.message[0])
        setLoading(false);
        return;
      }
      console.log(error);
      setLoading(false);
    }
  };

  const [listOccurrences, setListOccurrences] = useState<Occurrence[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllOccurrences(value);
  };
  interface GetOccurrences {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number
  }

  const getOccurrences = async <T>({name, paginationIndex, rowsPerPage = 10} : GetOccurrences) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)

    const nameToQuery = `${name ? `&nome=${name}` : ''}`;

    try {
      const response = await api.get(`ocorrencias?limit=${rowsPerPage}&skip=${paginationIndex}${nameToQuery}`);
     
      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result._id,
            name: result.nome,
            photosAmount: result?.start_imagens?.length,
            status: result.status,
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],  
      totalItems,
    };
  }

  const getAllOccurrences = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `ocorrencias?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
     
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListOccurrences(
        data.data.results.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            photosAmount: result.qtd_fotos,
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    occurrencyData,
    setOccurrencyData,
    listOccurrences,
    getOccurrency,
    addOccurrency,
    updateOccurrency,
    deleteOccurrency,
    getAllOccurrences,
    getOccurrences,
    loadingData,
    refresh
  };
}
