import { errorMessage } from "../../../../components/Messages";
import { api } from "../../../../services/api"

interface GetOccurrenceFromOrderAndId {
  id: string, 
  order: number,
  handleData: (data: any) => void,
  os_id: string
}

export const getOccurrenceFromOrderAndId = async ({id, order, handleData, os_id} : GetOccurrenceFromOrderAndId) => {
  try {
    const response = await api.get(`ocorrencias/${id}/get-occurrency?ordem=${order}&os_id=${os_id}`)

    if(response.status === 200) {
      handleData(response?.data?.data)
    }

  } catch (error) {
    errorMessage("Não foi possível carregar a ocorrência, tente novamente");
  }
}

