import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Edit as EditIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";

import { ModalDelete } from "../Modals/ModalDelete";
import { CancelModal } from "../Modals/ModalDelete/cancel";

type TableActionProps = {
  params: any;
  editFunction?: () => void;
  viewFunction: () => void;
  deleteFunction?: () => void;
  cancelFunction?: () => void;
  duplicateOption?: boolean;
  exportOption?: boolean;
};

export const TableActions = ({
  params,
  deleteFunction,
  editFunction,
  viewFunction,
  cancelFunction,
  duplicateOption,
  exportOption,
}: TableActionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {editFunction && 
        <IconButton onClick={editFunction}>
          <EditIcon />
        </IconButton>
      }
      <IconButton
        aria-label="mais opções"
        aria-controls={`menu-item-row-${params.row.id}`}
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`menu-item-row-${params.row.id}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={viewFunction}>Ver</MenuItem>
    
        
        {deleteFunction && (
          <MenuItem
            onClick={() => {
              setIsModalOpen(true);
              handleClose();
              }}
            >
            Excluir
          </MenuItem>       
        )}
        
        {cancelFunction && (
          <MenuItem
          onClick={() => {
            setCancel(true);
            handleClose();
          }}
        >
          Cancelar
        </MenuItem>
        
        )}
      </Menu>

      <ModalDelete
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        deleteFunction={deleteFunction || (() => {})}
      />

      <CancelModal
        isModalOpen={cancel}
        closeModal={() => setCancel(false)}
        deleteFunction={cancelFunction || (() => {})}
      />
    </div>
  );
};
