import { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import {
  Add as AddIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Delete,
} from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { ImageButton } from "../../../../components/ImageButton";
import { useChecklist } from "../../../../hooks/useChecklist";
import { Loading } from "../../../../components/Loading";
import {
  formatListToShowInSelectComponent,
  inCreationOrEditing,
} from "../../../../utils";
import { useStyles, CustomSwitch } from "./styles";
import { LIST_HOURS } from "../../../../consts";
import { useTypeSchedule } from "../../../../hooks/useTypeSchedule";
import CustomPopover from "../../../../components/CustomPopover";

export const ChecklistForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();

  const [stepToAskToForecast, setStepToAskToForecast] = useState<any>("");
  const [stepToAutoAnalyze, setStepToAutoAnalyze] = useState<any>("");

  const [schedules, setSchedules] = useState([]);

  const {
    loading,
    setLoading,
    checklistData,
    getChecklist,
    addChecklist,
    updateChecklist,
    addStep,
    addImage,
    removeStep,
    removeImage,
    getAllTypeSchedule,
    selectList,
    listOfStepClassifications,
  } = useChecklist();

  const {
    getAllTypeOfSchedule,
    //loadingData
  } = useTypeSchedule();

  const getSchedules = async () => {
    const { data } = await getAllTypeOfSchedule({
      name: "",
      paginationIndex: 0,
      getAll: true,
    });

    const formatedSchedules = formatListToShowInSelectComponent({
      list: data,
      keyOfTheValueToPutOnLabel: "name",
      keyOfTheValueToPutOnValue: "id",
    });

    setSchedules(formatedSchedules as any);
  };

  useEffect(() => {
    getSchedules();
  }, []);

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar checklist";
    } else if (location.pathname.includes("edit")) {
      return "Editar checklist";
    } else {
      return "Checklist";
    }
  };

  useEffect(() => {
    getAllTypeSchedule();
    if (id) {
      getChecklist(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  return (
    <Formik
      enableReinitialize
      initialValues={checklistData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addChecklist(values);
        } else {
          updateChecklist(values);
        }
      }}
    >
      {({ handleChange, values, setFieldValue }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>Checklist</Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/checklist")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button className={classes.buttonSave} type="submit">
                      {loading ? <Loading /> : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/checklist")}
                    >
                      Descartar
                    </Button>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    <Button className={classes.buttonExport}>Exportar</Button>
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/checklist/${id}/edit`)}
                    >
                      {loading ? <Loading /> : "Editar"}
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={1} className={classes.formContainer}>
                  <Grid item lg={12}>
                    <Typography className={classes.formSectionTitle}>
                      Dados do checklist
                    </Typography>
                  </Grid>

                  {!location.pathname.includes("new") ? (
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>ID</InputLabel>
                      <TextField
                        name="id"
                        value={values.id}
                        placeholder="0000"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item lg={3}>
                    <InputLabel className={classes.inputLabel}>
                      Nome*
                    </InputLabel>
                    <TextField
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Nome"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      disabled={!inCreationOrEditing(location)}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <InputLabel className={classes.inputLabel}>
                      Tipo de programação*
                    </InputLabel>
                    <TextField
                      name="typeScheduleId"
                      value={
                        values.typeScheduleId === "Selecionar"
                          ? null
                          : values.typeScheduleId
                      }
                      onChange={handleChange}
                      placeholder="Selecionar"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      select
                      disabled={!inCreationOrEditing(location)}
                    >
                      <MenuItem value="Selecionar" disabled>
                        Selecionar
                      </MenuItem>
                      {schedules.map(
                        (modal: { label: string; value: string }) => (
                          <MenuItem key={modal.value} value={modal.value}>
                            {modal.label}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Grid>
                  <Grid item lg={3}>
                    <InputLabel className={classes.inputLabel}>
                      Status*
                    </InputLabel>
                    <TextField
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      placeholder="Selecionar"
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      required
                      disabled={!inCreationOrEditing(location)}
                    >
                      {selectList.status.map((status) => (
                        <MenuItem
                          key={status.label}
                          value={status.value as any}
                        >
                          {status.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={3}>
                    <InputLabel className={classes.inputLabel}>
                      Notificar etapas no aplicativo*
                    </InputLabel>
                    <TextField
                      name="notifyApp"
                      value={values.notifyApp}
                      onChange={handleChange}
                      placeholder="Selecionar"
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      disabled={!inCreationOrEditing(location)}
                    >
                      {selectList.notifyApp.map((notification) => (
                        <MenuItem key={notification} value={notification}>
                          {notification}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={12}>
                    <Typography className={classes.formSectionTitle}>
                      Checklist
                    </Typography>
                  </Grid>

                  {values.steps.map((step, index) => {
                    if (step.askToForecast) {
                      setStepToAskToForecast(step.order);
                    }

                    return (
                      <Grid
                        container
                        className={classes.stepContainer}
                        key={`step-${index + 1}`}
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            //height: 50,
                          }}
                        >
                          <Typography className={classes.stepTitle}>{`Etapa ${
                            index + 1
                          }`}</Typography>

                          <button
                            type="button"
                            onClick={() => {
                              //handleDelete(index)
                              removeStep(values, index);
                            }}
                            style={{
                              width: "auto",
                              height: "auto",
                              backgroundColor: "transparent",
                              padding: 0,
                              border: "none",
                              cursor: "pointer",
                              display: inCreationOrEditing(location)
                                ? "block"
                                : "none",
                            }}
                          >
                            <Delete htmlColor="#2F4D7C" style={{ margin: 0 }} />
                          </button>
                        </Grid>

                        <Grid
                          item
                          lg={2}
                          style={{
                            marginTop: 4,
                          }}
                        >
                          <InputLabel className={classes.inputLabelStep}>
                            Título da Etapa
                          </InputLabel>
                          <TextField
                            name="name"
                            value={values.steps[index].name}
                            onChange={handleChange(`steps[${index}].name`)}
                            placeholder="Título da Etapa"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            disabled={!inCreationOrEditing(location)}
                          />
                        </Grid>

                        <Grid
                          item
                          lg={2}
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            className={classes.inputLabelStep}
                            style={{ color: "transparent" }}
                          >
                            Selecionar
                          </InputLabel>
                          <CustomPopover
                            startIcon={<AddIcon />}
                            data={[
                              { label: "Imagem", value: "image" },
                              { label: "Video", value: "isVideo" },
                            ]}
                            placeholder="Anexo"
                            onChangeValue={(value) => {
                              addImage(
                                values,
                                step,
                                index,
                                value === "isVideo"
                              );
                            }}
                          />
                        </Grid>
                        <div
                          className={classes.switchContainer}
                          style={{ width: "100%" }}
                        >
                          <CustomSwitch
                            name="required"
                            checked={values.steps[index].required}
                            onChange={handleChange(`steps[${index}].required`)}
                            disabled={!inCreationOrEditing(location)}
                          />
                          <Typography className={classes.switchText}>
                            Obrigatório
                          </Typography>

                          <CustomSwitch
                            name="askToForecast"
                            checked={stepToAskToForecast === step.order}
                            onChange={(e) => {
                              let change = handleChange(
                                `steps[${index}].askToForecast`
                              );
                              change(e);
                              setStepToAskToForecast(
                                stepToAskToForecast === step.order
                                  ? ""
                                  : step.order
                              );
                            }}
                            disabled={!inCreationOrEditing(location)}
                          />
                          <Typography className={classes.switchText}>
                            Pedir previsão de chegada ao cliente
                          </Typography>
                        </div>
                        <Grid
                          item
                          lg={12}
                          direction="row"
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            gap: 10,
                          }}
                        >
                          <Grid item lg={2}>
                            <InputLabel className={classes.inputLabel}>
                              Tempo para execução da etapa*
                            </InputLabel>
                            <TextField
                              name="executionTime"
                              value={values.steps[index].executionTime}
                              onChange={handleChange(
                                `steps[${index}].executionTime`
                              )}
                              variant="outlined"
                              size="small"
                              fullWidth
                              select
                              disabled={!inCreationOrEditing(location)}
                            >
                              {LIST_HOURS.map((executionTime) => (
                                <MenuItem
                                  key={executionTime.id}
                                  value={executionTime.id}
                                >
                                  {executionTime.value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item lg={2}>
                            <InputLabel className={classes.inputLabel}>
                              Classificação da etapa
                            </InputLabel>
                            <TextField
                              name="classification"
                              value={values.steps[index].classification}
                              onChange={handleChange(
                                `steps[${index}].classification`
                              )}
                              variant="outlined"
                              size="small"
                              fullWidth
                              select
                              disabled={!inCreationOrEditing(location)}
                            >
                              {listOfStepClassifications.map(
                                (classification) => (
                                  <MenuItem
                                    key={classification}
                                    value={classification}
                                  >
                                    {classification}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                        </Grid>
                        <Grid>
                          <div
                            className={classes.switchContainer}
                            style={{ width: "100%" }}
                          >
                            <CustomSwitch
                              name="autoAnalyze"
                              checked={values.steps[index].autoAnalyze}
                              onChange={(e) => {
                                let change = handleChange(
                                  `steps[${index}].autoAnalyze`
                                );
                                change(e);
                                setFieldValue(
                                  `steps[${index}].autoAnalyze`,
                                  !values.steps[index].autoAnalyze
                                );
                              }}
                              disabled={!inCreationOrEditing(location)}
                            />
                            <Typography className={classes.switchText}>
                              Ativar detectação de dados de porta de container e
                              lacre automáticos
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          style={{
                            marginTop: 8,
                            display: step?.images?.length > 0 ? "flex" : "none",
                          }}
                        >
                          <Typography className={classes.stepTitle}>
                            Imagens/Videos
                          </Typography>
                        </Grid>
                        {step.images.map((image: any, indexImage: number) => {
                          return (
                            <Grid
                              item
                              lg={2}
                              style={{
                                marginTop: 4,
                              }}
                              key={`imag/video-${indexImage + 1}`}
                            >
                              <Grid
                                item
                                lg={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  //height: 50,
                                }}
                              >
                                <InputLabel className={classes.inputLabelStep}>
                                  {`${indexImage + 1} - ${
                                    image.isVideo ? "Vídeo" : "Imagem"
                                  }`}
                                </InputLabel>

                                <button
                                  type="button"
                                  onClick={() => {
                                    //handleDelete(index)
                                    removeImage(
                                      values,
                                      step,
                                      index,
                                      indexImage
                                    );
                                  }}
                                  style={{
                                    width: "auto",
                                    height: "auto",
                                    backgroundColor: "transparent",
                                    padding: 0,
                                    border: "none",
                                    cursor: "pointer",
                                    display: inCreationOrEditing(location)
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <Delete
                                    htmlColor="#2F4D7C"
                                    style={{ margin: 0 }}
                                  />
                                </button>
                              </Grid>
                              <TextField
                                name="imageName"
                                value={image?.imageName}
                                onChange={handleChange(
                                  `steps[${index}].images[${indexImage}].imageName`
                                )}
                                placeholder={
                                  image.isVideo
                                    ? "Título do Vídeo"
                                    : "Título da Imagem"
                                }
                                variant="outlined"
                                size="small"
                                fullWidth
                                required
                                disabled={!inCreationOrEditing(location)}
                              />
                              <div className={classes.switchContainer}>
                                <CustomSwitch
                                  name="status"
                                  disabled={!inCreationOrEditing(location)}
                                  checked={image?.required}
                                  onChange={handleChange(
                                    `steps[${index}].images[${indexImage}].required`
                                  )}
                                />
                                <Typography className={classes.switchText}>
                                  Obrigatório
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}

                        <Grid item lg={12}>
                          <Divider style={{ height: 4, marginTop: 8 }} />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid
                    item
                    lg={12}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Button
                      startIcon={<AddIcon />}
                      className={classes.buttonCreateStep}
                      onClick={() => addStep(values)}
                      disabled={!inCreationOrEditing(location)}
                    >
                      Nova Etapa
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
