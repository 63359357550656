import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FaCopy } from "react-icons/fa";

import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { useChecklist } from "../../../hooks/useChecklist";
import { useStyles } from "./styles";
import GenericTable from "../../../components/GenericTable";
import GenericExport from "../../../components/GenericExport";
import { Checklist as  ChecklistProps} from "../../../types/managementServiceOrderTypes";
import { ModalConfirm } from "../../../components/Modals/ConfirmModal";

const tableHeader = [
  {key: 'name', label: 'Nome'},
  {key: 'typeSchedule', label: 'Tipo de Programação'},
  {key: 'status', label: 'Status'},
];

const tableHeaderToExport = [
  {key: 'nome', label: 'Nome'},
  {key: 'programacao_name', label: 'Tipo de Programação'},
  {key: 'createdAt', label: 'Data de criação'},
  {key: 'updatedAt', label: 'Última atualização'},
  {key: 'status', label: 'Status'},
];

export const Checklist = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [openModal, setModalOpen] = useState(false);

  const [selectedCheklist, setSelectedCheklist] = useState<ChecklistProps>()

  const [data, setData] = useState<any>()
  const [totalItems, setTotalItems] = useState(0)
  const [name, setName] = useState("")
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const Duplicate = ({item} : {item: ChecklistProps}) => {
 
    return(
      <div onClick={(e) => {
        e.stopPropagation();
        console.log(item)
        setSelectedCheklist(item)
        setModalOpen(true)
      }}>
        <FaCopy 
          color="#4f4f4f" 
          size={18}
        />
      </div>
    )
  }

  const {
    deleteChecklist,
    getListOfChecklist,
    loadingData,
    refresh,
    duplicateChecklist
  } = useChecklist();

  const getAllData = async () => {
    const {data, totalItems: total} = await getListOfChecklist({name, paginationIndex, rowsPerPage})

    if(data) {
      setData(data.map((item: any) => ({
        ...item,
        status: (
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
  
              width: 200,
              padding: "4px 0px",
              borderRadius: "4px",
  
              fontSize: 12,
              color: "#252525",
              opacity: "0.8",
              backgroundColor: (item?.status as Boolean)
                ? "#6FED8B"
                : "#FF3C40",
            }}
          >
            {(item?.status as Boolean) ? "Ativo" : "Inativo"}
          </Typography> 
          )
      })))
      setTotalItems(total)
    }
  }

  useEffect(() => {
    setData([])
    getAllData()
  }, [name, paginationIndex, refresh, rowsPerPage]);

  return (
    <Grid container spacing={2}>
      <ModalConfirm 
      onOK={() => {
        // if(isModalFor === "cancel") {
        //   return
        // }
        // if(isModalFor === "delete") {
        //   return
        // }
        if(selectedCheklist?.id) {
          duplicateChecklist(selectedCheklist)
        }
       
        setModalOpen(false)
      }}
      closeModal={() => setModalOpen(false)}
      isModalOpen={openModal}
      description={`Tem certeza de que deseja duplicar a checklist ${selectedCheklist?.name || ''}?`}
    />
      <Grid item lg={3}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Checklist
        </Typography>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchBarInputsContainer}>
              <TextField
                className={classes.searchBarInput}
                placeholder="Pesquisar por nome"
                variant="outlined"
                size="small"
                value={name}
                onChange={e => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.searchBarActionButtonsContainer}>
              <GenericExport 
                headerToRows={tableHeaderToExport}
                endpoint="checklist"
                title="Exportar lista de checklist"
              />
              <Button
                startIcon={<AddIcon />}
                className={classes.buttonRegister}
                onClick={() => navigate("/checklist/new")}
              >
                Cadastrar
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item lg={12}>
        <GenericTable
          header={tableHeader}
          data={data || []}
          isLoading={loadingData}
          handleDelete={(item) => deleteChecklist(item?.id)}
          handleEdit={(item) => navigate(`/checklist/${item?.id}/edit`)}
          onClick={(item) => navigate(`/checklist/${item?.id}`)}
          total={totalItems}
          onChangeIndex={(index) =>  setPaginationIndex(index)}
          onChangeRowsPerPage={(value) => setRowsPerPage(value)}
          customAction={Duplicate}
        />
      </Grid>
    </Grid>
  );
};
