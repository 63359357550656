import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1),
    },
    overviewSectionTitle: {
      color: "#2F4D7C",
      fontWeight: 600,
    },
    overviewSectionSubtitle: {
      color: "#2F4D7C",
    },
    overviewFieldTitle: {
      color: "#252525",
      fontSize: 18,
    },
  })
);
