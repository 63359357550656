import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { useClients } from "../../../hooks/Users/useClients";
import { useStyles } from "./styles";
import GenericTable from "../../../components/GenericTable";
import GenericExport from "../../../components/GenericExport";

const tableHeader = [
  {key: 'name', label: 'Nome'},
  {key: 'createdAt', label: 'Data de Cadastro'},
  {key: 'cnpj', label: 'CNPJ'},
  {key: 'email', label: 'E-mail'},
  {key: 'phone', label: 'Telefone'},
  {key: 'status', label: 'Status'},
];

const tableHeaderToExport = [
  {key: 'nome', label: 'Nome'},
  {key: 'createdAt', label: 'Data de Cadastro'},
  {key: 'cnpj', label: 'CNPJ'},
  {key: 'email', label: 'E-mail'},
  // {key: 'email2', label: 'Segundo E-mail'},
  {key: 'telefone', label: 'Telefone'},
  {key: 'cliente_CEP', label: 'CEP'},
  {key: 'cliente_estado', label: 'Estado'},
  {key: 'cliente_cidade', label: 'Cidade'},
  {key: 'cliente_bairro', label: 'Bairro'},
  {key: 'cliente_logradouro', label: 'Logradouro'},
  {key: 'cliente_numero', label: 'Número'},
  {key: 'cliente_complemento', label: 'Complemento'},
  {key: 'status', label: 'Status'},
];

export const Clients = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [data, setData] = useState<any>()
  const [totalItems, setTotalItems] = useState(0)
  const [name, setName] = useState("")
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    deleteClient,
    getAllClientsList,
    loadingData,
    refresh
  } = useClients();

  const getAllData = async () => {
    const {data, totalItems: total} = await getAllClientsList({name, paginationIndex, rowsPerPage})

    if(data) {
      setData(data.map((item: any) => ({
        ...item,
        status: (
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",

              width: 200,
              padding: "4px 0px",
              borderRadius: "4px",

              fontSize: 12,
              color: "#252525",
              opacity: "0.8",
              backgroundColor: (item?.status as Boolean)
                ? "#6FED8B"
                : "#FF3C40",
            }}
          >
            {(item?.status as Boolean) ? "Ativo" : "Inativo"}
          </Typography> 
          )
      })))
      setTotalItems(total)
    }
  }

  useEffect(() => {
    getAllData()
  }, [name, paginationIndex, refresh, rowsPerPage]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={3}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Clientes
        </Typography>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchBarInputsContainer}>
              <TextField
                className={classes.searchBarInput}
                placeholder="Pesquisar por nome"
                variant="outlined"
                size="small"
                value={name}
                onChange={e => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.searchBarActionButtonsContainer}>
              <GenericExport 
                headerToRows={tableHeaderToExport}
                endpoint="cliente"
                title="Exportar lista de clientes"
              />
              <Button
                startIcon={<AddIcon />}
                className={classes.buttonRegister}
                onClick={() => navigate("/clients/new")}
              >
                Cadastrar
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item lg={12}>
        <GenericTable
          header={tableHeader}
          data={data || []}
          isLoading={loadingData}
          handleDelete={(item) => deleteClient(item?.id)}
          handleEdit={(item) => navigate(`/clients/${item?.id}/edit`)}
          onClick={(item) => navigate(`/clients/${item?.id}`)}
          total={totalItems}
          onChangeIndex={(index) =>  setPaginationIndex(index)}
          onChangeRowsPerPage={(value) => setRowsPerPage(value)}
          noContentText="Sem clientes para mostrar"
        />
      </Grid>
    </Grid>
  );
};
