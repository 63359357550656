import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      gap: 10,
      minHeight: 200,
      width: 200,
      marginTop: 8,
      marginBottom: 8,
      background: "#FFFFFF",
      borderRadius: 8,
    },
  })
);
