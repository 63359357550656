import { FC, useEffect, useState, ChangeEvent } from "react"
import {
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";

import { useStyles } from "./styles";
import { useClients } from "../../hooks/Users/useClients";
import { Address } from "../../hooks/useAddress";

interface CustomClientListInputPorps {
  value: any;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, selectedAddress: Address | null) => void;
  label: string;
  required?: boolean;
  name: string;
}

interface ClientWithAddress {
  id: string;
  name: string;
  endereco: Address
}

const CustomClientListInput: FC<CustomClientListInputPorps> = ({ value, onChange, label, required, name }) => {
  const [chooseClinteManually, setChooseClinteManually] = useState(true);
  const [listOfClients, setListOfClients] = useState<ClientWithAddress[]>([])
   const classes = useStyles();

   const {
    clientListWidthAddress
  } = useClients()

  const getAllData = async () => {
    const clients = await clientListWidthAddress();

    if(clients) {
      setListOfClients(clients)
    }
  }

  useEffect(() => {
    getAllData()
  }, []);

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <InputLabel className={classes.inputLabel} >
          {label || ""}
        </InputLabel>
        <InputLabel className={classes.buttonManual} 
          onClick={() => {
            setChooseClinteManually(prev => !prev)
          }}
        >
          {chooseClinteManually  ? "Lista de clientes" : "Digitar manualmente"} 
        </InputLabel>

      </div>
      {chooseClinteManually  ? 
        (   
          <TextField
            name={name}
            value={value || ""}
            onChange={(e) => {
              onChange && onChange(e, null)
            }}
            placeholder={label}
            variant="outlined"
            size="small"
            fullWidth
          />
        ) : (
          <TextField
            name={name}
            value={value || ""}
            onChange={e => {
              const getAdresses = listOfClients?.find(item => item.name === e.target.value)

                onChange && onChange(e, getAdresses?.endereco || null)
            }}
            placeholder="Selecionar"
            variant="outlined"
            size="small"
            fullWidth
            select
            required={required}
          >
            {listOfClients?.map(value => (
              <MenuItem key={value?.name} value={value?.name}>
                {value?.name}
              </MenuItem>
            ))}
          </TextField>
        )}
    </>
  )
}

export default CustomClientListInput