import { api } from "../../../../services/api"

export const asyncCheckPlaca = async (placa: string, handleLoading: (isLoading: boolean) => void) => {
  if(!placa) {
    return false
  }

  handleLoading(true)
 
  try {
    const response = await api.get(`veiculos/placa/${placa}`)

    if(response.data.statusCode === 200){
      handleLoading(false)
      return true
    }
    handleLoading(false)
    return false

  } catch (error) {
    handleLoading(false)
    return false
  }
}