import styled from 'styled-components';

export const Container = styled.div<{ bgColor: string, color: string }>`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;

  background-color: ${({bgColor}) => bgColor};
  padding-top: 20px;

  img{
    margin-top: 40px;
  }

  span{
    margin: 40px 0;
    margin-top: 10px;
    font-size: 20px; 
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    
    text-align: center;
    color: ${({ color }) => color || "#4f4f4f"};
    max-width: 300px;
  }

  @media(max-width: 720px){
    img{
      width: 200px;
    }

    span{
      max-width: 200px;
    }
  }

  @media(max-height: 840px){
    img{
      width: 250px;
    }
  }
`;
