import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overviewSectionTitle: {
      color: "#2F4D7C",
      fontWeight: 600,
      fontSize: 18,
    },
    overviewSectionSubtitle: {
      color: "#2F4D7C",
      fontSize: 18,
    },
    overviewSectionField: {
      color: "#252525",
      fontSize: 18,
    },
    overviewSectionValue: {
      color: "#6A6A6A",
      fontSize: 16,
    },
  })
);
