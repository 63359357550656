import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    loadingNewPosition : {
      width: "100%",
      height: "100%",
      position: "absolute",
      backgroundColor: "rgba(47,77,124,0.8)",
    },
    buttonAddVehicle: {
      width: 160,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    vehicleSectionTitle: {
      color: "#2F4D7C",
      fontWeight: 600,
    },
  })
);
