import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { useTypeModal } from "../../../../hooks/useTypeModal";
import { Loading } from "../../../../components/Loading";
import { inCreationOrEditing } from "../../../../utils";
import { useStyles } from "./styles";

const listStatus = ["Ativo", "Inativo"];

export const TypeModalForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const {
    loading,
    setLoading,
    typeModalData,
    getTypeModal,
    addTypeModal,
    updateTypeModal,
  } = useTypeModal();

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar tipo de modal";
    } else if (location.pathname.includes("edit")) {
      return "Editar tipo de modal";
    } else {
      return "Tipo de modal";
    }
  };

  useEffect(() => {
    if (id) {
      getTypeModal(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  return (
    <Formik
      enableReinitialize
      initialValues={typeModalData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addTypeModal(values);
        } else {
          updateTypeModal(values);
        }
      }}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>
                Tipos de modal
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/modal-type")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button className={classes.buttonSave} type="submit">
                      {loading ? <Loading/> : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/modal-type")}
                    >
                      Descartar
                    </Button>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/modal-type/${id}/edit`)}
                    >
                      {loading ? <Loading/> :"Editar"}
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados do tipo de modal
                      </Typography>
                    </Grid>

                    {!location.pathname.includes("new") ? (
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          ID
                        </InputLabel>
                        <TextField
                          name="id"
                          value={values.id}
                          onChange={handleChange}
                          placeholder="0000"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Nome*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Status
                      </InputLabel>
                      <TextField
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {listStatus.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Paper>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
