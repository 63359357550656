import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  errorMessage,
  successMessage,
  warningMessage,
} from "../components/Messages";
import { api } from "../services/api";
import { Checklist, StepChecklist } from "../types/managementServiceOrderTypes";

type TypeChecklist = {
  id: string;
  name: string;
  typeSchedule: string;
  status: boolean;
};

const SKIPLIMIT = 10;

const listOfStepClassifications = ["Coletado", "No cliente", "Entregue"];

export function useChecklist() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [checklistData, setChecklistData] = useState<Checklist>({
    id: "",
    name: "",
    status: "Ativo",
    typeScheduleId: "Selecionar",
    notifyApp: "Sim",
    required: true,
    steps: [],
    forecast: "",
  });

  const [selectList, setSelectList] = useState({
    typeSchedule: [{ id: "", name: "" }],
    status: [
      { label: "Ativo", value: true },
      { label: "Inativo", value: false },
    ],
    notifyApp: ["Sim", "Não"],
  });

  const getChecklist = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await api.get(`checklists/${id}`);

      setChecklistData({
        id: data?.data?._id,
        name: data?.data?.nome,
        status: data?.data?.status,
        typeScheduleId: data?.data?.programacao?._id,
        notifyApp: data?.data?.notificacao ? "Sim" : "Não",
        required: data?.data?.obrigatorio,
        forecast: data?.data?.previsao,
        steps: data?.data?.etapas
          .map((etapa: any) => {
            return {
              status: etapa?.status,
              name: etapa?.titulo,
              askToForecast: etapa?.pedirPrevisao,
              executionTime: etapa?.tempo_execucao,
              order: etapa.ordem,
              location: {
                latitude: etapa?.localizacao?.latitude,
                longitude: etapa?.localizacao?.longitude,
              },
              driverDescription: etapa?.motorista_description,
              classification: etapa?.classification,
              required: etapa?.obrigatorio,
              autoAnalyze: etapa?.autoAnalyze,
              images: etapa?.imagens?.map((imagem: any) => {
                return {
                  imageName: imagem?.imagem_name,
                  imagePath: imagem?.imagem_path,
                  fileName: imagem?.file_name,
                  required: imagem?.obrigatorio,
                  order: imagem?.ordem,
                  isVideo: imagem?.isVideo,
                };
              }),
            };
          })
          .sort((step: any, nextStep: any) => {
            return step.order - nextStep.order;
          }),
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addChecklist = async (checklistData: Checklist) => {
    if (checklistData.steps.length < 1) {
      return warningMessage(
        "Para criar uma checklist é necessário ter pelo menos 1 etapa"
      );
    }

    setLoading(true);
    try {
      await api.post("checklists", {
        nome: checklistData.name,
        programacao_id: checklistData.typeScheduleId,
        notificacao: checklistData.notifyApp === "Sim",
        status: checklistData.status,
        //previsao: checklistData?.forecast,
        etapas: checklistData.steps.map((step) => {
          return {
            ordem: step.order,
            titulo: step.name,
            pedirPrevisao: step?.askToForecast,
            tempo_execucao: step.executionTime,
            localizacao: {
              latitude: 0,
              longitude: 0,
            },
            motorista_description: step?.driverDescription,
            classification: step?.classification,
            obrigatorio: step.required,
            autoAnalyze: step.autoAnalyze,
            imagens: step.images.map((image) => {
              return {
                imagem_name: image.imageName,
                imagem_path: image.imagePath,
                file_name: image.fileName,
                obrigatorio: image.required,
                ordem: image.order,
                isVideo: image?.isVideo,
              };
            }),
          };
        }),
      });
      navigate("/checklist");
      successMessage("Checklist adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message[0]) {
        errorMessage(error.response.data.message[0]);
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível adicionar checklist");
      setLoading(false);
    }
  };

  const duplicateChecklist = async (checklistData: Checklist) => {
    setLoading(true);
    try {
      await api.post("checklists", {
        nome: `${checklistData.name} - (cópia)`,
        programacao_id: checklistData.typeScheduleId,
        notificacao: checklistData.notifyApp === "Sim",
        status: true,
        etapas: checklistData.steps.map((step) => {
          return {
            ordem: step.order,
            titulo: step.name,
            pedirPrevisao: step?.askToForecast,
            tempo_execucao: step.executionTime,
            localizacao: {
              latitude: 0,
              longitude: 0,
            },
            motorista_description: step?.driverDescription,
            classification: step?.classification,
            obrigatorio: step.required,
            imagens: step.images.map((image) => {
              return {
                imagem_name: image.imageName,
                imagem_path: image.imagePath,
                file_name: image.fileName,
                obrigatorio: image.required,
                ordem: image.order,
                isVideo: image?.isVideo,
              };
            }),
          };
        }),
      });
      //navigate("/checklist");
      successMessage("Checklist duplicada com sucesso!");
      setLoading(false);
      setRefresh((prev) => !prev);
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message[0]) {
        errorMessage(error.response.data.message[0]);
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível duplicar checklist");
      setLoading(false);
    }
  };

  const updateChecklist = async (checklistData: Checklist) => {
    if (checklistData.steps.length < 1) {
      return warningMessage("Uma checklist é deve ter pelo menos 1 etapa");
    }
    setLoading(true);
    try {
      await api.patch(`checklists/${id}`, {
        nome: checklistData.name,
        programacao_id: checklistData.typeScheduleId,
        notificacao: checklistData.notifyApp === "Sim",
        status: checklistData.status,
        //previsao: checklistData?.forecast,
        etapas: checklistData.steps.map((step) => {
          return {
            status: step.status,
            titulo: step.name,
            pedirPrevisao: step?.askToForecast,
            tempo_execucao: step.executionTime,
            localizacao: {
              latitude: step?.location?.latitude,
              longitude: step?.location?.longitude,
            },
            motorista_description: step?.driverDescription,
            classification: step?.classification,
            obrigatorio: step.required,
            autoAnalyze: step.autoAnalyze,
            ordem: step.order,
            imagens: step.images.map((image) => {
              return {
                imagem_name: image.imageName,
                imagem_path: image.imagePath,
                file_name: image.fileName,
                obrigatorio: image.required,
                ordem: image.order,
                isVideo: image?.isVideo,
              };
            }),
          };
        }),
      });
      navigate("/checklist");
      successMessage("Checklist atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar checklist");
      setLoading(false);
    }
  };

  const getAllTypeSchedule = async () => {
    try {
      const { data } = await api.get("programacao");
      setSelectList({
        ...selectList,
        typeSchedule: data.data.data.map((result: any) => {
          return {
            id: result?._id,
            name: result?.nome,
          };
        }),
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deleteChecklist = async (id: string) => {
    try {
      await api.delete(`checklists/${id}`);
      getAllChecklist();
      setRefresh((prev) => !prev);
      successMessage("Checklist excluída com sucesso!");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [listChecklist, setListChecklist] = useState<Checklist[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllChecklist(value);
  };

  interface GetListOfChecklist {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number;
  }

  const getListOfChecklist = async <T>({
    name,
    paginationIndex,
    rowsPerPage = 10,
  }: GetListOfChecklist) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true);

    const nameToQuery = `${name ? `&nome=${name}` : ""}`;

    try {
      const response = await api.get(
        `checklists?limit=${rowsPerPage}&skip=${paginationIndex}${nameToQuery}`
      );
      if (response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => ({
          id: result._id,
          name: result.nome,
          status: result.status,
          typeSchedule: result?.programacao?.nome,
          typeScheduleId: result?.programacao?._id,
          notifyApp: result.notificacao ? "Sim" : "Não",
          required: result.obrigatorio,
          steps: result?.etapas?.map((etapa: any) => {
            return {
              images: etapa.imagens.map((imagem: any) => {
                return {
                  required: imagem.required,
                  imageName: imagem.imagem_name,
                  imagePath: imagem.imagem_path,
                  fileName: imagem.file_name,
                  order: imagem?.ordem,
                };
              }),
              status: etapa.status,
              name: etapa.titulo,
              executionTime: etapa.tempo_execucao,
              required: etapa.obrigatorio,
              askToForecast: etapa?.pedirPrevisao,
              order: etapa.ordem,
              location: {
                latitude: etapa?.localizacao?.latitude,
                longitude: etapa?.localizacao?.longitude,
              },
              id: etapa._id,
            };
          }),
        }));

        totalItems = response?.data?.data?.total;

        result = [...data];
      }

      setLoadingData(false);
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false);
    }

    return {
      data: result as T[],
      totalItems,
    };
  };

  const getAllChecklist = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const { data } = await api.get(`checklists`);

      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListChecklist(
        data.data.data.map((result: any) => {
          return {
            id: result?._id,
            name: result?.nome,
            status: result?.status,
            typeSchedule: result?.programacao?.nome,
            notifyApp: result?.notificacao ? "Sim" : "Não",
            required: result?.obrigatorio,
            steps: result?.etapas
              ? result?.etapas?.map((etapa: any) => {
                  return {
                    images: etapa?.imagens?.map((imagem: any) => {
                      return {
                        required: imagem?.required || imagem?.obrigatorio,
                        imageName: imagem?.imagem_name,
                        imagePath: imagem?.imagem_path,
                        fileName: imagem?.file_name,
                        order: imagem?.ordem,
                        isVideo: imagem?.isVideo,
                      };
                    }),
                    status: etapa?.status,
                    name: etapa?.titulo,
                    executionTime: etapa?.tempo_execucao,
                    required: etapa?.obrigatorio,
                    autoAnalyze: etapa?.autoAnalyze,
                    order: etapa?.ordem,
                    askToForecast: etapa?.pedirPrevisao,
                    driverDescription: etapa?.motorista_description,
                    classification: etapa?.classification,
                    location: {
                      latitude: etapa?.localizacao?.latitude,
                      longitude: etapa?.localizacao?.longitude,
                    },
                    id: etapa?._id,
                  };
                })
              : [],
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addStep = (values: Checklist) => {
    setChecklistData({
      ...values,
      steps: [
        ...values.steps,
        {
          status: false as any,
          name: "",
          executionTime: 1,
          images: [],
          imagesScheduled: [],
          imagesSigned: [],
          order: values.steps.length + 1,
          location: {
            latitude: "",
            longitude: "",
          },
          required: true,
          autoAnalyze: false,
          tare: "0",
          maxGross: "0",
          containerCode: "",
          sealCode: "",
          driverDescription: "",
          classification: "",
        },
      ],
    });
  };
  const removeStep = (values: Checklist, stepIndex: number) => {
    let allSteps = checklistData.steps;

    setChecklistData({
      ...values,
      steps: allSteps.filter((step, index) => index !== stepIndex),
    });
  };

  const addImage = (
    values: Checklist,
    step: StepChecklist,
    index: number,
    isVideo?: boolean
  ) => {
    let checklistDataCopy = { ...values };
    checklistDataCopy.steps[index].images = [
      ...step.images,
      {
        imageName: "",
        location: {
          latitude: 0,
          longitude: 0,
        },
        dateHour: "00/00/0000",
        required: true,
        dateScheduled: "00/00/0000",
        imagePath: "",
        fileName: "",
        order: values.steps[index].images.length + 1,
        base64: "",
        isVideo,
      },
    ];

    setChecklistData(checklistDataCopy);
  };

  const removeImage = (
    values: Checklist,
    step: StepChecklist,
    stepIndex: number,
    imageIndex: number
  ) => {
    let checklistDataCopy = { ...values };

    let itemsWithoutRemoved = checklistDataCopy.steps[stepIndex].images.filter(
      (_, index) => index !== imageIndex
    );
    checklistDataCopy.steps[stepIndex].images = itemsWithoutRemoved;

    setChecklistData(checklistDataCopy);
  };

  const addImageScheduled = (
    values: Checklist,
    step: StepChecklist,
    index: number
  ) => {
    let checklistDataCopy = { ...values };
    checklistDataCopy.steps[index].imagesScheduled = [
      ...step.imagesScheduled,
      {
        imageName: "",
        location: {
          latitude: 0,
          longitude: 0,
        },
        dateHour: "00/00/0000",
        required: true,
        dateScheduled: "00/00/0000",
        imagePath: "",
        fileName: "",
        order: values.steps[index].imagesScheduled.length + 1,
        base64: "",
      },
    ];

    setChecklistData(checklistDataCopy);
  };

  const addImageSigned = (
    values: Checklist,
    step: StepChecklist,
    index: number
  ) => {
    let checklistDataCopy = { ...values };
    checklistDataCopy.steps[index].imagesSigned = [
      ...step.imagesSigned,
      {
        imageName: "",
        location: {
          latitude: 0,
          longitude: 0,
        },
        dateHour: "00/00/0000",
        required: true,
        dateScheduled: "00/00/0000",
        imagePath: "",
        fileName: "",
        order: values.steps[index].imagesSigned.length + 1,
        base64: "",
      },
    ];

    setChecklistData(checklistDataCopy);
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    checklistData,
    listChecklist,
    getChecklist,
    addChecklist,
    updateChecklist,
    deleteChecklist,
    getAllChecklist,
    selectList,
    addStep,
    addImage,
    addImageScheduled,
    addImageSigned,
    getAllTypeSchedule,
    getListOfChecklist,
    loadingData,
    refresh,
    removeStep,
    removeImage,
    listOfStepClassifications,
    duplicateChecklist,
  };
}
