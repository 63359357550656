import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    searchBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",

      padding: 16,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputLabel: {
      marginBottom: 40,
      color: "#4E4E4E",
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
    searchBarInputsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    searchBarInput: {
      width: 280,
      marginRight: 12,
    },
    scheduleWeekInput: {
      width: 220,
      marginLeft: 12,
    },
    searchBarFilter: {
      width: 250,
    },
    searchBarActionButtonsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonModalOk: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      marginLeft: 8,
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#fff",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2EA949",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "rgba(46,169,73,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonFilter: {
      marginLeft: 12,
      borderRadius: "20%",
      color: "#FFF",
      backgroundColor: "#2F4D7C",
      transition: ".5 ease",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
    },
    buttonExport: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      marginLeft: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#76A1E8",
      "&:hover": {
        backgroundColor: "#6991CF",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttons : {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
    },
    buttonRegister: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      marginLeft: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonBack: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      marginRight: 8,
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#FF3C40",
      "&:hover": {
        backgroundColor: "rgba(255,60,64,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    rowItemStatus: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",

      width: "100%",
      padding: "4px 0px",
      borderRadius: "4px",

      fontSize: 12,
      color: "#252525",
      opacity: "0.8",
    },
  })
);

//css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root