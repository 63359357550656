export const formatTypeScheduleList = (list: any[]) => {
  if(!list) {
    return []
  }

  return list.map(item => ({
    label: item.name,
    modal: item.modal,
    value: item.id
  }))
}

export const suportedStatus = [
  // "Finalizada",
  "Em Ocorrência",
  // "Cancelada",
  "Em Andamento",
  "Em Intervalo",
  "Sem Aceite"
]

export const returnedColor = (status: string) => {
  switch (status) {
    case "Finalizada":
      return "#6FED8B";
    case "Em Ocorrência":
      return "#FC6D26";
    case "Cancelada":
      return "#FF3C40";
    case "Em Andamento":
      return "#FED278";
    case "Em Intervalo":
      return "#87CFF9";
    default:
      return "#D3D3D3";
  }
};