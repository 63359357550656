import {FC, useState} from 'react'
import { Checkbox } from 'antd'
import { TextField, Typography, Box } from '@material-ui/core'
import { useStyles } from './styles'
import { HandleChangeList } from '.'
import ImageComponent from '../../ImageComponent'

type Image = {
  imagem_name: string
  imagem_path: string
  obrigatorio: boolean
}

export type VehicleFormChecklistModalOptionProps = {
  id: string
  main_question: string
  second_question: string
  main_question_answer: string
  second_question_answer: string
  main_observation: string
  second_observation: string
  index: number
  handleChangeList: HandleChangeList
  viewOnly?: boolean
  first_image: Image
  second_image: Image
}

const VehicleFormChecklistModalOption: FC<VehicleFormChecklistModalOptionProps> = ({ 
  id, 
  main_question, 
  second_question, 
  main_question_answer,
  second_question_answer,
  main_observation,
  second_observation,
  index, 
  handleChangeList, 
  first_image,
  second_image, 
  viewOnly
 }) => {
  const [required, setRequired] = useState(false)

  const classes = useStyles();

  return(
    <Box className={classes.box}>
      <Typography className={classes.quetionText}>{index ? `${index}ª` : ''} Questão</Typography>
     {!viewOnly ? (
      <>
        <Typography className={classes.subQuetionText}>Questão {index}.1</Typography>
        <TextField
          className={classes.input}
          defaultValue={main_question}
          placeholder=''
          onChange={e => {
            handleChangeList(id, "main_question", e.target.value)
          }}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          minRows={3}
          disabled={viewOnly}
        />
        <Typography className={classes.subQuetionText}>Questão {index}.2</Typography>
        <TextField
          className={classes.input}
          defaultValue={second_question}
          placeholder=''
          onChange={e => {
            handleChangeList(id, "second_question", e.target.value)
          }}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          minRows={3}
          disabled={viewOnly}
        />
      </>
     ) : (
      <>
        <Typography className={classes.subQuetionText}>{main_question}</Typography>
        <Typography className={classes.text}>
          Resposta: {' '}
          <strong>
          {main_question_answer}
          </strong>
        </Typography>
        <TextField
          className={classes.input}
          defaultValue={main_observation}
          placeholder='Observação'
          variant="outlined"
          size="small"
          fullWidth
          multiline
          minRows={1}
          disabled
        />
        <Typography className={classes.subQuetionText}>{second_question}</Typography>
        <Typography className={classes.text}>
          Resposta: {' '}
          <strong>
          {second_question_answer}
          </strong>
        </Typography>
        <TextField
          className={classes.input}
          defaultValue={second_observation}
          placeholder='Observação'
          variant="outlined"
          size="small"
          fullWidth
          multiline
          minRows={1}
          disabled
        />
      </>
     )}

      {viewOnly &&  first_image.imagem_path && (
        <ImageComponent 
          imageKey={first_image.imagem_path} 
          imageStyles={{maxWidth: '100%', marginTop: 20}}
          onlyImage
          isVideo={false}
        />
      )}
      {viewOnly &&  second_image.imagem_path && (
        <ImageComponent 
          imageKey={second_image.imagem_path} 
          imageStyles={{maxWidth: '100%', marginTop: 20}}
          onlyImage
          isVideo={false}
        />
      )}

      <Checkbox
        style={{ marginTop: 20 }}
        checked={first_image?.obrigatorio}
        disabled={viewOnly}
        onChange={() => {
          setRequired(!required)
          handleChangeList(id, "first_image", {
            ...first_image,
            imagem_name: first_image?.imagem_name || main_question, 
            obrigatorio: required
          })
        }}
      >
        Origatório inserir uma foto
      </Checkbox>
      <Checkbox
        style={{ marginTop: 20 }}
        checked={second_image?.obrigatorio}
        disabled={viewOnly}
        onChange={() => {
          setRequired(!required)
          handleChangeList(id, "second_image", {
            ...second_image,
            imagem_name: second_image?.imagem_name || main_question, 
            obrigatorio: required
          })
        }}
      >
        Origatório inserir uma segunda foto da irregularidade
      </Checkbox>
    </Box>
  )
}

export default VehicleFormChecklistModalOption
