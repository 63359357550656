import { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { useOccurrency } from "../../../../hooks/useOcurrency";
import { Loading } from "../../../../components/Loading";
import { inCreationOrEditing } from "../../../../utils";
import { useStyles } from "./styles";

const listStatus = ["Ativo", "Inativo"];
const driverDescriptionList = ["Sim", "Não"];

export const OccurrencyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const {
    loading,
    setLoading,
    occurrencyData,
    getOccurrency,
    addOccurrency,
    updateOccurrency,
    setOccurrencyData,
  } = useOccurrency();

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar ocorrência";
    } else if (location.pathname.includes("edit")) {
      return "Editar ocorrência";
    } else {
      return "Ocorrência";
    }
  };

  useEffect(() => {
    if (id) {
      getOccurrency(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  return (
    <Formik
      enableReinitialize
      initialValues={occurrencyData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addOccurrency(values);
        } else {
          updateOccurrency(values);
        }
      }}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>
                Tipos de Ocorrências
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/occurrences")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button className={classes.buttonSave} type="submit">
                      {loading ? <Loading/> : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/occurrences")}
                    >
                      Descartar
                    </Button>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/occurrences/${id}/edit`)}
                    >
                      Editar
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados da Ocorrência
                      </Typography>
                    </Grid>

                    {!location.pathname.includes("new") ? (
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          ID
                        </InputLabel>
                        <TextField
                          name="id"
                          value={values.id}
                          onChange={handleChange}
                          placeholder="0000"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Nome"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Status*
                      </InputLabel>
                      <TextField
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {listStatus.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Descrição*
                      </Typography>
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        placeholder="Descrição"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        multiline
                        rows={4}
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Ocorrência
                      </Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Descrição de motorista
                      </InputLabel>
                      <TextField
                        name="driverDescription"
                        value={values.driverDescription}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {driverDescriptionList.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Quantidade de fotos
                      </InputLabel>
                      <TextField
                        name="photosQuantity"
                        value={values.images.length}
                        placeholder="Quantidade de fotos"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                      />
                    </Grid>

                    {values.images.length > 0
                      ? values.images.map((_, index) => (
                          <Grid
                            container
                            className={classes.imageTitleContainer}
                          >
                            <Grid item lg={11}>
                              <InputLabel className={classes.inputLabel}>
                                Imagem {index + 1}
                              </InputLabel>
                              <TextField
                                name="imageName"
                                value={values.images[index].imageName}
                                onChange={handleChange(
                                  `images[${index}].imageName`
                                )}
                                variant="outlined"
                                size="small"
                                fullWidth
                                required
                                disabled={!inCreationOrEditing(location)}
                              />
                            </Grid>
                            <Grid item lg={1}>
                              <IconButton
                                className={classes.buttonDelete}
                                onClick={() => {
                                  const filteredImages = values.images.filter(
                                    (_, indexFilter) => indexFilter !== index
                                  );
                                  setOccurrencyData({
                                    ...values,
                                    images: filteredImages,
                                  });
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))
                      : null}
                    <Grid item lg={12}>
                      <Button
                        className={classes.buttonAddImage}
                        onClick={() =>
                          setOccurrencyData({
                            ...values,
                            images: [
                              ...values.images,
                              {
                                imageName: "",
                                required: true,
                                order: values.images.length + 1,
                              },
                            ],
                          })
                        }
                        disabled={!inCreationOrEditing(location)}
                      >
                        Adicionar Imagem
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
