import { ChangeEvent, Fragment, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  MenuItem
} from "@material-ui/core";
import { Add as AddIcon, Search as SearchIcon, Delete } from "@material-ui/icons/";
import { InputMask } from "../../../../../components/InputMask";
import { ServiceOrder } from "../../../../../types/managementServiceOrderTypes";
import { onlyNumbers } from "../../../../../utils";

import { useStyles } from "./styles";
import { useDrivers } from "../../../../../hooks/Users/useDrivers";
import { useVehicles } from "../../../../../hooks/Users/useVehicles";

type StepDriverAndVehicleProps = {
  setServiceOrderData: React.Dispatch<React.SetStateAction<ServiceOrder>>;
  serviceOrderData: ServiceOrder;
  AddVehicle: () => void;
  handleAddDriver: (data: any) => void;
  handleAddVeichle: (data: any, index: number) => void
};

export const StepDriverAndVehicle = ({
  setServiceOrderData,
  serviceOrderData,
  AddVehicle,
  handleAddDriver,
  handleAddVeichle
}: StepDriverAndVehicleProps) => {
  const classes = useStyles();
  const { getAllDriversList } = useDrivers();
  const { getAllVehiclesList } = useVehicles()
  const [allDrivers, setAllDrivers] = useState([])
  const [selectedDriver, setSelectedDriver] = useState("");
  const [allVeichles, setAllVeichles] = useState([])
  const [selectedVeichle, setSelectedVeichle] = useState("");
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(0);

  const getAllData = async () => {
    const {data: drivers} = await getAllDriversList<any>({ paginationIndex: 0, rowsPerPage: 0 })
    const {data: vaichles} = await getAllVehiclesList<any>({ paginationIndex: 0, rowsPerPage: 0 })

    if(vaichles) {
      setAllDrivers(drivers as any)
    }

    if(drivers) {
      setAllVeichles(vaichles as any)
    }
  }

  useEffect(() => {
    getAllData()
  }, []);

  const handleDelete = (index: number) => {
    const prevData =serviceOrderData.vehicles

    let response = prevData?.filter((_, i) => index !== i)

    setServiceOrderData((prevState) => ({
      ...prevState,
     vehicles: response
    }));

  }

  return (
    <Grid container spacing={1} className={classes.formContainer}>
      <Grid item lg={12}>
        <Typography className={classes.formSectionTitle}>
          Dados do motorista
        </Typography>
      </Grid>
      <Grid container>
      <Grid
        item
        lg={3}
        style={{ padding: 4 }}
      >
        <InputLabel className={classes.inputLabel}>
          Selecionar Motorista*
        </InputLabel>
        <TextField
          name="driver"
          value={selectedDriver || serviceOrderData?.driver.id}
          onChange={e => {
            setSelectedDriver(e.target.value);
            const getItem = allDrivers?.find((item: any) => item.id === e.target.value) as unknown as {id: string, name: string}
            handleAddDriver(getItem)
          }}
          placeholder="Selecionar"
          variant="outlined"
          size="small"
          fullWidth
          select
          required
        >
          {allDrivers?.map((value: any) => (
            <MenuItem key={value?.id} value={value?.id}>
              {value?.name}
            </MenuItem>
          ))}
        </TextField>

      </Grid>
        <Grid item lg={3} style={{ padding: 4 }}>
          <InputLabel className={classes.inputLabel}>Motorista</InputLabel>
          <TextField
            value={serviceOrderData?.driver.name}
            placeholder="Motorista"
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>E-mail</InputLabel>
        <TextField
          value={serviceOrderData?.driver.email}
          placeholder="email@email.com"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>CNH</InputLabel>
        <TextField
          value={serviceOrderData?.driver.cnhNumber}
          placeholder="000000000000"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Categoria</InputLabel>
        <TextField
          value={serviceOrderData?.driver.cnhCategory}
          placeholder="E"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Validade da CNH</InputLabel>
        <TextField
          value={serviceOrderData?.driver.cnhExpirationDate}
          placeholder="00/00/0000"
          variant="outlined"
          size="small"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Crachá</InputLabel>
        <TextField
          value={serviceOrderData?.driver.badge}
          placeholder="000000"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Telefone 1*</InputLabel>
        <TextField
          value={serviceOrderData?.driver.phoneFirst}
          placeholder="(00) 00000-0000"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Telefone 2</InputLabel>
        <TextField
          value={serviceOrderData?.driver.phoneSecond}
          placeholder="(00) 00000-0000"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={12}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>

      <Grid
        item
        lg={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography className={classes.formSectionTitle}>
          Dados do veículo
        </Typography>
        <Button
          startIcon={<AddIcon />}
          className={classes.buttonAddVehicle}
          onClick={AddVehicle}
        >
          Veículo
        </Button>
      </Grid>
      {serviceOrderData?.vehicles?.map((vehicle, index) => (
        <Fragment key={`vehicle-${index}`}>
          <Grid item lg={12}>
            <Typography 
              className={classes.vehicleSectionTitle} 
              style={{
                display: "flex",
                alignItems: "center", 
                justifyContent: "space-between", 
                height: 50, 
              }}
            >
              Veículo {`${1 + index}`}

              <button
                onClick={() => {
                  handleDelete(index)
                }}
                style={{
                  width: "auto",
                  height: "auto",
                  backgroundColor: "transparent",
                  padding: 0,
                  border: "none",
                  cursor: "pointer"
                }}
              >
                <Delete htmlColor="#2F4D7C" style={{margin: 0}}/>
              </button>
            </Typography>
          </Grid>
          <Grid
        item
        lg={3}
        style={{ padding: 4 }}
      >
        <InputLabel className={classes.inputLabel}>
          Selecionar Veículo*
        </InputLabel>
          <TextField
          name="driver"
          value={(selectedVehicleIndex === index ? selectedVeichle : null) || serviceOrderData?.vehicles[index].id}
          onChange={e => {
            setSelectedVeichle(e.target.value);
            const getItem = allVeichles?.find((item: any) => item.id === e.target.value) as unknown as {id: string, name: string}
            setSelectedVehicleIndex(index)
            handleAddVeichle(getItem, index)
          }}
          placeholder="Selecionar"
          variant="outlined"
          size="small"
          fullWidth
          select
          required
        >
          {allVeichles?.map((value: any) => (
            <MenuItem key={value?.id} value={value?.id}>
              {value?.name}
            </MenuItem>
          ))}
        </TextField>

      </Grid>
         
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Tipo</InputLabel>
            <TextField
              value={vehicle.vehicleType.name}
              placeholder="Tipo"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Renavam</InputLabel>
            <TextField
              value={vehicle.renavam}
              placeholder="Renavam"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Chassi</InputLabel>
            <TextField
              value={vehicle.chassi}
              placeholder="Chassi"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Placa</InputLabel>
            <TextField
              name="plate"
              value={vehicle.plate}
              placeholder="AAA-0000"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              InputProps={{
                inputComponent: InputMask as any,
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Empresa</InputLabel>
            <TextField
              value={vehicle.client}
              placeholder="Empresa"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Proprietário</InputLabel>
            <TextField
              value={vehicle.ownerName}
              placeholder="Proprietário"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Cor</InputLabel>
            <TextField
              value={vehicle.color}
              placeholder="Cor"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          {/* <Grid item lg={3}>
            <InputLabel className={classes.inputLabel}>Status</InputLabel>
            <TextField
              value={vehicle.status ? "Ativo" : "Inativo"}
              placeholder="Status"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid> */}
          <Grid item lg={12}>
            <Divider style={{ margin: "16px 0" }} />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};
