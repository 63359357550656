import { DriverInfo, listOSAvailableMonitoring } from ".";
import { errorMessage } from "../../../components/Messages"
import { api } from "../../../services/api"

export type ListToShowInOptionsComponent = {
  os_number: string,
  driver: string,
  id: string
}

type ResponseType = {
  listToShowInOptionsComponent: ListToShowInOptionsComponent[] | null
  loading: boolean
  selectedOS: string | null
}

export const getAvailableMonitoring = async (clientToQuery: string) => {
  const response: ResponseType = {
    listToShowInOptionsComponent: null,
    loading: true,
    selectedOS: null
  }

  try {
    const { data } = await api.get(`ordem-servico${clientToQuery}`);

     if(data?.statusCode === 200) {
      const getListOfOS = data.data.data.filter((item: any) => 
        item.status !== "Sem Aceite"
        && item.status !== "Cancelada" 
        && item.status !== "Finalizada"
      )

      const listToShowInOptionsComponent = getListOfOS.map((item: any) => ({
        os_number: item?.numero,
        driver: item?.motorista?.nome,
        id: item?._id
      })) as listOSAvailableMonitoring[]

      response.listToShowInOptionsComponent = listToShowInOptionsComponent
      response.selectedOS = listToShowInOptionsComponent[getListOfOS.length - 1]?.id
     }
  } catch (error: any) {
    console.log(error.response);
    errorMessage("Erro ao tentar buscar as coordenadas do motoristas")
  } finally {
    response.loading = false
  }

  return response
};

type ResType = {
  data: DriverInfo[] | null
}

export const getAllServiceOrderMonitoring = async (clientToQuery: string, onLoading?: (loading: boolean) => void) => {
  const response: ResType = {
    data: null
  }

  onLoading && onLoading(true)

  try{
    const res = await api.get(`monitoramento-os${clientToQuery}`)

    response.data = res?.data?.filter((item: any) => 
      item.status !== "Sem Aceite"
      && item.status !== "Cancelada" 
      && item.status !== "Finalizada"
    )
  }catch (error: any) {
    errorMessage("Erro ao tentar buscar as coordenadas das OS")
  }finally {
    onLoading && onLoading(false)
  }

  return response
}