import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    searchBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 16,
    },
    infoWindowContent: {
      background: `white`,
      padding: 8,
    },
    infoWindowText: {
      color: "#2F4D7C",
      fontWeight: 500,
    },
    infoWindowValue: {
      color: "#6A6A6A",
      marginLeft: 4,
      fontWeight: 500,
    },
  })
);
