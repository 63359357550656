import PNG from '../../assets/png.png'
import JPG from '../../assets/jpg.png'
import PDF from '../../assets/pdf.png'
import MP4 from '../../assets/mp4.png'
import FILE from '../../assets/google-docs.png'

export type Code = "file-invalid-type" | "file-too-large" | string

export const chooseErrorMessageFromCode = (code: Code) => {
  if(code === 'file-too-large') {
    return 'Tamanho do arquivo excedito  (máx. 1MB)'
  }

  if(code === 'file-invalid-type') {
    return 'Formato de arquivo inválido'
  }

  return 'Arquivo Rejeitado'
}

export const chooseImage = (type: string) => {
  if(type.includes('pdf')) {
    return <img src={PDF} alt="Icon PDF" style={{ width: 40, height: 40 }} />
  }

  if(type.includes('video')) {
    return <img src={MP4} alt="Icon MP4" style={{ width: 40, height: 40 }} />
  }

  if(type.includes('png')) {
    return <img src={PNG} alt="Icon PNG" style={{ width: 40, height: 40 }} />
  }

  if(type.includes('jpg') || type.includes('jpeg')) {
    return <img src={JPG} alt="Icon JPEG" style={{ width: 40, height: 40 }} />
  }

  return <img src={FILE} alt="Icon FILE" style={{ width: 40, height: 40 }} />
}

export const formatSize = (byte: number) => {
  if (byte < 1000000) {
    return {
      size: parseFloat((byte / 1000).toFixed(1)),
      unit: 'KB'
    }
  } 
  if (byte < 1000000000) {
    return {
      size: parseFloat((byte / 1000000).toFixed(1)),
      unit: 'MB'
    }
  } 
  if (byte < 1000000000000) {
    return {
      size: parseFloat((byte / 1000000000).toFixed(1)),
      unit: 'GB'
    }
  }

  return {
    size: byte,
    unit: 'B'
  }
}