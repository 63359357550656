import Pagination from "@material-ui/lab/Pagination";

interface TablePaginationProps {
  count: number;
  page: number;
  onChange: (_: React.ChangeEvent<unknown>, value: number) => void;
}

export const TablePagination = ({
  count,
  page,
  onChange,
}: TablePaginationProps) => {
  return (
    <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
      <Pagination count={count} page={page} onChange={onChange} size="small" />
    </div>
  );
};
