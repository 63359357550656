import { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import Map from "../../../../../components/Map";
import { Loading } from "../../../../../components/Loading";
import { getLastDriverCoords } from "./services";
import { useQuery } from "@tanstack/react-query";

interface DriverInfo  {
  createdAt: string;
  inicio: string;
  localizacao: {
    latitude: number;
    longitude: number;
  },
  motorista: {
    nome: string;
    _id: string;
  };
  numero: string;
  os_id: string;
  status: string;
  ultima_atualizacao: string;
  updatedAt: string;
  veiculo: {
    nome: string;
    _id: string;
  };
  _id: string;
}

export const TabMonitoring = () => {
  const { id } = useParams();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDCoRI68p_w2yvN1ZuW61Sqo1w2QCuT468",
  });
  const [listDrivers, setDrivers] = useState<DriverInfo[]>()

  const [slectedDriverInfo, setSelectedDriverInfo] = useState<DriverInfo>({} as DriverInfo)

  const getMonitorings = () => getLastDriverCoords(id || '')
  const {data, isLoading} = useQuery({ 
    queryKey: ['monitoringList-os'], 
    queryFn: getMonitorings, 
    refetchInterval: 10000 
  })

  const getData = async () => {
    if(data?.data) {
      setDrivers(data.data)

      setSelectedDriverInfo(data.data[0])
    }
  }

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])

  if(!isLoaded || !listDrivers) {
    return(
      <div 
        style={{
          width: "100%", 
          height: "75vh", 
          display: "flex", 
          alignItems:"center", 
          justifyContent: "center"
        }}
      > 
        <Loading size={30} color="#2F4D7C"/>
      </div>
    )
  } 

  return (
    <div style={{width: "100%", height: "75vh"}}>
      <Map 
        markers={listDrivers}
      />
    </div>
  );
};
