/* eslint-disable no-unreachable */
export const chooseRowBgColor = (index: number = 0, highlight: boolean, colors: string[]) => {
  if (highlight) {
    return highlight;
  }

  //return index % 2 === 0 ? '#fff' : '#fff';
  //return index % 2 === 0 ? colors[0] : colors[1];

  return colors[index % 2];
};
