import { useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  useQuery,
} from '@tanstack/react-query'
import {
  useLoadScript,
} from "@react-google-maps/api";
import { useStyles } from "./styles";
import "./styles.css";
import { Loading } from "../../../components/Loading";
import Map from "../../../components/Map";
import { LOCAL_STORAGE_KEY } from "../../../consts";
import { getAllServiceOrderMonitoring, getAvailableMonitoring } from "./services";

export interface DriverInfo  {
  createdAt: string;
  inicio: string;
  localizacao: {
    latitude: number;
    longitude: number;
  },
  motorista: {
    nome: string;
    _id: string;
  };
  numero: string;
  os_id: string;
  status: string;
  ultima_atualizacao: string;
  updatedAt: string;
  veiculo: {
    nome: string;
    _id: string;
  };
  _id: string;
}

export type listOSAvailableMonitoring = {
  os_number: string,
  id: string,
  driver: string
}

export const Monitoring = () => {
  const classes = useStyles();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDCoRI68p_w2yvN1ZuW61Sqo1w2QCuT468",
  });
  const [listDrivers, setDrivers] = useState<DriverInfo[]>();
  const [slectedDriverInfo, setSelectedDriverInfo] = useState<DriverInfo>({} as DriverInfo)
  const [loading, setLoading] = useState(true);
  const [selectedOS, setSelectedOS] = useState<string>();
  const [listOSAvailableMonitoring, setListOSAvailableMonitoring] = useState<listOSAvailableMonitoring[]>([])

  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
  const client_id = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_CLIENT_ID)
      
  const isClient = userRole?.toString()?.includes('Cliente')
  const clientToQuery = `${isClient ? `?client_id=${JSON.parse(client_id || '')}`: ''}` 

  const getOptions = () => getAvailableMonitoring(clientToQuery)
  const getMonitorings = () => getAllServiceOrderMonitoring(clientToQuery)
  const {data, isLoading} = useQuery({ 
    queryKey: ['optionsList'], 
    queryFn: getOptions, 
    refetchInterval: 10000 
  })
  const {
    data: monitoring, 
    isLoading :secondLoading
  } = useQuery({ 
    queryKey: ['monitoringList'], 
    queryFn: getMonitorings, 
    refetchInterval: 10000 
  })

  const listToShowInOptionsComponent = data?.listToShowInOptionsComponent
  const OSID = data?.selectedOS

  const getData = async () => {
    if(monitoring?.data) {  
      setDrivers(monitoring.data)
    }

    if(listToShowInOptionsComponent?.length) {  
      setListOSAvailableMonitoring(listToShowInOptionsComponent)
      setSelectedOS(OSID || '')
    }

    setLoading(isLoading || secondLoading)

   
  }

  const handleSelectOS = (osId: string) => {
    let OS = listDrivers?.find(item => item.os_id === osId)

    if(OS) {
      setSelectedDriverInfo(OS)
    }
  }

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, secondLoading, monitoring]);

  if(!isLoaded || !listDrivers) {
    return(
      <div 
        style={{
          width: "100%", 
          height: "75vh", 
          display: "flex", 
          alignItems:"center", 
          justifyContent: "center"
        }}
      > 
        <Loading size={30} color="#2F4D7C"/>
      </div>
    )
  } 

  return (
    <Grid container>
      <Grid item style={{width: "100%"}}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Monitoramento Geral de OS 
        </Typography>
        <Typography style={{ color: "#5A5A5A", fontSize: 18, fontWeight: 500, marginBottom: 20 }}>
          OSs em andamento: {listOSAvailableMonitoring?.length || 0}
        </Typography>
      </Grid>
      <Grid item style={{width: "100%"}}>
        <Paper className={classes.paper}>
          <div className={classes.searchBarContainer}>
            <TextField
              onChange={e => {
                handleSelectOS(e.target.value)
              }}
              placeholder="Selecionar"
              variant="outlined"
              size="small"
              label="Selecionar OS"
              fullWidth
              select
            >
              <MenuItem value="Selecionar" disabled>
                Selecionar
              </MenuItem>
              {listOSAvailableMonitoring
                .map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.os_number} -  {item?.driver}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </Paper>
      </Grid>
        <div style={{width: "100%", height: "75vh", position: "relative"}}>
        {loading && 
        (
          <div style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "rgba(187, 204, 233, 0.8)",
            zIndex: 3,
            top: 12,
            display: "flex", 
            alignItems:"center", 
            justifyContent: "center"
          }}>
            <Loading size={30} color="#2F4D7C"/>
          </div>)
        }
      
        <Map 
          markers={listDrivers} 
          selected={slectedDriverInfo}
          hideDirections
          //zoom={}
        />
      </div>
    </Grid>
  );
};
;
