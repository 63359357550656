export const SKIPLIMIT = 10;

export const LIST_TIME = [
  { id: 1, value: "1 mês" },
  { id: 2, value: "2 meses" },
  { id: 3, value: "3 meses" },
  { id: 4, value: "4 meses" },
  { id: 5, value: "5 meses" },
  { id: 6, value: "6 meses" },
  { id: 7, value: "7 meses" },
  { id: 8, value: "8 meses" },
  { id: 9, value: "9 meses" },
  { id: 10, value: "10 meses" },
  { id: 11, value: "11 meses" },
  { id: 12, value: "1 ano" },
];

export const LIST_HOURS = [
  { id: 1, value: "1 Hora" },
  { id: 2, value: "2 Horas" },
  { id: 3, value: "3 Horas" },
  { id: 4, value: "4 Horas" },
  { id: 5, value: "5 Horas" },
  { id: 6, value: "6 Horas" },
  { id: 7, value: "7 Horas" },
  { id: 8, value: "8 Horas" },
  { id: 9, value: "9 Horas" },
  { id: 10, value: "10 Horas" },
  { id: 11, value: "11 Horas" },
  { id: 12, value: "12 Horas" },
  { id: 13, value: "13 Horas" },
  { id: 14, value: "14 Horas" },
  { id: 15, value: "15 Horas" },
  { id: 16, value: "16 Horas" },
  { id: 17, value: "17 Horas" },
  { id: 18, value: "18 Horas" },
  { id: 19, value: "19 Horas" },
  { id: 20, value: "20 Horas" },
  { id: 21, value: "21 Horas" },
  { id: 22, value: "22 Horas" },
  { id: 23, value: "23 Horas" },
];

export const IMAGENS_DOCUMENTOS = [
  {
    ordem: 0,
    imagem_name: "Foto CRLV",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 1,
    imagem_name: "Foto ANTT",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 2,
    imagem_name: "Foto Tacógrafo",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
];

export const IMAGENS_VEICULO = [
  {
    ordem: 1,
    imagem_name: "Parachoque dianteiro",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 2,
    imagem_name: "Parachoque traseiro",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 3,
    imagem_name: "Farol dianteiro",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 4,
    imagem_name: "Farol traseiro",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 5,
    imagem_name: "Motor",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 6,
    imagem_name: "Piso da unidade cavalo",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 7,
    imagem_name: "Tanque de combustível do motorista",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 8,
    imagem_name: "Tanque de combustível do passageiro",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 9,
    imagem_name: "Cabine",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 10,
    imagem_name: "Bolsa de ar",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 11,
    imagem_name: "Eixo de transmissão",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 12,
    imagem_name: "Quinta roda",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 13,
    imagem_name: "Conexões pneumáticas",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 14,
    imagem_name: "Sistema de exaustão",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 15,
    imagem_name: "Chassi do cavalo",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 16,
    imagem_name: "Chassi da carreta",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
];

export const IMAGENS_PNEUS = [
  {
    ordem: 1,
    imagem_name: "Pneu direcional motorista",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 2,
    imagem_name: "Pneu direcional Passageiro",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 3,
    imagem_name:
      "Pneu do 1º eixo do cavalo mecânico do lado do motorista(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 4,
    imagem_name:
      "Pneu do 1º eixo do cavalo mecânico do lado do motorista(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 5,
    imagem_name:
      "Pneu do 1º eixo do cavalo mecânico do lado do passageiro(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 6,
    imagem_name:
      "Pneu do 1º eixo do cavalo mecânico do lado do passageiro(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 7,
    imagem_name:
      "Pneu do 2º eixo do cavalo mecânico do lado do motorista(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 8,
    imagem_name:
      "Pneu do 2º eixo do cavalo mecânico do lado do motorista(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 9,
    imagem_name:
      "Pneu do 2º eixo do cavalo mecânico do lado do passageiro(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 10,
    imagem_name:
      "Pneu do 2º eixo do cavalo mecânico do lado do passageiro(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 11,
    imagem_name:
      "Pneu do 1º eixo da carreta do lado do motorista(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 12,
    imagem_name:
      "Pneu do 1º eixo da carreta do lado do motorista(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 13,
    imagem_name:
      "Pneu do 1º eixo da carreta do lado do passageiro(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 14,
    imagem_name:
      "Pneu do 1º eixo da carreta do lado do passageiro(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 15,
    imagem_name:
      "Pneu do 2º eixo da carreta do lado do motorista(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 16,
    imagem_name:
      "Pneu do 2º eixo da carreta do lado do motorista(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 17,
    imagem_name:
      "Pneu do 2º eixo da carreta do lado do passageiro(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 18,
    imagem_name:
      "Pneu do 2º eixo da carreta do lado do passageiro(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 19,
    imagem_name:
      "Pneu do 3º eixo da carreta do lado do motorista(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 20,
    imagem_name:
      "Pneu do 3º eixo da carreta do lado do motorista(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 21,
    imagem_name:
      "Pneu do 3º eixo da carreta do lado do passageiro(lado interno)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
  {
    ordem: 22,
    imagem_name:
      "Pneu do 3º eixo da carreta do lado do passageiro(lado externo)",
    imagem_path: "",
    file_name: "",
    obrigatorio: true,
  },
];

export const LOCAL_STORAGE_KEY = {
  'FORTALLOG_AUTH_TOKEN': 'FORTALLOG_AUTH_TOKEN',
  'FORTALLOG_USER_NAME': 'FORTALLOG_USER_NAME',
  'FORTALLOG_USER_ROLE': 'FORTALLOG_USER_ROLE',
  'FORTALLOG_USER_ID': 'FORTALLOG_USER_ID',
  'FORTALLOG_CLIENT_ID': 'FORTALLOG_CLIENT_ID',
  'FORTALLOG_IS_SIGNED': 'FORTALLOG_IS_SIGNED'
}