import React, { useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LOCAL_STORAGE_KEY } from "../../consts";
import {
  ArrowDropDown,
  Person,
  ExitToAppOutlined as ExitIcon
} from "@material-ui/icons";
import { AuthUserContext } from '../../contexts/AuthUserContext';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

const iconsStyle = {
  height: 16, 
  width: 16, 
  marginRight: 5
}

const LogoutManu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { setIsSigned } = useContext(AuthUserContext);

  const navigate = useNavigate();
  
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToProfilePage = () => {
    handleClose();
    navigate('/profile')
  }

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.FORTALLOG_IS_SIGNED);
    localStorage.removeItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_NAME);
    localStorage.removeItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.FORTALLOG_AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE)
    handleClose();
    
    setIsSigned(false);
    window.location.reload();
  }

  return (
    <div style={{ height: 20 }}>
      <button   
        onClick={(e) => handleClick(e)}
        style={{
          all: 'unset',
          height: 20,
          margin: 0,
          cursor: 'pointer'
        }}
      >
          <ArrowDropDown htmlColor='#1C1C1C' style={{ height: 20, margin: 0, padding: 0 }}/>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => goToProfilePage()}>
          <Person style={iconsStyle}/>
          Perfil
        </MenuItem>
        <MenuItem onClick={() => logout()}>
          <ExitIcon style={iconsStyle}/>
          Sair
        </MenuItem>
      </Menu>
    </div>
  );
}

export default LogoutManu