import {FC} from 'react';
import { CircularProgress, Grid } from "@material-ui/core";

export const Loading: FC<{color?: string, size?: number }> = ({size= 20, color= "#fff"}) => {
  return (
    <Grid item lg={12} style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={size} style={{color}} />
    </Grid>
  );
};
