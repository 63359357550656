import { useMemo, FC, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";

import { TablePagination } from "../../../components/TablePagination";
import { TableActions } from "../../../components/TableActions";
import { NotificationsContext } from "../../../contexts/NotificationsContext";
import { useStyles } from "./styles";
import moment from "moment";
import { useRequestSWR } from "../../../services/useSWR";
import { readNotification, readAllNotification } from "./services/handleReadNotification";
import { deleteNotification } from "./services/handleDeleteNotification";
import { Loading } from "../../../components/Loading";
import GenericTable from "../../../components/GenericTable";
import { notification } from "antd";
moment.locale("pt-br");

const tableHeader = [
  {key: 'dateHour', label: 'Data/Hora'},
  {key: 'osNumber', label: 'Ordem de Serviço'},
  {key: 'operationType', label: 'Tipo de Operação'},
  {key: 'status', label: 'Status'},
  {key: 'marAsRead', label: "", asButton: true}
]

const ButtonReadNotification: FC<{
  status: boolean, 
  id: string,
}> = ({id, status}) => {
  const [loading, setLoading] = useState(false);

  const handleMarkAsRead = async () => {
    setLoading(true)
    try {
      await readNotification(id, isLoading => null)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  return(
    <button 
      disabled={status}
      style={{
        height: 30,
        minWidth: 150, 
        border: 0,
        cursor: "pointer",
        borderRadius: "4px",
        padding: "0 12px",
        background: "#DADADA"
      }}
      onClick={() => handleMarkAsRead()}
    >
      {loading ? <Loading /> : "Marcar como lida"}
    </button>
  )
}

export const Notifications = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { 
    listNotifications, 
    pagination, 
    handleChangePagination, 
    handleChangeRowsPerPage,
    loading,
    total
  } = useContext(NotificationsContext)

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page");

  const [loadingAll, setLoadingAll] = useState(false);

  useEffect(() => {
    if(page) {
      handleChangePagination(Number(page))
    }
  },[page])

  const dateToSee = (data: any) => {
    return data?.map((not: any) => ({
      ...not,
      status: (
        <Typography
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
            padding: "4px 0px",
            borderRadius: "4px",
            fontWeight: "bold",
            fontSize: 12,
            color: (not?.status as Boolean) ? "#252525" : "#fff",
            opacity: "0.8",
            backgroundColor: (not?.status as Boolean)
              ? "#6FED8B"
              : "#FF3C40",
          }}
        >
          {(not?.status as Boolean) ? "Lida" : "Não lida"}
        </Typography>
      ),
      marAsRead: (
        <ButtonReadNotification
          id={not?.id}
          status={not?.status}
        />
      ) 
    }))
  }

  const userStorage = localStorage?.getItem("userId");  

  const handleViewServiceOrder = async (paramsRow: any) => {

    if(paramsRow.status) {
      navigate(`/service-order/${paramsRow?.os_id}`);
      return;
    }
    
    readNotification(paramsRow.id, () => null)

    navigate(`/service-order/${paramsRow?.os_id}`);
  };

  const handleDeleteNotification = async (params: any) => {
    deleteNotification(params.id, () => null)
  }


  return (
    <Grid container spacing={2}>
      <Grid item lg={12} style={{ display: "flex", justifyContent: "space-between"  }}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Notificações
        </Typography>

        <Button
          className={classes.buttonRegister}
          onClick={() => readAllNotification(setLoadingAll)}
        >
          {loadingAll ? "Carregando..." : "Marcar todas como lidas"}
        </Button>
      </Grid>

      <Grid item lg={12}>
      <GenericTable
        header={tableHeader}
        data={dateToSee(listNotifications)}
        isLoading={loading}
        handleDelete={(item) => handleDeleteNotification(item)}
        onClick={(item) => handleViewServiceOrder(item)}
        total={total || 0}
        rowsPerPage={pagination.rowsPerPage}
        activeIndex={Number(page)}
        onChangeIndex={(index) =>  setSearchParams({ page: index} as any)}
        onChangeRowsPerPage={(value) => handleChangeRowsPerPage(value)}
      />
       
      </Grid>
    </Grid>
  );
};
