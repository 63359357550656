import { makeStyles, createStyles, Theme, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      "&:disabled": {
        color: '#ffffff80',
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonCancel: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#FF3C40",
      "&:hover": {
        backgroundColor: "#FF3C4080",
      },
      textDecoration: "none",
    },
  })
);

export const boxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: 2,
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: '0px solid #00000000',
  boxShadow: 24,
  p: 4,
};

export const dropzoneWrapperStyle = {
  width: '100%',
  height: 200,
  border: '1px solid #00000010',
  borderRadius: 2,
  marginTop: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const buttonsWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 4
}