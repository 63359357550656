import { errorMessage, successMessage } from "../../../../components/Messages";
import { api } from "../../../../services/api"

export const deleteNotification = async (
  notificationID: string, 
  handleLoanding: (isLoading: boolean) => void) => {
  
  handleLoanding(true)

  try {
    await api.delete(`notificacoes/${notificationID}`);

    successMessage("Notificação Eliminada com sucesso!")
  } catch (error) {
    console.log(error)
    errorMessage("Erro ao tentar eliminar notificação, tente novamente mais tarde")
  } finally {
    handleLoanding(false)
  }
}