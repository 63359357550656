import { ReactNode } from "react"
import { Button, Typography, Dialog, DialogContent } from "@material-ui/core";
import { useStyles } from "./styles";
import { Loading } from "../../Loading";

type ModalDeleteProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  onOK: () => void;
  cancelText?: string; 
  okText?: string;
  description?: string;
  loading?: boolean;
  children?: ReactNode
  disableButton?: boolean
};

export const ModalConfirm = ({
  isModalOpen,
  closeModal,
  onOK,
  description,
  okText,
  cancelText,
  children,
  disableButton,
  loading
}: ModalDeleteProps) => {
  const classes = useStyles();

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogContent style={{ marginLeft: 12, marginRight: 12 }}>
        <div>
          <Typography style={{ fontSize: 24, textAlign: "center" }}>
           {description || "Deseja continuar com essa ação?"}
          </Typography>
        </div>
      </DialogContent>
      {children}
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 12,
          marginRight: 12,
          paddingBottom: 24,
        }}
      >
        <Button className={classes.buttonBack} onClick={closeModal}>
          {cancelText || "Não desejo"}
        </Button>
        <Button
          disabled={disableButton}
          className={classes.buttonDelete}
          variant="text"
          onClick={() => {
            onOK();
          }}
        >
          {loading ? <Loading/> : okText || "Sim, desejo"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};