import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
} from "react";
import { api } from "../services/api";
import { LOCAL_STORAGE_KEY } from "../consts";

type Props = {
  children: ReactNode;
};

type PropsAuthUserContext = {
  isSigned: boolean;
  setIsSigned: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
};

const initialState = {
  isSigned: false,
  setIsSigned: () => {},
  loading: true,
};

const AuthUserContext = createContext<PropsAuthUserContext>(initialState);

const AuthUserContextProvider = ({ children }: Props) => {
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const storageIsAuthenticated = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_IS_SIGNED);

  // useEffect(() => {
  //   const loadStorageData = () => {
  //     // if (storageIsAuthenticated) {
  //     //   setIsSigned(JSON.parse(storageIsAuthenticated));
  //     // }
  //     const storageToken = localStorage.getItem("token");

  //     if (storageToken) {
  //       api.defaults.headers.common["Authorization"] = `Bearer ${storageToken}`;
  //       // setLoading(false);
  //     }
  //   };

  //   loadStorageData();
  // }, []);

  return (
    <AuthUserContext.Provider value={{ isSigned, setIsSigned, loading }}>
      {children}
    </AuthUserContext.Provider>
  );
};

export { AuthUserContext, AuthUserContextProvider };
