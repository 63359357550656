import { ReactNode, useContext } from "react";

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AssignmentOutlined as AssignmentOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  CommuteOutlined as CommuteOutlinedIcon,
  PublicOutlined as PublicOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
  PlaylistAddCheckOutlined as ChecklistIcon,
  BusinessOutlined as ClientsIcon,
  GroupOutlined as UsersIcon,
  LocalShippingOutlined as VehiclesIcon,
  ExitToAppOutlined as ExitIcon
} from "@material-ui/icons";
import {
  BsMegaphone as OccurrencesIcon,
  BsPersonLinesFill as DriversIcon,
} from "react-icons/bs";
import { TbTruckLoading as TruckLoadingIcon } from "react-icons/tb";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { useStyles } from "./styles";

import imageLogo from "../../assets/fortallog_logo.svg";
import { NotificationsContext } from "../../contexts/NotificationsContext";
import { LOCAL_STORAGE_KEY } from "../../consts";
import LogoutManu from "./logoutMenu";

interface LayoutProps {
  children: ReactNode;
}

const menuListForClient = [
  {
      title: "Ordem de Serviço",
      itemList: [
        {
          itemTitle: "Ordens de Serviço",
          itemLocation: "/home",
          icon: (
            <AssignmentOutlinedIcon
              fontSize="small"
              style={{
                marginRight: 12,
              }}
            />
          ),
        },
        {
          itemTitle: "Monitoramento Geral de OS",
          itemLocation: "/monitoring",
          icon: (
            <LocationOnOutlinedIcon
              fontSize="small"
              style={{
                marginRight: 12,
              }}
            />
          ),
        },
      ],
    },
]

const menuList = [
  {
    title: "Ordem de Serviço",
    itemList: [
      {
        itemTitle: "Ordens de Serviço",
        itemLocation: "/home",
        icon: (
          <AssignmentOutlinedIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Monitoramento Geral de OS",
        itemLocation: "/monitoring",
        icon: (
          <LocationOnOutlinedIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Notificações",
        itemLocation: "/notifications?page=0",
        icon: (
          <NotificationsOutlinedIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
    ],
  },
  {
    title: "Gestão de Ordem de Serviço",
    itemList: [
      {
        itemTitle: "Tipos de veículos",
        itemLocation: "/vehicle-type",
        icon: (
          <CommuteOutlinedIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Tipos de cargas",
        itemLocation: "/load-type",
        icon: (
          <TruckLoadingIcon
            style={{
              fontSize: 20,
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Tipos de modal",
        itemLocation: "/modal-type",
        icon: (
          <PublicOutlinedIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Tipos de programação",
        itemLocation: "/schedule-type",
        icon: (
          <SettingsOutlinedIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      // {
      //   itemTitle: "Status de ordem de serviço",
      //   itemLocation: "/status",
      //   icon: (
      //     <AssignmentTurnedInOutlinedIcon
      //       fontSize="small"
      //       style={{
      //         marginRight: 12,
      //       }}
      //     />
      //   ),
      // },
      {
        itemTitle: "Ocorrências",
        itemLocation: "/occurrences",
        icon: (
          <OccurrencesIcon
            style={{
              fontSize: 18,
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Checklist",
        itemLocation: "/checklist",
        icon: (
          <ChecklistIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      // {
      //   itemTitle: "Endereços",
      //   itemLocation: "/adresses",
      //   icon: (
      //     <LocationCityOutlined
      //       fontSize="small"
      //       style={{
      //         marginRight: 12,
      //       }}
      //     />
      //   ),
      // },
    ],
  },
  {
    title: "Gestão de Usuários",
    itemList: [
      {
        itemTitle: "Usuários",
        itemLocation: "/users",
        icon: (
          <UsersIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Motoristas",
        itemLocation: "/drivers",
        icon: (
          <DriversIcon
            style={{
              fontSize: 20,
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Veículos",
        itemLocation: "/vehicles",
        icon: (
          <VehiclesIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
      {
        itemTitle: "Clientes",
        itemLocation: "/clients",
        icon: (
          <ClientsIcon
            fontSize="small"
            style={{
              marginRight: 12,
            }}
          />
        ),
      },
    ],
  },
];

export function Layout({ children }: LayoutProps) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { totalUnread } = useContext(NotificationsContext);

  const userName = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_NAME);
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);

  const getSelected = (itemLocation: string) => {
    if(itemLocation.includes("/notifications")) {
      return location.pathname.includes("/notifications")
    }
    return location.pathname.includes(itemLocation)
  }

  const list = userRole?.toString()?.includes('Cliente') ? menuListForClient : menuList

  const drawer = (
    <div>
      <Toolbar style={{ backgroundColor: "#FFF" }}>
        <Typography variant="h6" noWrap style={{ color: "black" }}>
          Fortal Log
        </Typography>
        <ExitIcon
          onClick={() => {
            localStorage.setItem(LOCAL_STORAGE_KEY.FORTALLOG_IS_SIGNED, JSON.stringify(false));
            navigate("/");
            window.location.reload();
          }}
        />
      </Toolbar>
      {list.map((item, index) => {
        return (
          <div key={index}>
            <Typography
              style={{
                color: "#000",
                fontWeight: "bold",
                margin: "8px 0 4px 8px",
              }}
            >
              {item.title}
            </Typography>
            <List style={{ padding: 0 }}>
              {item.itemList.map((itemMenu, itemIndex) => (
                <ListItem
                  key={itemIndex}
                  to={itemMenu.itemLocation}
                  component={Link}
                  selected={getSelected(itemMenu.itemLocation)}
                  style={{
                    color: getSelected(itemMenu.itemLocation)
                      ? "#63BFE7"
                      : "#4F4F4F",
                  }}
                >
                  {itemMenu.icon}

                  <ListItemText>
                    <Typography
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {itemMenu.itemTitle}
                    </Typography>
                  </ListItemText>
                  {itemMenu.itemLocation.includes("/notifications") &&
                    totalUnread && (
                      <Typography
                        style={{
                          color: "#000",
                          padding: "2px 8px",
                          backgroundColor: "#63BFE7",
                          borderRadius: "50%",
                          fontWeight: 500,
                        }}
                      >
                        {totalUnread >= 100 ? "99+" : totalUnread}
                      </Typography>
                    )}
                </ListItem>
              ))}
              <Divider />
            </List>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img style={{ height: 50 }} src={imageLogo} alt="fortallog-logo" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography style={{ color: "#1C1C1C", marginRight: 1 }}>
              Olá, {JSON.parse(userName || "")}
            </Typography>
            <LogoutManu/>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
}
