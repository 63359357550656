import useSWR from 'swr';
import axios from 'axios';
import { api } from './api';

const baseURL = api.getUri()

interface UseRequestSWRConfig {
  shouldRetryOnError?: boolean,
  errorRetryCount?: number,
  errorRetryInterval?: number,
  revalidateOnMount?: boolean,
  revalidateOnFocus?: boolean,
  refreshInterval?: number,
  revalidateOnReconnect?: boolean,
}

export const useRequestSWR = <T>(endpoint: string, {
  shouldRetryOnError= true,
  errorRetryCount= 3,
  errorRetryInterval= 1000,
  revalidateOnMount= true,
  revalidateOnFocus= true,
  refreshInterval= 10000,
  revalidateOnReconnect= true,
}: UseRequestSWRConfig) => {

  const { data, error, mutate } = useSWR(endpoint,

    async url => {
      const response = await api.get(url)
      
      return response
    },
    {
      shouldRetryOnError,
      errorRetryCount,
      errorRetryInterval,
      revalidateOnMount,
      revalidateOnFocus,
      refreshInterval,
      revalidateOnReconnect,
    }
  );

  const loading = !data && !error;

  return { 
    data: data?.data, 
    error, 
    mutate, 
    loading,
    status: data?.status
  };
};