import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles";

export const Container = styled.div`
  width: 600px;
  //min-height: 450px;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;

  padding: 30px 50px;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.span`
  color: #4E4E4E;
  font-size: 22px;
  font-weight: bolder;
`

export const Descroption = styled.span`
  color: #4E4E4E;
  font-size: 18px;
`

export const CheckboxList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  margin: 20px 0;
`;

export const RdiosWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const useStyles = makeStyles((theme) => ({
  buttons : {
    width: 140,
    fontSize: 14,
    fontWeight: "bold",
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  inputLabel: {
    marginBottom: 40,
    color: "#4E4E4E",
  },
  buttonExport: {
    width: 140,
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 30,
    paddingLeft: 30,
    marginLeft: 12,
    color: "#FFF",
    textTransform: "capitalize",
    transition: ".5s ease",
    backgroundColor: "#76A1E8",
    "&:hover": {
      backgroundColor: "#6991CF",
    },
    borderRadius: 5,
    textDecoration: "none",
  },
 
  buttonBack: {
    width: 200,
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 8,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 30,
    paddingLeft: 30,
    color: "#FFF",
    textTransform: "capitalize",
    transition: ".5s ease",
    backgroundColor: "#FF3C40",
    "&:hover": {
      backgroundColor: "rgba(255,60,64,0.8)",
    },
    borderRadius: 5,
    textDecoration: "none",
  },
  // buttonDelete: {
  //   width: 200,
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   marginLeft: 8,
  //   paddingTop: 12,
  //   paddingBottom: 12,
  //   paddingRight: 30,
  //   paddingLeft: 30,
  //   color: "#FFF",
  //   textTransform: "capitalize",
  //   transition: ".5s ease",
  //   backgroundColor: "#2EA949",
  //   "&:hover": {
  //     backgroundColor: "rgba(46,169,73,0.8)",
  //   },
  //   borderRadius: 5,
  //   textDecoration: "none",
  // },
  buttonDelete: {
    width: 200,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 8,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 30,
    paddingLeft: 30,
    color: "#2EA949",
    textTransform: "capitalize",
    transition: ".5s ease",
    // backgroundColor: "#2EA949",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "rgba(46,169,73,0.8)",
    },
    borderRadius: 5,
    textDecoration: "none",
  },
}));
