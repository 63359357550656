import axios from "axios";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../../components/Messages";
import {
  IMAGENS_DOCUMENTOS,
  IMAGENS_PNEUS,
  IMAGENS_VEICULO,
  SKIPLIMIT,
} from "../../consts";
import { api } from "../../services/api";
import { ImageModel, Vehicle } from "../../types/managementUserTypes";

type VehicleData = {
  id: string;
  createdAt: string;
  name: string;
  type: string;
  renavam: string;
  chassis: string;
  plate: string;
  status: boolean;
};

interface UpdateImagesProps {
  imageDocuments: ImageModel[],
  imageTires: ImageModel[],
  imageVehicle: ImageModel[],
  vehicle: any;
}

interface TypeOfVehicle  { 
  id: string, 
  name: string, 
  imageDocuments: any,
  imageTires: any,
  imageVehicle: any
}

export function useVehicles() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [vehicleData, setVehicleData] = useState<Vehicle>({
    id: "",
    name: "",
    status: "Ativo",
    createdAt: "",
    renavam: "",
    city: "",
    state_UF: "",
    chassi: "",
    plate: "",
    color: "",
    vehicleType: "",
    updateRegisterFrequency: 1,
    imageDocuments: IMAGENS_DOCUMENTOS as any,
    imageTires: IMAGENS_PNEUS as any,
    imageVehicle: IMAGENS_VEICULO as any,
    owner: {
      name: "",
      cpfCnpj: "",
      rg: "",
      birthDate: "",
    },
    client: { id: "", name: "" },
    responsibleDriver: { id: "", name: "" },
  });

  const updateImages = (images: UpdateImagesProps) => {
    const {
      imageDocuments,
      imageTires,
      imageVehicle,
      vehicle
    } = images;
    setVehicleData({
      ...vehicle,
      imageDocuments,
      imageTires,
      imageVehicle
    })
  }

  const [listOfVeVehicleType, setListOfVeVehicleType] = useState<TypeOfVehicle[]>([] as TypeOfVehicle[])
  
  const [selectList, setSelectList] = useState({
    status: ["Ativo", "Inativo"],
    updateRegisterFrequency: [
      { id: 1, value: "1 mês" },
      { id: 2, value: "2 meses" },
      { id: 3, value: "3 meses" },
      { id: 4, value: "4 meses" },
      { id: 5, value: "5 meses" },
      { id: 6, value: "6 meses" },
      { id: 7, value: "7 meses" },
      { id: 8, value: "8 meses" },
      { id: 9, value: "9 meses" },
      { id: 10, value: "10 meses" },
      { id: 11, value: "11 meses" },
      { id: 12, value: "1 ano" },
    ],
    listVehicleType: [
      { id: "", 
      name: "", 
      imageDocuments: {} as any,
      imageTires: {} as any,
      imageVehicle: {} as any
     }
    ],
  });

  const getVehicle = useCallback((id: string) => {
    const vehicle = api.get(`veiculos/${id}`);
    const listVehicleType = api.get("veiculo-tipos");
    setLoading(true);

    axios
      .all([vehicle, listVehicleType])
      .then(
        axios.spread((...responses) => {
          const vehicle = responses[0];
          const listVehicleType = responses[1];
          
          const vehicleInfo = vehicle?.data?.data;

          setVehicleData((prevState) => ({
            ...prevState,
            id: vehicleInfo?._id,
            name: vehicleInfo?.nome,
            plate: vehicleInfo?.placa,
            renavam: vehicleInfo?.renavam,
            vehicleType: vehicleInfo?.veiculo_tipo?._id || vehicleInfo?.veiculo_tipo_id,
            chassi: vehicleInfo?.chassis,
            color: vehicleInfo?.cor,
            city: vehicleInfo?.cidade || "",
            state_UF: vehicleInfo?.estado_uf || "",
            client: {
              id: vehicleInfo?.cliente
                ? vehicleInfo?.cliente._id
                : "",
              name: vehicleInfo?.cliente
                ? vehicleInfo?.cliente.nome
                : "",
            },
            updateRegisterFrequency:
              vehicleInfo?.frequencia_atualizacao_cadastral,
            owner: {
              name: vehicleInfo?.proprietario?.nome,
              cpfCnpj: vehicleInfo?.proprietario?.cpf_cnpj,
              rg: vehicleInfo?.proprietario?.rg,
              birthDate: vehicleInfo?.proprietario?.data_nascimento,
            },
            responsibleDriver: {
              ...vehicleData.responsibleDriver,
              id: vehicleInfo?.motorista_responsavel?._id,
              name: vehicleInfo?.proprietario?.nome
            },
            createdAt: vehicleInfo?.createdAt?  `${new Date(
              vehicleInfo?.createdAt
            ).toLocaleDateString()} - ${new Date(vehicleInfo?.createdAt)
              .toISOString()
              .slice(11, 16)}` : "",
            status: vehicleInfo?.status ? "Ativo" : "Inativo",
            imageDocuments: vehicleInfo?.imagem_documentos?.map(
              (image: any) => ({
                imageName: image?.imagem_name,
                imagePath: image?.imagem_path,
                fileName: image?.file_name,
                required: image?.obrigatorio,
                obrigatorio: image?.obrigatorio,
                location: image?.localizacao,
                dateHour: image?.data_hora,
                dateScheduled: image?.data_agendamento,
                order: image?.ordem,
              })
            ),
            imageTires: vehicleInfo?.imagem_pneus?.map((image: any) => ({
              imageName: image?.imagem_name,
              imagePath: image?.imagem_path,
              fileName: image?.file_name,
              required: image?.obrigatorio,
              obrigatorio: image?.obrigatorio,
              location: image?.localizacao,
              dateHour: image?.data_hora,
              dateScheduled: image?.data_agendamento,
              order: image?.ordem,
            })),
            imageVehicle: vehicleInfo?.imagem_veiculo?.map(
              (image: any) => ({
                imageName: image?.imagem_name,
                imagePath: image?.imagem_path,
                fileName: image?.file_name,
                required: image?.obrigatorio,
                obrigatorio: image?.obrigatorio,
                location: image?.localizacao,
                dateHour: image?.data_hora,
                dateScheduled: image?.data_agendamento,
                order: image?.ordem,
              })
            ),
          }));
          const idDriver = vehicleInfo?.motorista_responsavel?._id;
          setSelectList({
            ...selectList,
            listVehicleType: selectList.listVehicleType
          });

          const response = api.get(`motoristas/${idDriver}`);
          response
            .then((response) => {
              setVehicleData((prevState) => ({
                ...prevState,
                responsibleDriver: {
                  ...prevState.responsibleDriver,
                  name: "js",
                },
              }));
            })
            .catch((error) => console.log(error));

          setLoading(false);
        })

      )
      .catch((errors) => {
        console.log(errors);
        setLoading(false);
      });
  }, []);

  const addVehicle = async (vehicleDataForm: Vehicle) => {
    setLoading(true);
    try {
      // console.log(vehicleDataForm.plate.toUpperCase().split("-").join(""));
      await api.post("veiculos", {
        nome: vehicleDataForm.name,
        renavam: vehicleDataForm.renavam,
        chassis: vehicleDataForm.chassi,
        placa: vehicleDataForm.plate.toUpperCase().split("-").join(""),
        cliente: vehicleDataForm.client.id,
        cor: vehicleDataForm.color,
        frequencia_atualizacao_cadastral: vehicleDataForm.updateRegisterFrequency,
        status: vehicleDataForm.status === "Ativo",
        veiculo_tipo: vehicleDataForm.vehicleType,
        motorista_responsavel: vehicleDataForm.responsibleDriver.id,
        imagem_documentos: vehicleData.imageDocuments,
        imagem_pneus: vehicleData.imageTires,
        imagem_veiculo: vehicleData.imageVehicle,
        cidade: vehicleDataForm?.city,
        estado_uf: vehicleDataForm?.state_UF,
        proprietario: {
          nome: vehicleDataForm.owner.name,
          cpf_cnpj: vehicleDataForm.owner.cpfCnpj,
          rg: vehicleDataForm.owner.rg,
          data_nascimento: vehicleDataForm.owner.birthDate,
        },
      });
      navigate("/vehicles");
      successMessage("Veículo adicionado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível adicionar veículo");
      setLoading(false);
    }
  };

  const updateVehicle = async (vehicleDataForm: Vehicle) => {
    setLoading(true);
    try {
      await api.patch(`veiculos/${id}`, {
        nome: vehicleDataForm.name,
        renavam: vehicleDataForm.renavam,
        chassis: vehicleDataForm.chassi,
        placa: vehicleDataForm.plate.toUpperCase(),
        cliente: vehicleDataForm.client?.id || "",
        motorista_responsavel: vehicleDataForm.responsibleDriver.id,
        cor: vehicleDataForm.color,
        frequencia_atualizacao_cadastral: vehicleDataForm.updateRegisterFrequency,
        status: vehicleDataForm.status === "Ativo",
        cidade: vehicleDataForm?.city,
        estado_uf: vehicleDataForm?.state_UF,
        veiculo_tipo: vehicleDataForm.vehicleType,
        proprietario: {
          nome: vehicleDataForm.owner.name,
          cpf_cnpj: vehicleDataForm.owner.cpfCnpj,
          rg: vehicleDataForm.owner.rg,
          data_nascimento: vehicleDataForm.owner.birthDate,
        },
        imagem_documentos: vehicleDataForm.imageDocuments.map((image, index) => ({
          imagem_name: image.imageName,
          file_name: image.fileName,
          ordem: image.order,
           imagem_path: image?.imagePath,
          obrigatorio: (vehicleData as any).imageDocuments[index].obrigatorio,
        })),
        imagem_pneus: vehicleDataForm.imageTires.map((image, index) => ({
          imagem_name: image.imageName,
          file_name: image.fileName,
          ordem: image.order,
          imagem_path: image?.imagePath,
          obrigatorio: (vehicleData as any).imageTires[index].obrigatorio,
        })),
        imagem_veiculo: vehicleDataForm.imageVehicle.map((image, index) => ({
          imagem_name: image.imageName,
          file_name: image.fileName,
          ordem: image.order,
           imagem_path: image?.imagePath,
          obrigatorio: (vehicleData as any).imageVehicle[index].obrigatorio,
        })),
      });
      navigate("/vehicles");
      successMessage("Veículo atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar veículo");
      setLoading(false);
    }
  };

  const deleteVehicle = async (id: string) => {
    setLoading(true);
    try {
      await api.delete(`veiculos/${id}`);
      getAllVehicles();
      successMessage("Veículo excluído com sucesso!");
      setRefresh(prev => !prev);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [listVehicles, setListVehicles] = useState<VehicleData[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllVehicles(value);
  };

  interface GetAllVehiclesList {
    plate?: string;
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number;
  }

  const getAllVehiclesList = async <T>({plate, name, paginationIndex, rowsPerPage=10} : GetAllVehiclesList) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)

    const plateToQuery = `${plate ? `&placa=${plate}` : ''}`;
    const nameToQuery = `${name ? `&name=${name}` : ''}`;
    const pageLimit =  `${rowsPerPage ? `&limit=${rowsPerPage}` : ''}`;

    try {
      const response = await api.get(`veiculos?skip=${paginationIndex}${plateToQuery}${nameToQuery}${pageLimit}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          { 
            id: result?._id,
            name: result?.nome,
            createdAt: result?.createdAt ? new Date(result?.createdAt)?.toLocaleDateString() : "",
            type: result?.veiculo_tipo?.nome,
            renavam: result?.renavam,
            chassis: result?.chassis,
            color: result?.cor,
            plate: result?.placa,
            status: result?.status,
            responsibleDriver: result?.motorista_responsavel?._id,
            ownerName: result?.proprietario?.nome,
            vehicleType: {
              id: result?.veiculo_tipo?._id,
              name: result?.veiculo_tipo?.nome

            }
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],  
      totalItems,
    };
  }

  const getAllVehicles = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `veiculos?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListVehicles(
        data.data.data.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            createdAt: new Date(result.createdAt).toLocaleDateString(),
            type: result.veiculo_tipo_id.nome,
            renavam: result.renavam,
            chassis: result.chassis,
            plate: result.placa,
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getAllVehicleType = async () => {
    try {
      const { data } = await api.get("veiculo-tipos");

      const formatedList = data?.data?.data?.map((vehicleType: any) => {

        let imageDocuments =  (!vehicleType?.imagem_documentos || !vehicleType?.imagem_documentos?.length) ? 
          IMAGENS_DOCUMENTOS : 
          vehicleType?.imagem_documentos
      
        let imageVehicle =  (!vehicleType?.imagem_veiculo || !vehicleType?.imagem_veiculo?.length) ? 
          IMAGENS_DOCUMENTOS : 
          vehicleType?.imagem_veiculo
          
        let imageTires =  (!vehicleType?.imagem_pneus || !vehicleType?.imagem_pneus?.length) ? 
          IMAGENS_DOCUMENTOS : 
          vehicleType?.imagem_pneus      

        return {
          id: vehicleType?._id,
          name: vehicleType?.nome,
          imageDocuments,
          imageVehicle,
          imageTires,
        };
      });

      setListOfVeVehicleType(formatedList)
      setSelectList({
        ...selectList,
        listVehicleType: formatedList,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    vehicleData,
    setVehicleData,
    listVehicles,
    getVehicle,
    addVehicle,
    updateVehicle,
    deleteVehicle,
    getAllVehicles,
    selectList,
    getAllVehicleType,
    loadingData,
    getAllVehiclesList,
    refresh,
    updateImages,
    listOfVeVehicleType
  };
}
