import { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons/";

import CircularProgress from '@material-ui/core/CircularProgress';


import { Formik, Form as FormikForm } from "formik";

import { useStyles } from "./styles";

import { Loading } from "../../../../components/Loading";
import { InputMask } from "../../../../components/InputMask";
import { inCreationOrEditing, onlyNumbers } from "../../../../utils";
import { useUsers } from "../../../../hooks/Users/useUsers";
import { useClients } from "../../../../hooks/Users/useClients";
import { api } from "../../../../services/api";
import { errorMessage, successMessage, warningMessage } from "../../../../components/Messages";
import { asyncCheckCPF } from "../services/checkCPF";
import { asyncCheckRG } from "../services/checkRG";
import { LOCAL_STORAGE_KEY } from "../../../../consts";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const UserForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const {
    loading,
    setLoading,
    userData,
    getUser,
    addUser,
    updateUser,
    selectList,
    getAllProfileType,
  } = useUsers();
  const { listClients, getAllClients } = useClients();
  const [cpfExists, setCpfExists] = useState(false);
  const [rgExists, setRgExists] = useState(false);
  const [loadingCPFOrRG, setLoadingCPFOrRG] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false)
  const [myself, setMyself] = useState("");
  const [loadingUpadatePassword, setLoadingUpadatePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPasswords, setShowPasswords] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar Usuário";
    } else if (location.pathname.includes("edit")) {
      return "Editar Usuário";
    } else {
      return "Usuário";
    }
  };

  const listClientsFiltered = listClients.map((client) => ({
    id: client.id,
    name: client.name,
  }));

  const handleUpadePassword = async () => {
    if(!newPassword || !confirmPassword) {
      return warningMessage("É necessário prencher as duas senhas para atualizar!");
    }
    if(newPassword !== confirmPassword) {
      return warningMessage("As senhas devem ser iguais");
    }

    setLoadingUpadatePassword(true);

    try {
      await api.patch(`users/update-password/${userData.id}`, {
        password: newPassword
      });
      successMessage("Senha Atualizada com sucesso!");
    } catch (error) {
      errorMessage("Não foi possível atualizar a senha, por favor, tente novamente mais tarde!");
    } finally{
      setLoadingUpadatePassword(false)
    }

  }

  const handleResetPasswd = async () => {
    setLoadingResetPassword(true);
    try {
      await api.patch(`users/default-password/${userData.id}`);
      successMessage("Senha resetada com sucesso!");
    } catch (error) {
      errorMessage("Não foi possível resetar a senha!");
    } finally{
      setLoadingResetPassword(false)
    }
  };

  useEffect(() => {
    let getUserId = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ID);

    if(getUserId) {
      setMyself(JSON.parse(getUserId || ""));
    }
  }, [])

  useEffect(() => {
    getAllProfileType();
    getAllClients();
    if (id) {
      getUser(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  return (
    <>
    <Formik
      enableReinitialize
      initialValues={userData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addUser(values);
        } else {
          updateUser(values);
        }
      }}
    >
      {({ handleChange, setFieldValue, values }) => (
        <FormikForm>
          <Grid container>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>Usuários</Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/users")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button type="submit" className={classes.buttonSave} disabled={loading || cpfExists || rgExists || loadingCPFOrRG} >
                      {loading ? <Loading/> : "Salvar"}
                    </Button>
                    {/* <Button
                      className={classes.buttonDiscard}
                      onClick={() => alert("Em Implementação!")}
                    >
                      Descartar
                    </Button> */}
                  </div>
                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    {/* <Button
                      className={classes.buttonExport}
                      onClick={() => alert("Em Implementação!")}
                    >
                      Exportar
                    </Button> */}
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/users/${id}/edit`)}
                    >
                      Editar
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
              {/* {loading ? (
                <Loading />
              ) : ( */}
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados
                      </Typography>
                    </Grid>

                    {!location.pathname.includes("new") ? (
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          ID
                        </InputLabel>
                        <TextField
                          name="id"
                          value={values.id}
                          placeholder="0000"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Nome*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        CPF*
                      </InputLabel>
                      <TextField
                        name="cpf"
                        value={values.cpf}
                        className={cpfExists ? classes.inputError : ""}
                        onChange={e => {
                          handleChange(e);
                          if(onlyNumbers(e.target.value).length !== 11){
                            return
                          }

                          setTimeout(async() => {
                            const isItTrue = await asyncCheckCPF(e.target.value, setLoadingCPFOrRG);
                            setCpfExists(isItTrue)
                          }, 1000)
                        }}
                        placeholder="CPF*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                        InputProps={{
                          inputComponent: InputMask as any,
                        }}
                      />
                      {cpfExists &&
                        <InputLabel className={classes.inputLabelError}>
                        Já existe um usuário com este CPF
                        </InputLabel>
                      }
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        RG*
                      </InputLabel>
                      <TextField
                        name="document"
                        value={values.document}
                        className={rgExists ? classes.inputError : ""}
                        onChange={e => {
                          handleChange(e);
                          if(onlyNumbers(e.target.value).length !== 9){
                            return
                          }

                          setTimeout(async() => {
                            const isItTrue = await asyncCheckRG(e.target.value, setLoadingCPFOrRG);
                            setRgExists(isItTrue)
                          }, 1000)
                        }}
                        placeholder="RG*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                        {rgExists &&
                          <InputLabel className={classes.inputLabelError}>
                            Já existe um cliente com este RG
                          </InputLabel>
                        }
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Tipo de Perfil*
                      </InputLabel>
                      <TextField
                        name="profileType"
                        value={values.profileType}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!inCreationOrEditing(location)}
                      >
                        <MenuItem value="Selecionar" disabled>
                          Selecionar
                        </MenuItem>
                        {selectList.listProfileType.map((profile) => (
                          <MenuItem key={profile.id} value={profile.id}>
                            {profile.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {/* <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Validade de acesso*
                      </InputLabel>
                      <TextField
                        name="expirationAccess"
                        value={values.expirationAccess}
                        onChange={handleChange}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid> */}

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Status*    
                      </InputLabel>
                      <TextField
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!inCreationOrEditing(location)}
                      >
                        {selectList.status.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {!location.pathname.includes("new") ? (
                      <>
                      <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel} style={{color: "#fff"}}>
                        Resetar
                      </InputLabel>
                        <Button
                          className={classes.buttonResetPasswd}
                          onClick={handleResetPasswd}
                          >
                          {loadingResetPassword ? <Loading/> : "Resetar Senha"}
                        </Button>
                      </Grid>
                    </>
                    ) : null}

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Empresas
                      </Typography>
                    </Grid>
                    <Grid item lg={6}>
                      <Autocomplete
                        multiple
                        id="checkboxes-company"
                        options={listClientsFiltered}
                        disableCloseOnSelect
                        size="small"
                        value={values.clients}
                        onChange={(_, value) => {
                          setFieldValue("clients", value);
                        }}
                        disabled={!inCreationOrEditing(location)}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(company) => company.name}
                        renderOption={(company, { selected }) => (
                          <>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {company.name}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Empresas"
                            placeholder="Selecionar Empresas"
                          />
                        )}
                      />
                    </Grid>

                    {/* <img
                      style={{ width: 100, height: 100 }}
                      src="https://fortallog.s3.us-east-2.amazonaws.com/os-etapa-imagem-IMG01-16b790c4671a6d.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQFL7N6C3D3WNYTYS%2F20221221%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20221221T143200Z&X-Amz-Expires=900&X-Amz-Signature=62ff74f3337102027bce9e24f392374f75f33d3f137342374c7d751ab843f3d4&X-Amz-SignedHeaders=host"
                      alt=""
                    /> */}

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Contato
                      </Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        E-mail
                      </InputLabel>
                      <TextField
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        placeholder="E-mail"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Telefone*
                      </InputLabel>
                      <TextField
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                        InputProps={{
                          inputComponent: InputMask as any,
                        }}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Telefone 2
                      </InputLabel>
                      <TextField
                        name="phoneSecondary"
                        value={values.phoneSecondary}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                        InputProps={{
                          inputComponent: InputMask as any,
                        }}
                      />
                    </Grid>

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Atualização de Senha
                      </Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Frequência de redefinição da senha
                      </InputLabel>
                      <TextField
                        name="passwordResetFrequency"
                        value={values.passwordResetFrequency}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        <MenuItem value="Selecionar" disabled>
                          Selecionar
                        </MenuItem>
                        {selectList.listPasswordResetFrequency.map(
                          (frequency) => (
                            <MenuItem key={frequency.id} value={frequency.id}>
                              {frequency.value}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>                   
                  </Grid>
                </Paper>
              {/* )} */}
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>

    {
      (location?.pathname?.includes("edit") && id?.toString() === myself?.toString()) && (
        <Paper style={{padding: 10, marginTop: 40}}>
        <Grid item lg={12}>
          <Typography className={classes.formSectionTitle}>
            Atualizar minha Senha
          </Typography>
        </Grid>
  
        <div style={{display: "flex", marginTop: 10}}>
  
          <Grid item lg={3} style={{marginRight: 10}}>
            <InputLabel className={classes.inputLabel}>
              Nova senha
            </InputLabel>
            <TextField
              name="senha"
              //value={values.email}
              onChange={e => setNewPassword(e.target.value)}
              placeholder="********"
              variant="outlined"
              size="small"
              fullWidth
              type={showPasswords.newPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPasswords((prevState) => ({
                          ...prevState,
                          newPassword: !prevState.newPassword,
                        }))
                      }
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswords.newPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={3} style={{marginRight: 10}}>
            <InputLabel className={classes.inputLabel}>
              Confirmar senha
            </InputLabel>
            <TextField
              name="confirm-senha"
              //value={values.phone}
              onChange={e => setConfirmPassword(e.target.value)}
              placeholder="********"
              variant="outlined"
              size="small"
              fullWidth
              type={showPasswords.confirmNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPasswords((prevState) => ({
                          ...prevState,
                          confirmNewPassword: !prevState.confirmNewPassword,
                        }))
                      }
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswords.confirmNewPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <InputLabel className={classes.inputLabel} style={{color: "#fff"}}>
              Resetar
            </InputLabel>
            <Button
              disabled={loading}
              className={classes.buttonResetPasswd}
              onClick={() => handleUpadePassword()}
              style={{backgroundColor: "#2EA949"}}
              >
              {loadingUpadatePassword ? <Loading/> : "Atualizar senha"}
            </Button>
          {/* </Grid> */}
        
        </Grid>
        </div>
      </Paper>
      )
    }
  </>
  );
};
