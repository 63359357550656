import {FC, useState} from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
//import { ButtonProps } from '@mui/material/Button';
import { Button, ButtonProps } from "@material-ui/core";

import { useStyles } from "./styles";

interface CustomPopoverProps extends  ButtonProps{
  data?: {value: any; label: string}[],
  onChangeValue?: (value: any) => void;
  placeholder?: string;
}

const CustomPopover: FC<CustomPopoverProps> = ({data, onChangeValue, placeholder, ...rest}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeValue = (value: any) => {
    onChangeValue && onChangeValue(value);
    handleClose();
  }

  return (
    <div>
      <Button
        aria-describedby={id} 
        onClick={handleClick}
        {...rest} 
        className={classes.button}
      >
        {placeholder || ""}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {data?.map(item => (
          <Typography
            className={classes.popoverItem} 
            sx={{ p: 2 }} 
            key={item.value}
            onClick={() => handleChangeValue(item.value)}
          >
            {item.label}
          </Typography>
        ))}
      </Popover>
    </div>
  );
}

export default CustomPopover