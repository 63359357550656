import { api } from "../../../services/api"

export const askToGenerateReport = async (endpoint: string, columns: {header: string, key: string}[], filter?: object) => {
  let resp = {
    status: 0,
    code: ""
  }

  const body = filter ? {
    columns,
    filters: { listdto: filter }
  } : { columns, filters: {} }

  try {
    const { status, data } = await api.post(`export/${endpoint}`, body)

    if(status === 201) {
      resp.status = status;
      resp.code = data?.job_id as any
    }

  } catch (error) {
    console.log("Deu erro ao tentar pedir isso:", error)
  }

  return resp
}