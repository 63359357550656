import {FC, useState} from 'react'

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons/";

import {useStyles} from './styles'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'

type InputPasswordProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: any
}

const InputPassword: FC<InputPasswordProps> = ({ onChange, value}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      name="confirm-senha"
      value={value}
      onChange={onChange}
      placeholder="********"
      variant="outlined"
      size="small"
      fullWidth
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>
                setShowPassword((prevState) => !prevState)
              }
              //onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default InputPassword