import { FC, useEffect, useState } from "react"
import {
  Grid,
  Typography,
  Paper
} from "@material-ui/core";

import { useParams } from "react-router-dom"

import { useStyles } from "./styles";
import GenericTable from "../../../../../components/GenericTable";
import { useServiceOrder } from "../../../../../hooks/useServiceOrder";
import { printLocationInfo } from "../../../../../utils";
import GenericExport from "../../../../../components/GenericExport";

const header = [
  {key: "startDate", label: "Data/Hora inicial"},
  {key: "endDate", label: "Data/Hora de término"},
  {key: "description", label: "Justificativa"},
  {key: "duration", label: "Duração"},
  {key: "location", label: "Localização"},
  {key: 'status', label: 'Status'},
]

const tableHeaderToExport = [
  {key: "start", label: "Data/Hora inicial"},
  {key: "end", label: "Data/Hora de término"},
  {key: "causa", label: "Justificativa"},
  {key: "duration", label: "Duração"},
  {key: "localizacao", label: "Localização"},
  {key: 'isActive', label: 'Status'},
]

const IntervalTab: FC = () => {
  const [index, setIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<any>({});
  const [loadingData, setLoadingData] = useState(true)
  const [total, setTotal] = useState(0);
  const classes = useStyles();

  const { id } = useParams();

  const filterToExport = {
    os_id: id,
  }

  const { getAllIntervals } = useServiceOrder()

  const getIntervals = async () => {
    const {data: respose, loading, total: totalItems} = await getAllIntervals({
      paginationIndex: index,
      rowsPerPage,
      id: id  || ""
    })

    setData(respose.map((item: any) => ({
      ...item,
      location: printLocationInfo(item?.location),
      status: (
        <Typography
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            width: 200,
            padding: "4px 0px",
            borderRadius: "4px",

            fontSize: 12,
            color: "#252525",
            opacity: "0.8",
            backgroundColor: !!item?.status
              ? "#FED278"
              : "#6FED8B",
          }}
        >
          {(item?.status as Boolean) ? "Em andamento" : "Finalizado"}
        </Typography> 
        )
    })))
    setLoadingData(loading)
    setTotal(totalItems)
  }

  useEffect(() => {
    if(id) {
      getIntervals()
    }
  }, [index, rowsPerPage, id])

  return(
    <Grid container spacing={1} className={classes.formContainer}>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 4, marginBottom: 10, marginRight: 4 }}>
        <GenericExport
          headerToRows={tableHeaderToExport}
          endpoint="os-intervalos"
          title="Exportar lista das Ocorrências da OS"  
          filter={filterToExport}
        />
      </div>
      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <GenericTable
            data={data || []}
            isLoading={loadingData}
            header={header}
            total={total}
            onChangeIndex={(index) =>  setIndex(index)}
            onChangeRowsPerPage={(value) => setRowsPerPage(value)}
            noContentText="Sem intervalos para mostrar"
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default IntervalTab