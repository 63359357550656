import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import {
  Add as AddIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { Loading } from "../../../../components/Loading";
import { formatListToShowInSelectComponent, inCreationOrEditing } from "../../../../utils";
import { useStyles } from "./styles";
import { useTypeSchedule } from "../../../../hooks/useTypeSchedule";
import { useTypeModal } from "../../../../hooks/useTypeModal";
import { useTypeLoad } from "../../../../hooks/useTypeLoad";

export const TypeScheduleForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();

  const [modals, setModals] = useState([])
  const [leads, serLeads] = useState([])

  const {
    loading,
    setLoading,
    typeScheduleData,
    setTypeScheduleData,
    getTypeSchedule,
    addTypeSchedule,
    updateTypeSchedule,
    getAll,
    selectList,
  } = useTypeSchedule();

  const {
    getAllTypeOfModal,
   // loadingData
  } = useTypeModal();

  const {
    getAllTypeOfLoad,
    //loadingData
  } = useTypeLoad();

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar tipo de programação";
    } else if (location.pathname.includes("edit")) {
      return "Editar tipo de programação";
    } else {
      return "Tipo de programação";
    }
  };

  const getModalsAndLeads = async () => {
    const {data} = await getAllTypeOfModal({name: "", paginationIndex: 0})
    const {data: leadList} = await getAllTypeOfLoad({name: "", paginationIndex: 0})

    const formatedModals = formatListToShowInSelectComponent({
      list: data,
      keyOfTheValueToPutOnLabel: "name",
      keyOfTheValueToPutOnValue: "id"
    })

    const formatedLead = formatListToShowInSelectComponent({
      list: leadList,
      keyOfTheValueToPutOnLabel: "name",
      keyOfTheValueToPutOnValue: "id"
    })

    serLeads(formatedLead as any)
    setModals(formatedModals as any)
  }

  useEffect(() => {
    getModalsAndLeads()
  }, [])

  useEffect(() => {
    getAll();
    if (id) {
      getTypeSchedule(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  return (
    <Formik
      enableReinitialize
      initialValues={typeScheduleData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addTypeSchedule(values);
        } else {
          updateTypeSchedule(values);
        }
      }}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>
                Tipos de programação
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/schedule-type")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button className={classes.buttonSave} type="submit">
                      {loading ? <Loading/> : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/schedule-type")}
                    >
                      Descartar
                    </Button>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/schedule-type/${id}/edit`)}
                    >
                      {loading ? <Loading/> : "Editar"}
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados do tipo de programação
                      </Typography>
                    </Grid>

                    {!location.pathname.includes("new") ? (
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          ID
                        </InputLabel>
                        <TextField
                          name="id"
                          value={values.id}
                          placeholder="0000"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Nome da programação*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Via
                      </InputLabel>
                      <TextField
                        name="via"
                        value={values.via}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {selectList.via.map((via) => (
                          <MenuItem key={via} value={via}>
                            {via}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Modal
                      </InputLabel>
                      <TextField
                        name="modalId"
                        value={values.modalId}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        <MenuItem value="Selecionar" disabled>
                          Selecionar
                        </MenuItem>
                        {modals.map((modal: {label: string, value: string}) => (
                          <MenuItem key={modal.value} value={modal.value}>
                            {modal.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Tipo de carga
                      </InputLabel>
                      <TextField
                        name="loadId"
                        value={values.loadId}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        <MenuItem value="Selecionar" disabled>
                          Selecionar
                        </MenuItem>
                        {leads.map((modal: {label: string, value: string}) => (
                          <MenuItem key={modal.value} value={modal.value}>
                            {modal.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Situação
                      </InputLabel>
                      <TextField
                        name="situation"
                        value={values.situation}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {selectList.situation.map((situation) => (
                          <MenuItem key={situation} value={situation}>
                            {situation}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Status
                      </InputLabel>
                      <TextField
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {selectList.status.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados da rota
                      </Typography>
                    </Grid>
                    {values.routes.length > 0
                      ? values.routes.map((_, index) => (
                          <Grid
                            container
                            className={classes.routeTitleContainer}
                            key={`route-${index}`}
                          >
                            <Grid item lg={11}>
                              <InputLabel className={classes.inputLabel}>
                                Rota {index + 1}
                              </InputLabel>
                              <TextField
                                name="name"
                                value={values.routes[index].name}
                                onChange={handleChange(`routes[${index}].name`)}
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={!inCreationOrEditing(location)}
                              />
                            </Grid>
                            <Grid item lg={1}>
                              <IconButton
                                className={classes.buttonDelete}
                                onClick={() => {
                                  const filteredRoutes = values.routes.filter(
                                    (_, indexFilter) => indexFilter !== index
                                  );
                                  setTypeScheduleData({
                                    ...values,
                                    routes: filteredRoutes,
                                  });
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))
                      : null}
                    <Grid item lg={12}>
                      <Button
                        startIcon={<AddIcon />}
                        className={classes.buttonCreateRoute}
                        onClick={() =>
                          setTypeScheduleData({
                            ...values,
                            routes: [
                              ...values.routes,
                              {
                                name: "",
                                order: values.routes.length + 1,
                              },
                            ],
                          })
                        }
                        disabled={!inCreationOrEditing(location)}
                      >
                        Cadastrar Rota
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
