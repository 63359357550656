import { Fragment } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../styles";

type ListVehiclesProps = {
  isAdmin?: boolean,
  vehicles: {
    id: string;
    name: string;
    renavam: string;
    chassi: string;
    plate: string;
    client: string;
    color: string;
    createdAt: string;
    status: boolean;
    ownerName: string;
    vehicleType: {
      id: string;
      name: string;
    };
  }[];
};

export const ListVehicles = ({ vehicles, isAdmin }: ListVehiclesProps) => {
  const classes = useStyles();

  return (
    <>
      {vehicles.map((vehicle, index) => {
        if(!isAdmin) {
          return (
             <Fragment key={`vehicle-${index}`}>
              <Grid item style={{width: "100%"}}>
                <Typography className={classes.overviewSectionSubtitle}>
                  Veículo {`${1 + index}`}
                </Typography>
              </Grid>
              <Grid item style={{minWidth: 140}}>
                <Typography className={classes.overviewFieldTitle}>Nome</Typography>
                <Typography>{vehicle.name}</Typography>
              </Grid>
              <Grid item style={{minWidth: 140}}>
                <Typography className={classes.overviewFieldTitle}>
                  Placa
                </Typography>
                <Typography>{vehicle.plate}</Typography>
              </Grid>              
             </Fragment>
          )
        }
        return (
          <Fragment key={`vehicle-${index}`}>
            <Grid item style={{width: "100%"}}>
              <Typography className={classes.overviewSectionSubtitle}>
                Veículo {`${1 + index}`}
              </Typography>
            </Grid>
            <Grid item style={{minWidth: 140}}>
              <Typography className={classes.overviewFieldTitle}>Nome</Typography>
              <Typography>{vehicle.name}</Typography>
            </Grid>
            <Grid item style={{minWidth: 140,}}>
              <Typography className={classes.overviewFieldTitle}>Tipo</Typography>
              <Typography>{vehicle.vehicleType.name}</Typography>
            </Grid>
            <Grid item style={{minWidth: 140}}>
              <Typography className={classes.overviewFieldTitle}>
                Renavam
              </Typography>
              <Typography>{vehicle.renavam}</Typography>
            </Grid>
            <Grid item style={{minWidth: 140}}>
              <Typography className={classes.overviewFieldTitle}>
                Chassi
              </Typography>
              <Typography>{vehicle.chassi}</Typography>
            </Grid>
            <Grid item style={{minWidth: 140}}>
              <Typography className={classes.overviewFieldTitle}>
                Placa
              </Typography>
              <Typography>{vehicle.plate}</Typography>
            </Grid>
            <Grid item style={{minWidth: 140}}>
              <Typography className={classes.overviewFieldTitle}>
                Empresa
              </Typography>
              <Typography>{vehicle.client}</Typography>
            </Grid>
            <Grid item style={{minWidth: 140}}>
              <Typography className={classes.overviewFieldTitle}>
                Proprietário
              </Typography>
              <Typography>{vehicle.ownerName}</Typography>
            </Grid>
          </Fragment>
        )
      })}
      <Grid item lg={12}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>
    </>
  );
};
