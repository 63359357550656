import { FC } from "react"
import {
  Add as AddIcon,
  Delete,
} from "@material-ui/icons/";
import {
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper
} from "@material-ui/core";

import { CustomSwitch, useStyles } from "./styles";
import CustomPopover from "../../../../../components/CustomPopover";
import { ImageModel } from "../../../../../types/managementUserTypes";

export const chooseTitleAndLabel = (isVideo?: boolean, isDocument?: boolean) => {
  if(isVideo) {
    return {
      title: 'Título do Vídeo',
      label: 'Vídeo'
    }
  }
  if(isDocument) {
    return {
      title: 'Título do Documento',
      label: 'Documento'
    }
  }  

  return {
    title: 'Título da Imagem',
    label: 'Imagem'
  }
}

const files: ImageModel[] | any = [
  {
    imageName: 'Imagem do Doc',
    imagePath: '',
    required: true
  },
  {
    imageName: 'Video do Doc',
    imagePath: '',
    isVideo: true,
    required: false
  },
  {
    imageName: 'PDF do Doc',
    imagePath: '',
    isDocument: true,
    required: true
  },
]

const FilesTab: FC<{ newOrEdit: boolean, files: ImageModel[] | any }> = ({ newOrEdit, files: f }) => {
  const classes = useStyles();

  if(!newOrEdit) {
    return (
      <Grid container spacing={1} className={classes.formContainer}>
        <Grid item lg={12}>
           <Paper className={classes.paper}>
            <Grid item style={{width: "100%"}}>
              <Typography className={classes.overviewSectionTitle}>
                Arquivos
              </Typography>
            </Grid>
           </Paper>
        </Grid>
      </Grid>
    )
  }

  return(
    <Grid item className={classes.formContainer}>
      <Typography className={classes.overviewSectionTitle}>
        Arquivos
      </Typography>

        <Grid
          item
          lg={2}
          style={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
            <InputLabel className={classes.inputLabelStep} style={{ color: "transparent" }}>
            Selecionar
          </InputLabel>
          <CustomPopover 
            startIcon={<AddIcon />}
            data={[
              {label: "Imagem", value: "image"},
              {label: "Video", value: "isVideo"},
              {label: "Documento", value: "isDocument"}
            ]}
            placeholder="Arquivo"
            onChangeValue={(value) => {
              //addImage(values, step, index, value === "isVideo")
            }}
          />
        </Grid>
        {files?.map((file: ImageModel, index: number) => {
          return (
            <Grid
              item
              lg={2}
              style={{
                marginTop: 4,
              }}
              key={`file-${index + 1}`}
            >
              
              <Grid 
                item 
                lg={12} 
                style={{
                  display: "flex",
                  alignItems: "center", 
                  justifyContent: "space-between", 
                  //height: 50, 
                }}
              >
                <InputLabel className={classes.inputLabelStep}>
                  {`${index + 1} - ${chooseTitleAndLabel(file?.isVideo, file?.isDocument).label}`}
                </InputLabel>

                <button
                  type="button"
                  onClick={() => {
                    //handleDelete(index)
                    //removeImage(values, step, index, indexImage)
                  }}
                  style={{
                    width: "auto",
                    height: "auto",
                    backgroundColor: "transparent",
                    padding: 0,
                    border: "none",
                    cursor: "pointer",
                    //display: inCreationOrEditing(location) ? "block" : "none"
                  }}
                >
                  <Delete htmlColor="#2F4D7C" style={{margin: 0}}/>
                </button>
              </Grid>
              <TextField
                name="imageName"
                value={file.imageName}
                // onChange={handleChange(
                //   `steps[${index}].images[${indexImage}].imageName`
                // )}
                placeholder={chooseTitleAndLabel(file?.isVideo, file?.isDocument).title}
                variant="outlined"
                size="small"
                fullWidth
                required
                //disabled={!inCreationOrEditing(location)}
              />
              <div className={classes.switchContainer}>
                <CustomSwitch
                  name="status"
                  //disabled={!inCreationOrEditing(location)}
                  checked={file?.required
                  }
                  // onChange={handleChange(
                  //   `steps[${index}].images[${indexImage}].required`
                  // )}
                />
                <Typography className={classes.switchText}>
                  Obrigatório
                </Typography>
              </div>
            </Grid>
          );
        })}
    </Grid>   
  )
}

export default FilesTab