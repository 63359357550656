import { api } from "../../../../services/api"

export const readNotification = async (
  notificationID: string, 
  handleLoanding: (isLoading: boolean) => void) => {
  
  handleLoanding(true)

  try {
    await api.post(`notificacoes/${notificationID}/readAdmin`);
  } catch (error) {
    console.log(error)
  } finally {
    handleLoanding(false)
  }
}

export const readAllNotification = async ( handleLoanding: (isLoading: boolean) => void) => {
  handleLoanding(true);

  try {
    await api.post(`notificacoes/readAll`);
  } catch (error) {
    console.log(error)
  } finally {
    handleLoanding(false)
  }
}