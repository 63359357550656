import { Button, Typography, Dialog, DialogContent } from "@material-ui/core";
import { Location, useLocation } from "react-router-dom";
import { useStyles } from "./styles";

type ModalDeleteProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  deleteFunction: () => void;
};

export const ModalDelete = ({
  isModalOpen,
  closeModal,
  deleteFunction,
}: ModalDeleteProps) => {
  const classes = useStyles();
  const location = useLocation();

  const returnedPhrase = (location: Location) => {
    switch (location.pathname) {
      case "/vehicle-type":
        return "tipo de veículo";
      case "/load-type":
        return "tipo de carga";
      case "/modal-type":
        return "tipo de modal";
      case "/schedule-type":
        return "tipo de programação";
      case "/status":
        return "tipo de status";
      case "/occurrences":
        return "tipo de ocorrência";
      case "/checklist":
        return "checklist";
      case "/users":
        return "usuário";
      case "/clients":
        return "cliente";
      case "/vehicles":
        return "veículo";
      case "/drivers":
        return "motorista";
      default:
        return "ordem de serviço";
    }
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogContent style={{ marginLeft: 12, marginRight: 12 }}>
        <div>
          <Typography style={{ fontSize: 24, textAlign: "center" }}>
            Deseja realmente <strong>excluir</strong>
            <br /> esta {returnedPhrase(location)}?
          </Typography>
        </div>
      </DialogContent>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 12,
          marginRight: 12,
          paddingBottom: 24,
        }}
      >
        <Button className={classes.buttonBack} onClick={closeModal}>
          Voltar
        </Button>
        <Button
          className={classes.buttonDelete}
          variant="text"
          onClick={() => {
            deleteFunction();
            closeModal();
          }}
        >
          Excluir
        </Button>
      </DialogContent>
    </Dialog>
  );
};
