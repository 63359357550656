import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  errorMessage,
  successMessage,
  warningMessage,
} from "../components/Messages";
import { api } from "../services/api";
import { TypeSchedule } from "../types/managementServiceOrderTypes";
import axios from "axios";

type Schedule = {
  id: string;
  name: string;
  via: string;
  modal: string;
  typeLoad: string;
  situation: string;
  status: boolean;
  routes: {
    id: string;
    name: string;
    sender: string;
    order: number;
    zipCode: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    number: string;
    complement: string;
    reference: string;
    collectDate: string;
  }[];
};

const SKIPLIMIT = 10;

export function useTypeSchedule() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [typeScheduleData, setTypeScheduleData] = useState<TypeSchedule>({
    id: "",
    name: "",
    via: "Porto",
    modalId: "Selecionar",
    loadId: "Selecionar",
    situation: "Vazio",
    status: "Ativo",
    routes: [],
  });

  const [selectList, setSelectList] = useState({
    modal: [{ id: "df", name: "df" }],
    typeLoad: [{ id: "", name: "" }],
    via: ["Porto", "Aeroporto", "Outros"],
    status: ["Ativo", "Inativo"],
    situation: ["Vazio", "Cheio"],
  });

  const formatRoutes = (routes: any[]) => {
    if(!routes || routes.length === 0) {
      return []
    }

    return routes
    .map((rota: any) => {
      return {
        name: rota.nome,
        order: rota.ordem,
      };
    })
    .sort((route: any, nextRoute: any) => {
      return route.order - nextRoute.order;
    })
  }

  const getTypeSchedule = async (id: string) => {
    try {
      const { data } = await api.get(`programacao/${id}`);
      setTypeScheduleData({
        id: data.data._id,
        name: data.data.nome,
        status: data.data.status ? "Ativo" : "Inativo",
        via: data.data.via,
        modalId: data.data.modal._id,
        loadId: data.data.carga._id,
        situation: data.data.situacao,
        routes: formatRoutes(data?.data?.rotas),
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addTypeSchedule = async (typeScheduleData: TypeSchedule) => {
    if (typeScheduleData.routes.length < 2) {
      return warningMessage(
        "Para criar um tipo de programação é necessário ter pelo menos 2 rotas"
      );
    }

    setLoading(true);

    try {
      await api.post("programacao", {
        nome: typeScheduleData.name,
        via: typeScheduleData.via,
        modal_id: typeScheduleData.modalId,
        carga_id: typeScheduleData.loadId,
        status: typeScheduleData.status === "Ativo",
        situacao: typeScheduleData.situation,
        rotas: typeScheduleData.routes.map((route) => {
          return { nome: route.name, ordem: route.order };
        }),
      });
      navigate("/schedule-type");
      successMessage("Tipo de programação adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error.response.data.message[0]) {
        errorMessage(error.response.data.message[0])
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível adicionar tipo de programação");
      setLoading(false);
    }
  };

  const updateTypeSchedule = async (typeScheduleData: TypeSchedule) => {
    if (typeScheduleData.routes.length < 2) {
      return warningMessage(
        "Tipo de programação deve ter pelo menos 2 rotas"
      );
    }
    
    setLoading(true);
    try {
      await api.patch(`programacao/${id}`, {
        nome: typeScheduleData.name,
        status: typeScheduleData.status === "Ativo",
        via: typeScheduleData.via,
        modal_id: typeScheduleData.modalId,
        carga_id: typeScheduleData.loadId,
        situacao: typeScheduleData.situation,
        rotas: typeScheduleData.routes.map((route) => {
          return {
            nome: route.name,
            ordem: route.order,
          };
        }),
      });
      navigate("/schedule-type");
      successMessage("Tipo de programação atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar tipo de programação");
      setLoading(false);
    }
  };

  const deleteTypeSchedule = async (id: string) => {
    try {
      await api.delete(`programacao/${id}`);
      getAllTypeSchedule();
      setRefresh(prev => !prev);
      successMessage("Tipo de programação excluído com sucesso!");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getAll = () => {
    const listModals = api.get("modals");
    const listTypeLoad = api.get("cargas");

    axios
      .all([listModals, listTypeLoad])
      .then(
        axios.spread((...responses) => {
          const listModals = responses[0];
          const listTypeLoad = responses[1];

          setSelectList({
            ...selectList,
            typeLoad: listTypeLoad?.data?.data?.data?.map((result: any) => {
              return {
                id: result?._id,
                name: result?.nome,
              };
            }),
            modal: listModals?.data?.data?.data?.map((result: any) => {
              return {
                id: result?._id,
                name: result?.nome,
              };
            }),
          });
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  };

  const [listTypeSchedule, setListTypeSchedule] = useState<Schedule[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination: any = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllTypeSchedule(value);
  };


  interface GetAllTypeOfSchedule {
    name?: string;
    paginationIndex: number;
    getAll?: boolean;
    rowsPerPage?: number;
  }

  const getAllTypeOfSchedule = async <T>({name, paginationIndex, getAll, rowsPerPage = 10} : GetAllTypeOfSchedule) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)

    const nameToQuery = `${name ? `&nome=${name}` : ''}`;

    try {
      const response = await api.get(getAll ? "programacao" : `programacao?limit=${rowsPerPage}&skip=${paginationIndex}${nameToQuery}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result?._id,
            name: result?.nome,
            status: result?.status,
            via: result?.via,
            modal: result?.modal?.nome,
            typeLoad: result?.carga?.nome,
            situation: result?.situacao,
            routes: result?.rotas?.map((rota: any) => ({
              id: rota._id,
              name: rota.nome,
              order: rota?.ordem,
              sender: "",
              zipCode: "",
              state: "",
              city: "",
              neighborhood: "",
              street: "",
              number: "",
              complement: "",
              reference: "",
              collectDate: ""
            }))
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],  
      totalItems,
    };
  }

  const getAllTypeSchedule = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const response = await api.get(
        `programacao`
      );

      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(response?.data?.data?.total / SKIPLIMIT),
      });

      setListTypeSchedule(
        response?.data?.data?.data?.map((result: any) => {
          return {
            id: result?._id,
            name: result?.nome,
            status: result?.status,
            via: result?.via,
            modal: result?.modal?.nome,
            typeLoad: result?.carga?.nome,
            situation: result?.situacao,
            routes: result?.rotas?.map((rota: any) => ({
              id: rota?._id,
              name: rota?.nome,
              order: rota?.ordem,
              sender: "",
              zipCode: "",
              state: "",
              city: "",
              neighborhood: "",
              street: "",
              number: "",
              complement: "",
              reference: "",
              collectDate: "",
            })),
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    typeScheduleData,
    setTypeScheduleData,
    listTypeSchedule,
    getTypeSchedule,
    addTypeSchedule,
    updateTypeSchedule,
    deleteTypeSchedule,
    getAllTypeSchedule,
    getAll,
    selectList,
    getAllTypeOfSchedule,
    loadingData,
    refresh
  };
}
