import { FC, useState, useEffect } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';

interface ReportCheckboxProps {
  label: string,
  value?: boolean,
  onToggle?: (value: boolean) => void;
  isRadio?: boolean;
  checked?: boolean;
  disabled?: boolean
}

const ReportCheckbox: FC<ReportCheckboxProps> = ({ label, isRadio, checked, onToggle, disabled }) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(checked || false)
  }, [])

  if(isRadio) {
    return (
      <FormControl>
        <FormControlLabel 
          //value={value} 

          checked={checked}
          control={
            <Radio 
              disabled={disabled}
              //defaultChecked={checked}  
              checked={value}
              onClick={e =>{
                setValue(!value)
                onToggle && onToggle(!value)
              }}
            />
          } 
          label={label} 
          style={{ marginRight: 20 }}
        />
      </FormControl>
    )
  }
  return(
    <FormControlLabel 
      control={
        <Checkbox   
          checked={value}
          onClick={e =>{
            setValue(!value)
            onToggle && onToggle(!value)
          }}
        />
      } 
      label={label} 
      value={value}
      style={{ marginRight: 20 }}
    />
  )
}

export default ReportCheckbox