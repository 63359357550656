import React, {useState, useEffect, FC, ReactNode} from 'react';

import Body from './body';
import Footer from './footer';
import NoContent from './noContent';
import {
  Container,
  Content,
  LoadingContainer,
} from './styles';
import { Loading } from '../Loading';

export type CustomCancelModalType = ({isModalOpen, closeModal, selectedItem} :{isModalOpen: boolean, closeModal: () => void, selectedItem: any}) => ReactNode

export interface GenericTableProps {
  header: {key: string, label: string, asButton?: boolean, sorted?: string}[],
  data: any[],
  footerColor?: string,
  foooterBgColor?: string,
  bgColor?: string,
  itemsColor?: string,
  headerColor?: string,
  headerBgColor?: string,
  bodyItemsColors?: string[],
  rowsPerPageOptions?: number[],
  rowsPerPage?: number,
  total?: number,
  borderRadius?: string,
  enableBorderToHeader?: boolean,
  isLoading?: boolean,
  activeIndex?: number;
  onChangeIndex?: (index: number) => void,
  onChangeRowsPerPage?: (rowsPerPage: number) => void,
  pagination?: boolean,
  noContentText?: string,
  handleSort?: (data: {sortBy: string; order: string}) => void,
  handleEdit?: (item: any) => void,
  handleDelete?: (item: any) => void,
  handleCancel?: (item: any) => void,
  disableHandleCancel?: boolean
  onClick?: (item: any) => void
  hideActions?: boolean
  customAction?: (item: any) => ReactNode
  customCancelModal?: CustomCancelModalType
}

const GenericTable: FC<GenericTableProps> =
({
  header,
  data,
  footerColor = '#4f4f4f',
  foooterBgColor = '#fff',
  bgColor = '#fff',
  itemsColor = '#4f4f4f',
  headerColor = '#4f4f4f',
  headerBgColor = '#fff',
  bodyItemsColors = ['#fff', '#fff'],
  rowsPerPageOptions = [5, 10, 25],
  rowsPerPage = 10,
  total = 0,
  borderRadius = '4px',
  enableBorderToHeader = true,
  isLoading = false,
  onChangeIndex = () => {},
  onChangeRowsPerPage = () => {},
  pagination = true,
  noContentText = 'Sem informação a ser mostrada',
  handleSort = () => {},
  handleEdit,
  handleDelete,
  handleCancel,
  onClick,
  hideActions = false,
  activeIndex : index = 0,
  customAction,
  disableHandleCancel,
  customCancelModal
}) => {
  const [activeRowsPerPage, setActiveRowsPerPage] = useState(rowsPerPage);
  const [activeIndex, setActiveIndex] = useState(index);

  let headerWidthActions = header;
  let dataWidthActions = data

  if(handleEdit || handleDelete || handleCancel) {
    headerWidthActions = [...header, {
      key: "actions", 
      label: ""
    }]

    dataWidthActions = data?.map(item => ({
      ...item, 
       actions: "actions"
    }))
  }

  const changeActiveRowsPerPage = (newRowsPerPage: number) => {
    setActiveIndex(0);
    setActiveRowsPerPage(newRowsPerPage);
    onChangeRowsPerPage(newRowsPerPage);
  };

  const handleActiveIndex = (toNext: boolean) => {
    if (toNext && (activeRowsPerPage * activeIndex + activeRowsPerPage) > total) {
      return;
    }
    if (toNext) {
      setActiveIndex(prev => prev + 1);
      return;
    }

    if (activeIndex <= 0) {
      return;
    }
    setActiveIndex(prev => prev - 1);
  };

  useEffect(() => {
    onChangeIndex(activeIndex);
  }, [activeIndex]);

  return (
    <Container borderRadius={borderRadius}>
      <Content>
        <Body
          itemsColor={itemsColor}
          isLoading={isLoading}
          enableBorderToHeader={enableBorderToHeader}
          quantity={headerWidthActions?.length}
          header={headerWidthActions}
          data={dataWidthActions}
          headerBgColor={headerBgColor}
          headerColor={headerColor}
          bodyItemsColors={bodyItemsColors}
          handleSort={handleSort}
          pagination={pagination}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          hideActions={hideActions}
          onClick={onClick}
          customAction={customAction}
          customCancelModal={customCancelModal}
          disableHandleCancel={disableHandleCancel}
        />
        {!isLoading && data.length === 0 && (
          <NoContent color={itemsColor} noContentText={noContentText} bgColor={bgColor || bodyItemsColors[0]} />
        )}

        {isLoading && (
          <LoadingContainer>
            <Loading size={40} color={itemsColor} />
          </LoadingContainer>
        )}
        {pagination && (
          <Footer
            isLoading={isLoading}
            activeRowsPerPage={activeRowsPerPage}
            total={total}
            color={footerColor}
            bgColor={foooterBgColor}
            disablePrev={activeIndex === 0 || data.length === 0}
            disableNext={activeIndex === total - 1 || data.length === 0}
            rowsPerPageOptions={rowsPerPageOptions}
            changeActiveRowsPerPage={changeActiveRowsPerPage}
            handleActiveIndex={handleActiveIndex}
            activeIndex={activeIndex}
          />
        )}
      </Content>
    </Container>
  );
};

export default GenericTable;
