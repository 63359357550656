import { useState, useMemo, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { TablePagination } from "../../../../../components/TablePagination";
import { useServiceOrder } from "../../../../../hooks/useServiceOrder";
import { useParams } from "react-router-dom";
import { dateToShow, timeToShow } from "../../../../../utils";
import GenericExport from "../../../../../components/GenericExport";

type Notification = {
  id: string;
  initialTime: string;
  runtime: string;
  expirationTime: string;
  status: boolean;
};

const tableHeaderToExport = [
  {key: "titulo", label: "Etapa"},
  {key: "startDate", label: "Data/Hora do disparo"},
  {key: "tempo_execucao", label: "Tempo de execução"},
  {key: "date", label: "Data/Hora do fim da execução"},
  {key: "statusMessage", label: "Desempenho da etapa"},
]

export const TabNotifications = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    // getAllDrivers(value);
  };

  const { id } = useParams();

  const filterToExport = {
    os_id: id,
  }

  const [data, setData] = useState()

  const {
    getServiceOrder,
    serviceOrderData,
    //loadingData
  } = useServiceOrder();

  useEffect(() => {
    getServiceOrder(id as any);
  }, [])

  const getListOfFinishedSteps = (data: any) => {
    if(!data) {
      return []
    }
    const list: any[] = []
    data?.forEach((item: any) => {
      const executionTimeLabel = item?.executionTime > 1  ? "horas" : "hora"
      
      if(item.status === "CONCLUIDO"){
        list.push({
          id: item?.name,
          initialTime: item?.startDate  ? dateToShow(item?.startDate, true) : "Esperando conclusão",
          runtime: `${item?.executionTime} ${executionTimeLabel}`,
          expirationTime:  item?.date  ? dateToShow(item?.date, true) : "Esperando conclusão",
          status: item?.statusMessage,
        })
      }
    })

    return list
  }

  const handleChooseStatusText = (status: string) => {
    if(!status) {
      return "gray"
    }
    return status  === "concluida dentro do prazo" ? "#6FED8B": "#FF3C40";
  }

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "Etapa", flex: 0.5, disableColumnMenu: true },
      {
        field: "initialTime",
        headerName: "Data/Hora do disparo",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "runtime",
        headerName: "Tempo de execução",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "expirationTime",
        headerName: "Data/Hora do fim da execução",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        disableColumnMenu: true,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",

              width: "100%",
              padding: "4px 0px",
              borderRadius: "4px",

              fontSize: 12,
              color: params.value  === "concluida dentro do prazo" ? "#252525" : "#fff",
              opacity: "0.8",
              backgroundColor: handleChooseStatusText(params?.value)
            }}
          >
            {params.value || "Esperando conclusão"}
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <Grid item lg={12}>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 4, marginBottom: 10, marginRight: 4 }}>
        <GenericExport
          headerToRows={tableHeaderToExport}
          endpoint="os-steps"
          title="Exportar lista das Notificações da OS"  
          filter={filterToExport}
        />
      </div>
      <div style={{ height: 630, width: "100%" }}>
        <DataGrid
          rows={getListOfFinishedSteps(serviceOrderData?.checklist?.steps)}
          columns={columns}
          checkboxSelection
          hideFooterSelectedRowCount
          components={{
            Pagination: () => (
              <TablePagination
                count={pagination.pageQuantity}
                page={pagination.currentPage}
                onChange={handleChangePagination}
              />
            ),
          }}
          disableSelectionOnClick
          // onSelectionModelChange={(values) => console.log(values)}
        />
      </div>
    </Grid>
  );
};

