import { errorMessage } from "../../components/Messages";
import { api } from "../api"

export const getPresignedUrl = async (key: string, contentType: string) =>  {
  let response = {
    key: "",
    url: ""
  }
  try {
    const res = await api.post(`imagem/post-presigned?key=${key}&contentType=${"image/jpeg"}`, {Headers: {
      "Content-Type": "image/jpeg",
    }});

    response.key = res?.data?.Key
    response.url = res?.data?.url

  } catch (error: any) {
    const response = error.response
    errorMessage("Erro na busca pela url")
    console.log("Vamos ver se temos erro aqui:", error)
  }

  return response
}