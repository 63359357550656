import { useEffect, useState } from "react"
import { Button, Typography, Dialog, DialogContent, InputLabel, TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import { useServiceOrder } from "../../hooks/useServiceOrder";
import { Loading } from "../../components/Loading";

type ModalDeleteProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  selectedItem: any;
  cancelServiceOrder: (id: string, justification: string) => void
  loading: boolean
};

export const CancelModal = ({
  isModalOpen,
  closeModal,
  selectedItem,
  cancelServiceOrder,
  loading
}: ModalDeleteProps) => {
  const classes = useStyles();
  const [txt, setTxt] = useState("")
  const [verifyIfCanClose, setVerifyIsCanClose] = useState(false)

  const handleCancelServiceOrder = (tx: string) => {
    cancelServiceOrder(selectedItem.id, tx);
    setVerifyIsCanClose(true)
  };
  
  const handleCloseModal = () => {
    closeModal()
    setTxt("")
  }

  useEffect(() => {
    if(verifyIfCanClose && !loading) {
      handleCloseModal()
      setVerifyIsCanClose(false)
    }
  }, [verifyIfCanClose, loading])


  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal}>
      <DialogContent style={{ marginLeft: 12, marginRight: 12 }}>
        <div>
          <Typography style={{ fontSize: 24, textAlign: "center" }}>
           {`Tem a certeza de que deseja cancelar esta OS ${selectedItem?.name || ""}?`}
          </Typography>
        </div>
      </DialogContent>
      <DialogContent style={{ marginLeft: 12, marginRight: 12, marginBottom: 20 }}>
      <InputLabel id="demo-simple-select-outlined-label">Qual é o motivo do cancelamento da OS?*</InputLabel>
        <TextField
          style={{ width: '100%', marginTop: 10 }}
          placeholder="Justificativa do cancelamento"
          variant="outlined"
          onChange={e => {
            setTxt(e.target.value)
          }}
          size="small"
        />
      </DialogContent>
      
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 12,
          marginRight: 12,
          paddingBottom: 24,
        }}
      >
        <Button className={classes.buttonBack} onClick={() => handleCloseModal()}>
          Não desejo
        </Button>
        <Button
          disabled={loading || !txt}
          className={classes.buttonModalOk}
          variant="text"
          onClick={() => {
           
            handleCancelServiceOrder(txt)
          }}
        >
          {loading ? <Loading/> : "Sim, desejo"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};