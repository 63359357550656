import { CssBaseline } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import { AuthUserContextProvider } from "./contexts/AuthUserContext";
// import { NotificationsContextProvider } from "./contexts/NotificationsContext";

import { Routes } from "./routes";

const queryClient = new QueryClient()


const theme = createTheme({
  typography: {
    fontFamily: ["Barlow", "sans-serif"].join(","),
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthUserContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
          <ToastContainer limit={3} transition={Slide} />
        </ThemeProvider>
      </AuthUserContextProvider>
    </QueryClientProvider>
  );
}
