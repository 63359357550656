import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    buttonManual: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
      cursor: "pointer",
    },
    imageTitle: {
      color: "#6A6A6A",
      fontWeight: 500,
      fontSize: 14,
    },
    imageSubtitle: {
      color: "#CDCDCD",
      fontWeight: 500,
      fontSize: 14,
    },
    button: {
      //width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 30,
      paddingLeft: 30,
      marginLeft: 4,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    }
  }
));
