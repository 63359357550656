import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { useVehicles } from "../../../hooks/Users/useVehicles";
import { useStyles } from "./styles";
import GenericTable from "../../../components/GenericTable";
import GenericExport from "../../../components/GenericExport";

const tableHeader = [
  {key: 'name', label: 'Nome'},
  {key: 'createdAt', label: 'Data Cadastro'},
  {key: 'type', label: 'Tipo'},
  {key: 'renavam', label: 'Renavam'},
  {key: 'chassis', label: 'Chassi'},
  {key: 'plate', label: 'Placa'},
  {key: 'status', label: 'Status'},
];

const tableHeaderToExport = [
  {key: 'nome', label: 'Nome'},
  {key: 'createdAt', label: 'Data de Cadastro'},
  {key: 'tipo_name', label: 'Tipo'},
  {key: 'renavam', label: 'Renavam'},
  {key: 'chassis', label: 'Chassi'},
  {key: 'placa', label: 'Placa'},
  {key: 'cidade', label: 'Cidade'},
  {key: 'estado_uf', label: 'Estado (UF)'},
  {key: 'motorista_name', label: 'Motorista responsável'},
  {key: 'proprietario_name', label: 'Nome do Proprietário'},
  {key: 'proprietario_cpf_cnpj', label: 'CPF/CNPJ do Proprietário'},
  {key: 'proprietario_rg', label: 'IE do Proprietário'},
  {key: 'proprietario_data_nascimento', label: 'Data de nascimento do Proprietário'},
  {key: 'status', label: 'Status'},
];

export const Vehicles = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [data, setData] = useState<any>()
  const [totalItems, setTotalItems] = useState(0)
  const [plateOrName, setPlateOrName] = useState("")
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchFor, setSearchFor] = useState('placa')

  const {
    deleteVehicle,
    getAllVehiclesList,
    refresh,
    loadingData
  } = useVehicles();

  const getAllData = async () => {
    const {data, totalItems: total} = await getAllVehiclesList({
      plate: searchFor === 'placa' ? plateOrName : '', 
      name: searchFor === 'nome' ? plateOrName : '',
      paginationIndex, 
      rowsPerPage
    })

    if(data) {
      setData(data.map((item: any) => ({
        ...item,
        status: (
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",

              width: 200,
              padding: "4px 0px",
              borderRadius: "4px",

              fontSize: 12,
              color: "#252525",
              opacity: "0.8",
              backgroundColor: (item?.status as Boolean)
                ? "#6FED8B"
                : "#FF3C40",
            }}
          >
            {(item?.status as Boolean) ? "Ativo" : "Inativo"}
          </Typography> 
          )
      })))
      setTotalItems(total)
    }
  }

  useEffect(() => {
    getAllData()
  }, [plateOrName, paginationIndex, refresh, rowsPerPage]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={3}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Veículos
        </Typography>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchBarInputsContainer}>
              <TextField
                className={classes.searchBarInput}
                style={{ borderRadius: '0px !important' }}
                placeholder={`Pesquisar por ${searchFor}`}
                variant="outlined"
                size="small"
                value={plateOrName}
                onChange={e => {
                  setPlateOrName(e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel> */}
                <Select
                   className={classes.select}
                  //labelId="demo-simple-select-outlined-label"
                  //id="demo-simple-select-outlined"
                  //value={statusQuery || status}
                  defaultValue="placa"
                  onChange={e => {
                    setSearchFor(e.target.value as string)
                    setPlateOrName("")
                    // setStatus(e.target.value as any)
                    // handleAddParamsToQuery("status", e.target.value as any)
                  }}
                  //label="Status"
                >
                  {['placa', 'nome'].map(item => 
                    <MenuItem key={item} value={item}>
                      {item.toUpperCase()}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className={classes.searchBarActionButtonsContainer}>
              <GenericExport 
                headerToRows={tableHeaderToExport}
                endpoint="veiculos"
                title="Exportar lista de veículos"
              />
              <Button
                startIcon={<AddIcon />}
                className={classes.buttonRegister}
                onClick={() => navigate("/vehicles/new")}
              >
                Cadastrar
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item lg={12}>
        <GenericTable
          header={tableHeader}
          data={data || []}
          isLoading={loadingData}
          handleDelete={(item) => deleteVehicle(item?.id)}
          handleEdit={(item) => navigate(`/vehicles/${item?.id}/edit`)}
          onClick={(item) => navigate(`/vehicles/${item?.id}`)}
          total={totalItems}
          onChangeIndex={(index) =>  setPaginationIndex(index)}
          onChangeRowsPerPage={(value) => setRowsPerPage(value)}
          noContentText="Sem veículos para mostrar"
        />
      </Grid>
    </Grid>
  );
};
