import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { TablePagination } from "../../../components/TablePagination";
import { TableActions } from "../../../components/TableActions";
import { useStyles } from "./styles";
import { useTypeStatus } from "../../../hooks/useTypeStatus";

export const Status = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    loading,
    pagination,
    handleChangePagination,
    listTypeStatus,
    deleteTypeStatus,
    getAllTypeStatus,
  } = useTypeStatus();

  useEffect(() => {
    getAllTypeStatus();
  }, []);

  // const [selectedStatusTypes, setSelectedStatusTypes] = useState<StatusType[]>(
  //   []
  // );
  // const handleSelectedStatusType = (selectedStatusType: StatusType) => {
  //   const updatedSelectedStatusTypes = [...selectedStatusTypes];
  //   const alreadySelected = updatedSelectedStatusTypes.find(
  //     (user) => user.id === selectedStatusType.id
  //   );
  //   if (alreadySelected) {
  //     setSelectedStatusTypes(
  //       updatedSelectedStatusTypes.filter(
  //         (user) => user.id !== selectedStatusType.id
  //       )
  //     );
  //   } else {
  //     setSelectedStatusTypes([
  //       ...updatedSelectedStatusTypes,
  //       selectedStatusType,
  //     ]);
  //   }
  // };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Nome",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        disableColumnMenu: true,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",

              width: "100%",
              padding: "4px 0px",
              borderRadius: "4px",

              fontSize: 12,
              color: "#252525",
              opacity: "0.8",
              backgroundColor: (params.value as Boolean)
                ? "#6FED8B"
                : "#FF3C40",
            }}
          >
            {(params.value as Boolean) ? "Ativo" : "Inativo"}
          </Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Ações",
        flex: 0.1,
        disableColumnMenu: true,
        hideSortIcons: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <TableActions
            params={params}
            viewFunction={() => navigate(`/status/${params.row.id}`)}
            editFunction={() => navigate(`/status/${params.row.id}/edit`)}
            deleteFunction={() => deleteTypeStatus(params.row.id)}
          />
        ),
      },
    ],
    []
  );

  return (
    <Grid container spacing={2}>
      <Grid item lg={3}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Status de Ordem de Serviço
        </Typography>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchBarInputsContainer}>
              <TextField
                className={classes.searchBarInput}
                placeholder="Pesquisar"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.searchBarActionButtonsContainer}>
              {/* <IconButton
                className={classes.buttonDelete}
                onClick={() => alert("Em Implementação!")}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
              <IconButton
                className={classes.buttonFilter}
                onClick={() => alert("Em Implementação!")}
              >
                <FilterListIcon fontSize="medium" />
              </IconButton> */}
              <Button
                startIcon={<AddIcon />}
                className={classes.buttonRegister}
                onClick={() => navigate("/status/new")}
              >
                Cadastrar
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item lg={12}>
        <div style={{ height: 630, width: "100%" }}>
          <DataGrid
            rows={listTypeStatus}
            columns={columns}
            loading={loading}
            checkboxSelection
            hideFooterSelectedRowCount
            // page={page}
            // pageSize={10}
            // rowsPerPageOptions={[5]}
            // onPageChange={(newPage) => setPage(newPage)}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // onRowClick={(params) => handleSelectedStatusType(params.row)}
            components={{
              Pagination: () => (
                <TablePagination
                  count={pagination.pageQuantity}
                  page={pagination.currentPage}
                  onChange={handleChangePagination}
                />
              ),
            }}
            disableSelectionOnClick
            // onSelectionModelChange={(values) => console.log(values)}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const listStatus = [
  { text: "Organizar por", value: "Organizar por" },
  { text: "Ativo", value: "Ativo" },
  { text: "Inativo", value: "Inativo" },
];
