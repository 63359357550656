import { CSSProperties, FC, useEffect, useState } from "react";

import {
  ImageOutlined as ImageIcon,
} from "@material-ui/icons/";

import {useStyles} from "./styles"
import { Typography } from "@material-ui/core";
import { getPresignedImage } from "../../services/getPresignedImage";

interface ImageComponentProps {
  imageKey: string, 
  alt?: string,
  onlyImage?: boolean, 
  imageStyles?: CSSProperties,
  isVideo?: boolean;
  isDocument?: boolean
}

const ImageComponent: FC<ImageComponentProps> = ({ imageKey, alt, onlyImage, imageStyles, isVideo, isDocument }) => {
  const classes = useStyles()

  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    (async () => {
      const {url: imageURL} = await getPresignedImage(imageKey)

      setUrl(imageURL)
    })()

  }, [imageKey])

  if(isDocument) {
    return(
      <embed src={url} type="application/pdf" width="600px%" height="600px" />
    )
  }

  if (onlyImage) {
    if(isVideo) {
      return (
        <video src={url}
            width="480" height="360" controls>
            Este navegador não suporta vídeos.
      </video>
      )
    }
    return (
      <img
        style={imageStyles}
        src={url}
        alt={alt || "fortallog imagem"}
      />
    )
  }  

  return(
    <div
      className={classes.imageContainer}
      style={{
        border: url
          ? "none"
          : "2px dashed #BCBCBC",
        ...imageStyles  
      }}
    >
      {url ? 
        isVideo ? (
          <video width="320" height="240" controls>
            <source src={url} type="video/mp4"/>
            {/* </source><source src="movie.ogg" type="video/ogg"> */}
            Your browser does not support the video tag.
          </video>
        ) : (
            <img
              style={{
                height: "100%",
                width: "100%",
                borderRadius: 8,
              }}
              src={url}
              alt={alt || "fortallog imagem"}
            />
        )
       : (
        <>
          <ImageIcon />
          <Typography>Imagem</Typography>
        </>
      )}
    </div>
  )
}

export default ImageComponent