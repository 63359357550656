import { Button, Typography, Dialog, DialogContent, Grid, InputLabel, TextField } from "@material-ui/core";
import { Location, useLocation } from "react-router-dom";
import { useStyles } from "./styles";
import { useState } from "react";
import InputPassword from "./inputPassword";
import { errorMessage, successMessage, warningMessage } from "../../components/Messages";
import { api } from "../../services/api";
import { Loading } from "../../components/Loading";

type ModalDeleteProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  userID: string
  isClient: boolean
};

export const UpdatePassword = ({
  isModalOpen,
  closeModal,
  userID,
  isClient
}: ModalDeleteProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")

  const clear = () => {
    setNewPassword('')
    setConfirmPassword('')
  }

  const handleClose = () => {
    closeModal()
    clear()
  }

  const handleUpadePassword = async () => {
    if(!newPassword || !confirmPassword) {
      return warningMessage("É necessário preencher as duas senhas para atualizar!");
    }
    if(newPassword !== confirmPassword) {
      return warningMessage("As senhas devem ser iguais");
    }

    setLoading(true);

    try {
      await api.patch(`users/${isClient ? 'update-client-password' : 'update-password'}/${userID}`, {
        password: newPassword
      });
      handleClose()
      successMessage("Senha Atualizada com sucesso!");
    } catch (error) {
      errorMessage("Não foi possível atualizar a senha, por favor, tente novamente mais tarde!");
    } finally{
      setLoading(false)
    }

  }

  return (
    <Dialog open={isModalOpen} onClose={handleClose}>
      <DialogContent style={{ marginLeft: 12, marginRight: 12 }}>
        <div>
          <Typography style={{ fontSize: 24, textAlign: "center" }}>
           Atualizar senha
          </Typography>
        </div>
      </DialogContent>
      <DialogContent style={{ 
        display: "flex",
        flexDirection: 'column',
        justifyContent: "space-between",
        marginLeft: 12,
        marginRight: 12,
        paddingBottom: 24,
      }}>
        <div style={{  width: '100%'}}>
          <InputLabel className={classes.inputLabel}>
            Nova senha
          </InputLabel>
          <InputPassword
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
        </div>
        <div style={{  width: '100%'}}>
          <InputLabel className={classes.inputLabel}>
            Confirmar senha
          </InputLabel>
          <InputPassword
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 12,
          marginRight: 12,
          paddingBottom: 24,
        }}
      >
        <Button className={classes.buttonDiscard} onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          className={classes.buttonConfirm}
          variant="text"
          onClick={() => {
            handleUpadePassword()
          }}
          
        >
          {loading ? <Loading /> : 'Atualizar'}
        </Button>
      </DialogContent>
    </Dialog>
  );
};