import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  ImageOutlined as ImageIcon,
} from "@material-ui/icons/";

import { useStyles } from "./styles";
import { chooseStausColorAndLabel, getObjectFromQueryParams } from "../../../utils";
import { getOccurrenceFromOrderAndId } from "./services/getOccurrence";
import { endOccurrence } from "./services/endOccurrence";
import ImageComponent from "../../../components/ImageComponent";


export const Occurrency = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const classes = useStyles();
  const params = useParams();

  const id = params.id

  const [occurrencyData, setOccurrencyData] = useState<any>({});
  const [refesh, setRefresh] = useState(true);

  const {occurrence, order} = getObjectFromQueryParams(location.search.substring(1))

  const handleData = (data: any) => {
    setOccurrencyData(data)
  }

  const getOccurrece = async () => {
    getOccurrenceFromOrderAndId({
      id: occurrence,
      order,
      os_id: id as any,
      handleData
    })
  }

  useEffect(() => {
    if (id) {
      getOccurrece();
    }
  }, [id, refesh]);

  const handleEndOccurrence = async () => {
    await endOccurrence({
      id: occurrence,
      order,
      os_id: id as any,
      handleData,
      refresh: () => setRefresh(prev => !prev)
    })
  }


  return (
    <Grid container spacing={2}>
      <Grid item lg={3}>
        <Typography className={classes.pageTitle}>Ordens de Serviço</Typography>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.actionBar}>
            <div className={classes.actionBarLeftContent}>
              <IconButton
                onClick={() => navigate(-1)}
                className={classes.buttonBack}
              >
                <KeyboardArrowLeftIcon fontSize="medium" />
              </IconButton>
              <Typography className={classes.pageSubtitle}>
                Ocorrência
              </Typography>
            </div>
            <div style={{ display: occurrencyData?.status === "CONCLUIDO" ? "none" : "flex" }}>
              <Button className={classes.buttonSave} onClick={() => handleEndOccurrence()}>
                Encerrar Ocorrência
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={1} className={classes.formContainer}>
            <Grid item lg={12}>
              <Typography className={classes.formSectionTitle}>
                Dados do tipo de veículo
              </Typography>
            </Grid>

            <Grid item lg={3}>
              <InputLabel className={classes.inputLabel}>
                Tipo de Ocorrência
              </InputLabel>
              <TextField
                value={occurrencyData?.nome}
                placeholder="Tipo de Ocorrência"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item lg={3}>
              <InputLabel className={classes.inputLabel}>
                Status da ocorrência
              </InputLabel>

              <div className={classes.statusComponent} 
                style={{ backgroundColor: chooseStausColorAndLabel(occurrencyData?.status as any).color }}
              >
                {chooseStausColorAndLabel(occurrencyData?.status as any).label}
              </div>
            </Grid>

            <Grid item lg={12}>
              <Typography className={classes.formSectionTitle}>
                Descrição da ocorrência
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <TextField
                value={occurrencyData?.descricao_ocorrencia}
                placeholder="Descrição"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                multiline
                minRows={4}
              />
            </Grid>

            {
              occurrencyData?.pode_descricao && (
                <>
                  <Grid item lg={12}>
                    <Typography className={classes.formSectionTitle}>
                      Descrição do motorista
                    </Typography>
                  </Grid>
                  <Grid item lg={12}>
                    <TextField
                      value={occurrencyData?.descricao_motorista}
                      placeholder="Descrição"
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      multiline
                      minRows={4}
                    />
                  </Grid>
                </>
              )
            }            
 
            <Grid item lg={12}>
              <Typography className={classes.formSectionTitle}>
                Imagens da ocorrência
              </Typography>
            </Grid>
            <div style={{ width: "100%", display: "flex"}}>
              {occurrencyData?.start_imagens?.map((image: any) => (
                <div style={{marginRight: 10}}>
                  <Typography className={classes.imageText}>
                    {image.imagem_name}
                  </Typography>

                  <ImageComponent 
                    imageKey={image?.imagem_path}
                    imageStyles={{
                      width: 400,
                      borderRadius: 8,
                    }}
                  />
                </div>
              ))}
            </div>

            <Grid item lg={12}>
              <Typography className={classes.formSectionTitle}>
                Comprovativo da resolução da ocorrência
              </Typography>
            </Grid>
            <div style={{ width: "100%", display: "flex"}}>
              {occurrencyData?.end_imagens?.map((image: any) => (
                <div style={{marginRight: 10}}>
                  <Typography className={classes.imageText}>
                    {image.imagem_name}
                  </Typography>

                  <ImageComponent 
                    imageKey={image?.imagem_path}
                    imageStyles={{
                      width: 400,
                      borderRadius: 8,
                    }}
                  />
                </div>
              ))}
            </div>
           
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
