import { Location } from "react-router-dom";
import moment from "moment";
import { StepChecklist } from "../types/managementServiceOrderTypes";

export const onlyNumbers = (string: string) => {
  if(!string) {
    return ""
  }
  return string.replace(/[^0-9]/g, "");
};

export const inCreationOrEditing = (location: Location) => {
  return (
    location.pathname.includes("new") || location.pathname.includes("edit")
  );
};

export const removeEmptyValuesFromObject = (object: any) => {
 for (var key in object) {
    if (object.hasOwnProperty(key)) {
      var value = object[key];
      if (value === null || value === undefined || value === '' || value?.length === 0) {
        delete object[key];
      }
    }
  }
}

export const dateToShow = (date: string, showDate?: boolean) => {
  if(!date || (date && date?.length < 8)) {
    return ""
  }

  if(showDate) {

    return moment(date).format("DD/MM/YYYY - HH:mm")
  }

  return moment(date).format("DD/MM/YYYY")
}

export const timeToShow = (date: string) => {
  if(!date) {
    return ""
  }

  return moment(date).format("HH:mm")
}

export const checkIfDateIsvalid = (date: string) => {
  if(!date || (date && date?.length < 8)) {
    return ""
  }

  return  moment(new Date(date).toISOString()).format("yyyy-MM-DD")
}

interface IormatListToShowInSelectComponent {
  list: any[],
  keyOfTheValueToPutOnLabel: string,
  keyOfTheValueToPutOnValue: string
}

export const formatListToShowInSelectComponent = (
  {
    list,
    keyOfTheValueToPutOnLabel,
    keyOfTheValueToPutOnValue
  }: IormatListToShowInSelectComponent) => {
  if(!list) {
    return []
  }

  return list.map(item => ({
    label: item[keyOfTheValueToPutOnLabel] || "",
    value: item[keyOfTheValueToPutOnValue] || ""
  }))
}

export const getObjectFromQueryParams = (query: string) => {
  if(!query) {
    return ""
  }
  return JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
  
}

export const chooseStausColorAndLabel = (status: string) => {
  switch(status) {
    case "CONCLUIDO":
      return {
        color: "#6FED8B",
        label: "Concluida"
      }
    case "EM_VALIDACAO":
      return {
        color: "#87CFF9",
        label: "Aguardando Encerramento"
      }  
    case "EM_ANDAMENTO":
      return {
        color: "#FED278",
        label: "Em Andamento"
      }     
    default :
      return {
        color: "#D3D3D3",
        label: "Desconocido"
      }  
  }
}

export const checkIfStringIsNumeric = (str: string) => {
  if(!str) {
    return false
  }

  return /^-?\d+$/.test(str);
}

export const tranformStringIntoZipCode = (cep: string) => {
  if(!cep || cep?.length !== 8) {
    return ""
  }

  return cep.slice(0, 5) + "-" + cep.slice(5, 8)
}

export const chechServiceOrderStatusFromClassification = (steps: StepChecklist[]) => {
  const listOfStatus = {
    "No cliente": "", 
    "Coletado": "", 
    "Entregue": ""
  }

  if(!steps) {
    return listOfStatus;
  }

  steps?.forEach(step => {
    if(step?.classification) {
      listOfStatus[step.classification as keyof typeof listOfStatus] = step.status as unknown as string
    }
  })

  return listOfStatus
}

export const printLocationInfo = (location: {latitude: any, longitude: any}) => {
  const {latitude, longitude} = location;
  if(!latitude || !longitude) {
    return ""
  }

  return `${latitude || "00.00000"}, ${longitude || "00.00000"}`
}

export const decimalOnly = (numberr: number) => {
  if(!numberr && numberr !== 0) {
    return ""
  }

  return Math.trunc(numberr);
}

export const formatTimeFromSeconds = (seconds: number) => {
  if(!seconds && seconds !== 0) {
    return ""
  }
  
  let minutes = seconds / 60;

  if(minutes >= 1) {
    if(minutes >= 60) {
      let hours = minutes / 60;

      if(hours >= 24) {
        let days = hours / 24;
        return days < 2 ? `${decimalOnly(days)} dia` : `${decimalOnly(days)} dias`
      }

      return hours < 2 ? `${decimalOnly(hours)} hora` : `${decimalOnly(hours)} horas`
    }

    return minutes < 2 ? `${decimalOnly(minutes)} minuto` : `${decimalOnly(minutes)} minutos`
  }

  return seconds < 2 ? `${decimalOnly(seconds)} segundo` : `${decimalOnly(seconds)} segundos`
}