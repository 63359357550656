import { Grid, Typography } from "@material-ui/core";

import { ImageCheckbox } from "../../../../../components/ImageCheckbox";
import { Vehicle } from "../../../../../types/managementUserTypes";

import { useStyles } from "./styles";

type VehiclePhotosProps = {
  values: Vehicle;
  handleChange: any;
  disabled: boolean;
};

export const VehiclePhotos = ({
  values,
  handleChange,
  disabled,
}: VehiclePhotosProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.formContainer}>
      <Grid item lg={12}>
        <Typography
          className={classes.formSectionTitle}
          style={{ color: "#2F4D7C" }}
        >
          Veículo
        </Typography>
      </Grid>
      {values?.imageDocuments
        .map((image: any, index) => (
          <ImageCheckbox
            image={image}
            key={`imagem-do-documento-${index}`}
            switchName={list[index]}
            switchChecked={image?.obrigatorio}
            switchOnChange={() => {
              let img = [...values.imageDocuments] as any;

              img[index] = {
                ...image,
                obrigatorio: !(image as any)?.obrigatorio
              }    

              handleChange({
                ...values,
                imageDocuments: img
              })
            }}
            disabled={disabled}
          />
        ))
        .sort((image: any, nextImage: any) => {
          return image.order - nextImage.order;
        })}

      <Grid item lg={12}>
        <Typography
          className={classes.formSectionTitle}
          style={{ color: "#2F4D7C" }}
        >
          Fotos do veículo
        </Typography>
      </Grid>
      {values.imageVehicle
        .map((image: any, index) => (
          <ImageCheckbox
            image={image}
            key={`imagem-do-veiculo-${index}`}
            switchName={list1[index]}
            switchChecked={image?.obrigatorio}
            //switchOnChange={handleChange(`imageVehicle[${index}].required`)}
            switchOnChange={() => {
              let img = [...values.imageVehicle] as any;

              img[index] = {
                ...image,
                obrigatorio: !(image as any)?.obrigatorio
              }

              handleChange({
                ...values,
                imageVehicle: img
              })
            }}
            disabled={disabled}
          />
        ))
        .sort((image: any, nextImage: any) => {
          return image.order - nextImage.order;
        })}

      <Grid item lg={12}>
        <Typography
          className={classes.formSectionTitle}
          style={{ color: "#2F4D7C" }}
        >
          Fotos dos pneus
        </Typography>
      </Grid>
      {values.imageTires
        .map((image: any, index) => (
          <ImageCheckbox
            image={image}
            switchName={list2[index]}
            switchChecked={image?.obrigatorio}
            key={`imagem-do-pneu-${index}`}
            switchOnChange={() => {
              let img = [...values.imageTires] as any;

              img[index] = {
                ...image,
                obrigatorio: !(image as any)?.obrigatorio
              }

              handleChange({
                ...values,
                imageTires: img
              })
            }}
            disabled={disabled}
          />
        ))
        .sort((image: any, nextImage: any) => {
          return image.order - nextImage.order;
        })}
    </Grid>
  );
};

const list = ["crlv", "antt", "tachograph"];
const list1 = [
  "frontBumper",
  "rearBumper",
  "headlight",
  "rearLight",
  "motor",
  "horseUnitFloor",
  "fuelTankDriver",
  "fuelTankPassenger",
  "cabin",
  "airBag",
  "transmissionShaft",
  "fifthWheel",
  "pneumaticConnections",
  "exhaustSystem",
  "chassisHorse",
  "chassisTrailer",
];
const list2 = [
  "driverDirectionalTire",
  "passengerDirectionalTire",
  "driverSide1stAxleTireInside",
  "driverSide1stAxleTireOutside",
  "passengerSide1stAxleTireInside",
  "passengerSide1stAxleTireOutside",
  "driverSide2ndAxleTireInside",
  "driverSide2ndAxleTireOutside",
  "passengerSide2ndAxleTireInside",
  "passengerSide2ndAxleTireOutside",
  "driverSide1stTrailerAxleTireInside",
  "driverSide1stTrailerAxleTireOutside",
  "passengerSide1stTrailerAxleTireInside",
  "passengerSide1stTrailerAxleTireOutside",
  "driverSide2ndTrailerAxleTireInside",
  "driverSide2ndTrailerAxleTireOutside",
  "passengerSideTrailer2ndAxleTireInside",
  "passengerSideTrailer2ndAxleTireOutside",
  "driverSide3rdAxleTrailerTireInside",
  "driverSide3rdAxleTrailerTireOutside",
  "passengerSideTrailer3rdAxleTireInside",
  "passengerSideTrailer3rdAxleTireOutside",
];
