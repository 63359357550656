import MaskedInput from "react-text-mask";

interface InputMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  name: string;
}

export const InputMask = (props: InputMaskProps) => {
  const { inputRef, ...other } = props;
  let maskInput: (string | RegExp)[] = [];

  switch (props.name) {
    case "cnpj":
      maskInput = [
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
      ];
      break;
    case "cpf":
      maskInput = [
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ];
      break;
    case "zipCode": 
    case "CEP":
      maskInput = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
      break;
    case "plate":
      maskInput = [
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        "-",
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
      ];
      break;
    default:
      maskInput = [
        "(",
        /[1-9]/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
      break;
  }

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskInput}
      placeholderChar={"\u2000"}
    />
  );
};
