import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../../components/Messages";
import { User } from "../../types/managementUserTypes";
import { onlyNumbers, removeEmptyValuesFromObject } from "../../utils";
import { api } from "../../services/api";
import { LOCAL_STORAGE_KEY} from "../../consts";


type UserData = {
  id: string;
  name: string;
  profileType: string;
  company: string;
  email: string;
  status: boolean;
};

const SKIPLIMIT = 10;

export function useUsers() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [refreshUserList, setRefreshUserList] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [userData, setUserData] = useState<User>({
    id: "",
    name: "",
    cpf: "",
    profileType: "",
    expirationAccess: "",
    email: "",
    phone: "",
    document: "",
    phoneSecondary: "",
    passwordResetFrequency: 1,
    status: "Ativo",
    clients: [],
    cnpj: ""
  });

  const [selectList, setSelectList] = useState({
    status: ["Ativo", "Inativo"],
    listPasswordResetFrequency: [
      { id: 1, value: "1 mês" },
      { id: 2, value: "2 meses" },
      { id: 3, value: "3 meses" },
      { id: 4, value: "4 meses" },
      { id: 5, value: "5 meses" },
      { id: 6, value: "6 meses" },
      { id: 7, value: "7 meses" },
      { id: 8, value: "8 meses" },
      { id: 9, value: "9 meses" },
      { id: 10, value: "10 meses" },
      { id: 11, value: "11 meses" },
      { id: 12, value: "1 ano" },
    ],
    listProfileType: [{ id: "", name: "" }],
  });

  const getUser = async (id: string) => {
    try {
      const { data } = await api.get(`users/${id}`);

      setUserData({
        id: data.data._id,
        name: data.data.nome,
        cpf: data.data.cpf,
        document: data.data.documento,
        profileType: data.data.role._id,
        expirationAccess: new Date(data.data.validade_acesso)
          .toISOString()
          .slice(0, 10),
        email: data.data.email,
        phone: data.data.telefone,
        phoneSecondary: data.data.telefone2,
        passwordResetFrequency: data.data.frequencia_redefinicao_senha,
        status: data.data.status ? "Ativo" : "Inativo",
        clients: data.data.clientes,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getCurrentUser = async () => {

    try {
      const USER_ID = await localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ID)
      if(!USER_ID) return
      const { data } = await api.get(`users/${JSON.parse(USER_ID)}`);

      setUserData({
        id: data.data._id,
        name: data.data.nome,
        cpf: data.data.cpf,
        document: data.data.documento,
        profileType: data.data.role._id,
        expirationAccess: new Date(data.data.validade_acesso)
          .toISOString()
          .slice(0, 10),
        email: data.data.email,
        phone: data.data.telefone,
        phoneSecondary: data.data.telefone2,
        passwordResetFrequency: data.data.frequencia_redefinicao_senha,
        status: data.data.status ? "Ativo" : "Inativo",
        clients: data.data.clientes,
        cnpj: data.data?.cnpj
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addUser = async (userData: User) => {
    setLoading(true);
    const dataToSend = {
      nome: userData.name,
      cpf: onlyNumbers(userData.cpf),
      documento: userData.document,
      role_id: userData.profileType,
      clientes: userData.clients,
      email: userData.email,
      telefone: onlyNumbers(userData.phone),
      telefone2: onlyNumbers(userData.phoneSecondary),
      frequencia_redefinicao_senha: userData.passwordResetFrequency,
      status: userData.status === "Ativo",
    }
    removeEmptyValuesFromObject(dataToSend);
    try {
      await api.post("users", dataToSend);
      navigate("/users");
      successMessage("Usuário adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error?.response?.data?.message[0]) {
        setLoading(false);
        if(typeof error?.response?.data?.message[0] === "object") {
          errorMessage(error?.response?.data?.message[0][0]);
          return
        }
        errorMessage(error?.response?.data?.message[0])
        return
      }
      errorMessage("Não foi possível adicionar usuário");
      setLoading(false);
    }
  };

  const updateUser = async (userData: User) => {
    setLoading(true);
    try {
      await api.patch(`users/${id}`, {
        nome: userData.name,
        cpf: userData.cpf,
        documento: userData.document,
        role_id: userData.profileType,
        validade_acesso: userData.expirationAccess,
        email: userData.email,
        telefone: userData.phone,
        telefone2: userData.phoneSecondary,
        frequencia_redefinicao_senha: userData.passwordResetFrequency,
        status: userData.status === "Ativo",
        clientes: userData.clients,
      });
      navigate("/users");
      successMessage("Usuário atualizado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error.response.data.message[0]){
        errorMessage(error.response.data.message[0][0])
        return
      }
      errorMessage("Não foi possível atualizar usuário");
      setLoading(false);
    }
  };

  const getAllProfileType = async () => {
    try {
      const { data } = await api.get("roles");
      setSelectList({
        ...selectList,
        listProfileType: data.data.map((profile: any) => {
          return {
            id: profile._id,
            name: profile.nome,
          };
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [listUsers, setListUsers] = useState<UserData[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllUsers(value);
  };


  interface GetAllUserList {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number;
  }

  const getAllUserList = async <T>({name, paginationIndex, rowsPerPage = 10} : GetAllUserList) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)

    const nameToQuery = `${name ? `&nome=${name}` : ''}`;

    try {
      const response = await api.get(`users?limit=${rowsPerPage}&skip=${paginationIndex}${nameToQuery}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result._id,
            name: result.nome,
            email: result.email,
            profileType: result?.role?.nome,
            company: "",
            status: result.status,
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],  
      totalItems,
    };
  }

  const getAllUsers = async (currentPage: number = 1) => {
    try {
      const { data } = await api.get(
        `users?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListUsers(
        data.data.data.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            email: result.email,
            profileType: result.role_id.nome,
            company: "",
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deleteUser = async (id: string) => {
    try {
      await api.delete(`users/${id}`);
      getAllUsers();
      setRefreshUserList(prev => !prev);
      successMessage("Usuário excluído com sucesso!");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    userData,
    listUsers,
    getUser,
    addUser,
    updateUser,
    deleteUser,
    getAllUsers,
    selectList,
    getAllProfileType,
    loadingData,
    getAllUserList,
    refreshUserList,
    getCurrentUser
  };
}
