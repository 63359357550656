import {FC, useEffect, useState} from 'react'
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";

import {Container, useStyles} from './styles'
import { useUsers } from '../../hooks/Users/useUsers'
import { LOCAL_STORAGE_KEY } from '../../consts';
import { UpdatePassword } from './updatePassword';

const Profile: FC = () => {
  const {getCurrentUser, userData} = useUsers()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const isClient = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE)

  useEffect(() => {
    getCurrentUser()
  }, [])

  const classes = useStyles();
  return (
    <Container>
      <UpdatePassword
        isModalOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        userID={userData?.id || ''}
        isClient={!!(isClient && JSON.parse(isClient) === 'Cliente')}
      />
      <Paper className={classes.paper}>
      <Typography 
        style={{ 
          color: "#5A5A5A", 
          fontSize: 24, 
          fontWeight: 600,
          marginBottom: 20
        }}>
        Perfil
      </Typography>

      <Divider/>

      <div style={{ marginBottom: 20 }}>
        <Grid item lg={3}>
          <InputLabel className={classes.inputLabel}>
            Nome
          </InputLabel>
          <TextField
            name="id"
            value={userData?.name}
            placeholder="0000"
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item lg={3}>
          <InputLabel className={classes.inputLabel}>
            {isClient && JSON.parse(isClient) === 'Cliente' ? 'CNPJ' : 'CPF'}
          </InputLabel>
          <TextField
            name="id"
            value={isClient && JSON.parse(isClient) === 'Cliente' ? userData?.cnpj : userData?.cpf}
            placeholder="0000"
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item lg={3}>
          <InputLabel className={classes.inputLabel}>
            Email
          </InputLabel>
          <TextField
            name="id"
            value={userData?.email}
            placeholder="0000"
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item lg={3}>
          <InputLabel className={classes.inputLabel}>
            Telefone
          </InputLabel>
          <TextField
            name="id"
            value={userData?.phone}
            placeholder="0000"
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>
      </div>

      <Button 
        className={classes.buttonSave}
        onClick={() => setModalIsOpen(true)}
        disabled={!userData?.id}
      >
        Atualizar Senha
      </Button>
      </Paper>
    </Container>
  )
}

export default Profile