import React, {useState, useEffect, FC, ReactNode} from 'react';
import { MdDelete, MdEdit, MdOutlineCancel } from "react-icons/md" 

import {chooseRowBgColor} from '../utils';
import {
  Container,
  Header,
  Item,
  HeaderIconSortWrapper,
} from './styles';
import { ModalConfirm } from '../../Modals/ConfirmModal';
import { CustomCancelModalType } from '..';

export interface GenercTableBodyProps {
  quantity: number,
  data: any[],
  header: {key: string, label: string, asButton?: boolean,  sorted?: string}[],
  itemsColor: string,
  headerBgColor: string,
  headerColor: string,
  bodyItemsColors: string[],
  enableBorderToHeader: boolean,
  isLoading: boolean,
  handleSort: (data: {sortBy: string; order: string}) => void,
  pagination: any,
  handleEdit?: (item: any) => void,
  handleDelete?: (item: any) => void,
  handleCancel?: (item: any) => void,
  disableHandleCancel?: boolean
  onClick?: (item: any) => void
  hideActions?: boolean
  customAction?: (item: any) => ReactNode
  customCancelModal?: CustomCancelModalType
}  

interface ModalFor {
  idFor: string,
  description: string,
  title: string,
  selectedItem: any,
  handleFunction: (item: any) => void
}

const GenercTableBody: FC<GenercTableBodyProps> = (
  {
    quantity,
    data,
    header,
    itemsColor,
    headerBgColor,
    headerColor,
    bodyItemsColors,
    enableBorderToHeader,
    isLoading,
    handleSort,
    pagination,
    handleEdit,
    handleDelete,
    handleCancel,
    onClick,
    hideActions,
    customAction,
    disableHandleCancel,
    customCancelModal
  }) => {
  const [sortBy, setSortBy] = useState<string>();
  const [order, setOrder] = useState('asc');
  const [openModal, setModalOpen] = useState(false);
  const [isModalFor, setIsModalFor] = useState<ModalFor>({
    idFor: "",
    description: "",
    title: "",
    selectedItem: {},
    handleFunction: () => null
  });

  const onClickOnHeader = (sorted?: string) => {
    if (!sorted) {
      return;
    }

    if (sortBy && sortBy === sorted) {
      setOrder(prev => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setOrder('asc');
      setSortBy(sorted);
    }
  };

  useEffect(() => {
    if (sortBy) {
      handleSort({
        sortBy,
        order,
      });
    }
  }, [sortBy, order]);

  return (
    <>
    <Container quantity={quantity}>
      {header.map((item) => (
        <Header
          key={item.key}
          color={headerColor}
          headerBgColor={headerBgColor}
          headerColor={headerColor}
          enableBorderToHeader={enableBorderToHeader}
        >
          <p
            onClick={() => onClickOnHeader(item?.sorted)}
            style={{
              cursor: item?.sorted ? 'pointer' : '',
            }}
          >
            {item?.label}
            {item?.sorted &&
            (
              <HeaderIconSortWrapper
                show={sortBy === item?.sorted}
                down={sortBy === item?.sorted && order === 'desc'}
              >
              </HeaderIconSortWrapper>
            )}
          </p>
        </Header>
      ))}

      {!isLoading && data.map((item, rowIndex) => {

        return header.map(({key, asButton}, index) => {
          const highlighted = item?.highlighted;


          return (
            <Item
              onClick={() => {
                onClick && onClick(item)
              }}
              theLast={rowIndex === data.length - 1 && !pagination}
              className='GenercTableBodyItem'
              color={itemsColor}
              key={rowIndex + index}
              bgColor={chooseRowBgColor(rowIndex, highlighted && '#2f4d7c', bodyItemsColors) as string}
              style={{cursor: item?.onClick ? 'pointer' : ''}}
            >
              {!(item[key] === "actions") && !asButton && item[key]}
              {asButton && (
                <div className='actions' onClick={e =>  e.stopPropagation()}>
                  {item[key]}
                </div>
              )}
              {(item[key] === "actions") && (
                <div className='actions'>
                  {!hideActions && handleEdit && <MdEdit color="#4f4f4f" size={20} onClick={(e: any) => {
                    e.stopPropagation();
                    handleEdit(item)
                  }} />}
                  {!hideActions && handleCancel && <MdOutlineCancel color="#4f4f4f" size={20} onClick={(e: any) => {
                     e.stopPropagation();
                     setModalOpen(true);
                     setIsModalFor({
                      idFor: "cancel",
                      description: `Tem a certeza de que deseja ${item.status === "Cancelada" ? "descancelar" : "cancelar"}  esta OS?`,
                      title: "Cancelar",
                      selectedItem: item,
                      handleFunction: handleCancel
                     })
                  }} />}
                  {!hideActions && handleDelete && <MdDelete color="#4f4f4f" size={20} onClick={(e: any) => {
                     e.stopPropagation();
                     setModalOpen(true);
                     setIsModalFor({
                      idFor: "delete",
                      description: "Tem a certeza de que deseja deletar este item?",
                      title: "Deletar",
                      selectedItem: item,
                      handleFunction: handleDelete
                     })
                  }} />}
                  {(!hideActions && customAction) ? customAction({item}) : null}
                </div>
              )}
            </Item>
          );
        });

      })}

    </Container>
    <ModalConfirm 
      onOK={() => {
        let fnc = isModalFor.handleFunction
        if(isModalFor.idFor === "cancel" && disableHandleCancel) {
          return
        }
        fnc(isModalFor.selectedItem)
        setModalOpen(false)
      }}
      closeModal={() => setModalOpen(false)}
      isModalOpen={openModal && !(customCancelModal && isModalFor.idFor === "cancel")}
      description={isModalFor.description}
      disableButton={isModalFor.idFor === "cancel" && disableHandleCancel}
    >
      
    </ModalConfirm>
    {(isModalFor.idFor === "cancel" && customCancelModal) && 
      customCancelModal({
        isModalOpen: openModal,  
        closeModal:() => setModalOpen(false),
        selectedItem: isModalFor.selectedItem
      })
    }
    </>
  );
};

export default GenercTableBody;


