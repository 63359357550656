import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../components/Messages";
import { api } from "../services/api";
import { TypeLoad } from "../types/managementServiceOrderTypes";
import { AxiosError } from "axios";

type LoadType = {
  id: string;
  name: string;
  status: boolean;
};

const SKIPLIMIT = 10;

export function useTypeLoad() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [typeLoadData, setTypeLoadData] = useState<TypeLoad>({
    id: "",
    name: "",
    status: "Ativo",
  });

  const getTypeLoad = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await api.get(`cargas/${id}`);
      setTypeLoadData({
        id: data.data._id,
        name: data.data.nome,
        status: data.data.status ? "Ativo" : "Inativo",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addTypeLoad = async (typeLoadData: TypeLoad) => {
    setLoading(true);
    try {
      await api.post("cargas", {
        nome: typeLoadData.name,
        status: typeLoadData.status === "Ativo",
      });
      navigate("/load-type");
      successMessage("Tipo de carga adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error.response.data.message[0]) {
        errorMessage(error.response.data.message[0])
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível adicionar tipo de carga");
      setLoading(false);
    }
  };

  const updateTypeLoad = async (typeLoadData: TypeLoad) => {
    setLoading(true);
    try {
      await api.patch(`cargas/${id}`, {
        nome: typeLoadData.name,
        status: typeLoadData.status === "Ativo",
      });
      navigate("/load-type");
      successMessage("Tipo de carga atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar tipo de carga");
      setLoading(false);
    }
  };

  const deleteTypeLoad = async (id: string) => {
    try {
      await api.delete(`cargas/${id}`);
      getAllTypeLoad();
      setRefresh(prev => !prev)
      successMessage("Tipo de carga excluído com sucesso!");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [listTypeLoad, setListTypeLoad] = useState<LoadType[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllTypeLoad(value);
  };


  interface GetAllTypeOfLoad {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number;
  }

  const getAllTypeOfLoad = async <T>({name, paginationIndex, rowsPerPage = 10} : GetAllTypeOfLoad) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)


    const nameToQuery = `${name ? `&nome=${name}` : ''}`;

    try {
      const response = await api.get(`cargas?limit=${rowsPerPage}&skip=${paginationIndex}${nameToQuery}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result._id,
            name: result.nome,
            status: result.status,
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],
      loading,  
      totalItems,
    };
  }

  const getAllTypeLoad = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `cargas?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListTypeLoad(
        data.data.results.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    typeLoadData,
    listTypeLoad,
    getTypeLoad,
    addTypeLoad,
    updateTypeLoad,
    deleteTypeLoad,
    getAllTypeLoad,
    getAllTypeOfLoad,
    loadingData,
    refresh
  };
}
