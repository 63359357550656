import { useState, useEffect } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

import { chooseStausColorAndLabel, printLocationInfo } from "../../../../../utils";
import { useServiceOrder } from "../../../../../hooks/useServiceOrder";
import GenericTable from "../../../../../components/GenericTable";
import GenericExport from "../../../../../components/GenericExport";

type Occurrency = {
  id: string;
  name: string;
  type: string;
  photosQuantity: string;
  status: boolean;
};


const header = [
  {key: "name", label: "Tipo"},
  {key: "startDate", label: "Data/Hora inicial"},
  {key: "endDate", label: "Data/Hora de término"},
  {key: "description", label: "Descrição do motorista"},
  {key: "location", label: "Localização"},
  {key: 'status', label: 'Status'},
]
const tableHeaderToExport = [
  {key: "nome", label: "Tipo"},
  {key: "createdAt", label: "Data/Hora inicial"},
  {key: "end_date", label: "Data/Hora de término"},
  {key: "descricao_ocorrencia", label: "Descrição"},
  {key: "descricao_motorista", label: "Descrição do motorista"},
  {key: "localizacao", label: "Localização"},
  {key: 'status', label: 'Status'},
]

export const TabOccurrences = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<any>({});
  const [loadingData, setLoadingData] = useState(true)
  const [total, setTotal] = useState(0);

  const { getAllOccurrences } = useServiceOrder();

  const filterToExport = {
    os_id: id,
  }

  const getOccurrences = async () => {
    const {data: respose, loading, total: totalItems} = await getAllOccurrences({
      paginationIndex: index + 1,
      rowsPerPage,
      id: id  || ""
    })

    setData(respose.map((item: any) => ({
      ...item,
      location: item?.location ? printLocationInfo(item?.location) : "",
      status: (
        <Typography
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            width: 200,
            padding: "4px 0px",
            borderRadius: "4px",

            fontSize: 12,
            color: "#252525",
            opacity: "0.8",
            backgroundColor: chooseStausColorAndLabel(item?.status).color
           }} 
        >
         {chooseStausColorAndLabel(item?.status).label}
        </Typography> 
        )
    })))
    setLoadingData(loading)
    setTotal(totalItems)
  }

  useEffect(() => {
    if (id) {
      getOccurrences()
    }
  }, [id]);

  return (
    <Grid item lg={12}>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: 20 }}>
        <GenericExport
          headerToRows={tableHeaderToExport}
          endpoint="os-ocorrencias"
          title="Exportar lista das Ocorrências da OS"  
          filter={filterToExport}
        />
      </div>
      <GenericTable
        data={data || []}
        isLoading={loadingData}
        header={header}
        total={total}
        onChangeIndex={(index) =>  setIndex(index)}
        onChangeRowsPerPage={(value) => setRowsPerPage(value)}
        noContentText="Sem ocorrências para mostrar"
        onClick={(item) => {
          navigate({
            pathname: `/service-order/occurrency/${id}`,
            search: createSearchParams({
              occurrence: item?.occurrencyId,
              order: item?.id,
          } as any).toString()
          })
        }}
      />
    </Grid>
  );
};
