import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../components/Messages";
import { api } from "../services/api";
import { Status } from "../types/managementServiceOrderTypes";

type StatusType = {
  id: string;
  name: string;
  status: boolean;
};

const SKIPLIMIT = 10;

export function useTypeStatus() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [statusData, setStatusData] = useState<Status>({
    id: "",
    name: "",
    status: "Ativo",
  });

  const getTypeStatus = async (id: string) => {
    try {
      const { data } = await api.get(`ordem-servico-status/${id}`);
      setStatusData({
        id: data.data._id,
        name: data.data.nome,
        status: data.data.status ? "Ativo" : "Inativo",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addTypeStatus = async (statusData: Status) => {
    try {
      await api.post("ordem-servico-status", {
        nome: statusData.name,
        status: statusData.status === "Ativo",
      });
      navigate("/status");
      successMessage("Status adicionado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível adicionar status");
      setLoading(false);
    }
  };

  const updateTypeStatus = async (statusData: Status) => {
    // console.log('statusData.status === "Ativo"', statusData.status === "Ativo");
    try {
      const { data } = await api.patch(`ordem-servico-status/${id}`, {
        nome: statusData.name,
        status: statusData.status === "Ativo",
      });
      // console.log("data", data);
      navigate("/status");
      successMessage("Status atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar status");
      setLoading(false);
    }
  };

  const deleteTypeStatus = async (id: string) => {
    try {
      await api.delete(`ordem-servico-status/${id}`);
      getAllTypeStatus();
      successMessage("Status excluído com sucesso!");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [listTypeStatus, setListTypeStatus] = useState<StatusType[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllTypeStatus(value);
  };

  const getAllTypeStatus = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `ordem-servico-status?skip=${
          SKIPLIMIT * (currentPage - 1)
        }&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListTypeStatus(
        data.data.results.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    statusData,
    listTypeStatus,
    getTypeStatus,
    addTypeStatus,
    updateTypeStatus,
    deleteTypeStatus,
    getAllTypeStatus,
  };
}
