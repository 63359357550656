import { Switch } from "@material-ui/core";
import { makeStyles, createStyles, Theme, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
      fontSize: 18,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    imageTitle: {
      color: "#4F4F4F",
      fontWeight: 500,
      fontSize: 18,
    },
    imageSubtitle: {
      color: "#BCBCBC",
      fontSize: 16,
    },
    switchContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    switchText: {
      color: "#1D1D1D",
      marginLeft: theme.spacing(1),
      marginRight: 10,
    },
    vehicleFormBtnText: {
      color: '#2F4D7C',
      fontWeight: 700,
      fontSize: 16,
      marginBottom: 10,
      cursor: 'pointer',
      "&:hover": {
        opacity: 0.8
      },
    }
  })
);

export const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#34B870",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#F66969",
    },
    checked: {},
  })
)(Switch);

