import {useState} from "react";
import { errorMessage, successMessage, warningMessage } from "../components/Messages";
import { api } from "../services/api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { onlyNumbers } from "../utils";

interface GetAllAddress {
  cep?: string;
  paginationIndex?: number;
  rowsPerPage?: number;
}

export interface Address {
  CEP: string,
  bairro:string,
  cidade: string,
  createdAt?: string,
  estado: string,
  logradouro: string,
  numero: string,
  updatedAt?: string,
  _v?: number,
  _id?: string,
  complemento?: string
}

interface ABrasilAbertoResult {
  city: string;
  cityId: number;
  complement: string;
  district:  string;
  districtId: number;
  ibgeId:number;
  state: string;
  stateShortname:string;
  street: string;
  zipcode: string
}

const transformObjectToReturn = (data: Address[]) => {
  return data.map(item => {
    return {
      id: item?._id,
      CEP: item?.CEP,
      bairro: item?.bairro,
      estado: item?.estado,
      numero: item?.numero,
      logradouro: item?.logradouro,
      cidade: item?.cidade,
      complemento: item?.complemento
    }
  })
}

const useAddress = () => {
  const [loadingList, setLoadingList] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const navigate = useNavigate();
  let result: Address[] = [];
  const [refreshList, setRefreshList] = useState(true);
  const [address, setAddress] = useState({
    CEP: '',
    bairro: '',
    estado: '',
    numero: '',
    logradouro: '',
    cidade: '',
    complemento: '',
  })

  const getAllAddress = async (props: GetAllAddress) => {
    const {
      cep, 
      paginationIndex, 
      rowsPerPage
    } = props;

    let totalItems = 0;
    setLoadingList(true)

    const cepToQuery = `${cep ? `&CEP=${cep}` : ''}`;
    const limit = `${rowsPerPage ? `&limit=${rowsPerPage}` : ''}`;
    const page = `${paginationIndex !== undefined && paginationIndex >= 0 ? `?skip=${paginationIndex}` : ''}`;

    try {
      const response = await api.get(`enderecos${page}${cepToQuery}${limit}`);

      if(response.status === 200) {
        result = transformObjectToReturn(response?.data?.items) as any;
        totalItems = response?.data?.totalItems
      }
      setLoadingList(false)
      
    } catch (error: any) {
      const response = error?.response;
      setLoadingList(false)
      if(response?.status !== 400) {
        errorMessage("Erro ao carregar endereços")
      }
    }

    return {
      totalItems,
      data: result
    }
  }

  const getAddress = async (id: string) => {
    setLoadingAction(true);
    try {
      const response = await api.get(`enderecos/${id}`);
      setLoadingAction(false);
      setAddress(response?.data)
    } catch (error) {
      setLoadingAction(false);
      errorMessage("Erro ao carregar endereço")
    }
  }

  const deleteAddress = async (id: string) => {
    setLoadingAction(true);
    try {
      await api.delete(`enderecos/${id}`);
      setLoadingAction(false);
      setRefreshList(prev => !prev);
      successMessage("Endereço excluído com sucesso!");
    } catch (error) {
      console.log(error);
      setLoadingAction(false);
      errorMessage("Erro ao deletar endereço")
    }
  };

  const addAddress =  async (address: Address) => {
    setLoadingAction(true);
    try {
      await api.post('enderecos', address);
      setLoadingAction(false);
      setRefreshList(prev => !prev);
      successMessage("Endereço adicionado com sucesso!");
      navigate("/adresses");
    } catch (error) {
      console.log(error);
      setLoadingAction(false);
      errorMessage("Erro ao tentar adicionar endereço")
    }
  }

  const updateAddress =  async (id: string, address: Address) => {
    setLoadingAction(true);
    try {
      await api.patch(`enderecos/${id}`, address);
      setLoadingAction(false);
      setRefreshList(prev => !prev);
      successMessage("Endereço atualizado com sucesso!");
      //navigate("/adresses");
    } catch (error) {
      console.log(error);
      setLoadingAction(false);
      errorMessage("Erro ao tentar atualizar endereço")
    }
  }

  const getAddressFromCEP = async (cep: string) => {
    let result  = {} as ABrasilAbertoResult
    setLoadingAddress(true);
    try {
      const response = await axios.get(`https://brasilaberto.com/api/v1/zipcode/${onlyNumbers(cep)}`)

      result  = response.data?.result as ABrasilAbertoResult;

      if(result.zipcode) {
        setAddress({
          CEP: result?.zipcode || "",
          bairro: result?.district || "",
          estado: result?.state || "",
          numero: "",
          logradouro: result?.street || "",
          cidade: result?.city || "",
          complemento: result?.complement || ""
        })
      }
      
    } catch (error: any) {
      const resp = error?.response
      if(resp.status === 404) {
        return warningMessage("Não foi possível carregar informações pelo CEP, certifca-se de que esteja correto ou insira os outros campos manualmente ")
      }
      errorMessage("Erro ao tentar buscar informações pelo CEP")
    } finally {
      setLoadingAddress(false);
    }

    return result
  }
  
  return {
    loadingList,
    getAllAddress,
    refreshList,
    deleteAddress,
    loadingAction,
    addAddress,
    updateAddress,
    address,
    getAddress,
    getAddressFromCEP,
    loadingAddress
  }
}

export {useAddress}