import Popover from '@material-ui/core/Popover';
//import BackIcon from 'assets/icons/backIcon';
import { IoIosArrowForward, IoIosArrowBack, IoIosArrowDown } from "react-icons/io" 
import React, { FC } from 'react';

import {
  Container,
  RightButton,
  ButtonLeft,
  PaginationInfo,
  RowsPerPageButton,
  RowsPerPageOptions,
} from './styles';

export interface FooterProps {
  color: string,
  bgColor: string,
  total: number,
  activeRowsPerPage: number,
  rowsPerPageOptions: number[],
  changeActiveRowsPerPage: (rowsPerPage: number) => void,
  handleActiveIndex: (isActive: boolean) => void,
  activeIndex: number,
  isLoading: boolean,
  disablePrev: boolean,
  disableNext: boolean
}

const Footer: FC<FooterProps> = ({
  color,
  bgColor,
  total,
  activeRowsPerPage,
  rowsPerPageOptions,
  changeActiveRowsPerPage,
  handleActiveIndex,
  activeIndex,
  isLoading,
  disableNext, 
  disablePrev
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    handleClose();
    changeActiveRowsPerPage(rowsPerPage);
  };

  const to = Math.min(activeRowsPerPage * activeIndex + activeRowsPerPage, total);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Container color={color} bgColor={bgColor}>
      <PaginationInfo>
        Linhas por página:
        <RowsPerPageButton color={color} onClick={handleClick} type='button'>
          {activeRowsPerPage}
          <IoIosArrowDown color={color} size={10}/>

        </RowsPerPageButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <RowsPerPageOptions bgColor={bgColor}>
            {rowsPerPageOptions.map(item => (
              <span
                key={item}
                onClick={() => handleChangeRowsPerPage(item)}
              >
                {item}
              </span>
            ))}
          </RowsPerPageOptions>
        </Popover>
      </PaginationInfo>
      <PaginationInfo>
        {(activeRowsPerPage * activeIndex) || 1} - {to} de {total}
      </PaginationInfo>

      <ButtonLeft type='button' onClick={() => handleActiveIndex(false)} disabled={isLoading || disablePrev}>
        <IoIosArrowBack color={color} size={12}/>
      </ButtonLeft>

      <RightButton type='button' onClick={() => handleActiveIndex(true)} disabled={isLoading || disableNext}>
        <IoIosArrowForward color={color} size={12}/>
      </RightButton>
    </Container>
  );
};

export default Footer;
