import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    searchBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",

      padding: 16,
    },
    formControl: {
      minWidth: 100,
    },
    select: {
      maxHeight: 40, 
      display: "flex", 
      alignItems: "center", 
      borderTopLeftRadius: 0, 
      borderBottomLeftRadius: 0,
      marginLeft: -10,
      background: '#fff'
    },
    searchBarInputsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    searchBarInput: {
      width: 250,
      borderWidth: 3,
      borderTopRightRadius: 0, 
      borderBottomRightRadius: 0,
      borderRadius: '0'
      //marginRight: 12,
    },
    searchBarFilter: {
      width: 250,
    },
    searchBarActionButtonsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonDelete: {
      borderRadius: "20%",
      color: "#D3D3D3",
      backgroundColor: "#FFF",
      border: "1px solid #D3D3D3",
      transition: ".5 ease",
      "&:hover": {
        backgroundColor: "#FF9395",
      },
    },
    buttonFilter: {
      marginLeft: 12,
      borderRadius: "20%",
      color: "#FFF",
      backgroundColor: "#2F4D7C",
      transition: ".5 ease",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
    },
    buttonExport: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      marginLeft: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#76A1E8",
      "&:hover": {
        backgroundColor: "#6991CF",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonRegister: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      marginLeft: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
  })
);
