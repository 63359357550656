export const imageDocuments = ["crlv", "antt", "tachograph"];
export const imageVehicle = [
  "frontBumper",
  "rearBumper",
  "headlight",
  "rearLight",
  "motor",
  "horseUnitFloor",
  "fuelTankDriver",
  "fuelTankPassenger",
  "cabin",
  "airBag",
  "transmissionShaft",
  "fifthWheel",
  "pneumaticConnections",
  "exhaustSystem",
  "chassisHorse",
  "chassisTrailer",
];
export const imageTires = [
  "driverDirectionalTire",
  "passengerDirectionalTire",
  "driverSide1stAxleTireInside",
  "driverSide1stAxleTireOutside",
  "passengerSide1stAxleTireInside",
  "passengerSide1stAxleTireOutside",
  "driverSide2ndAxleTireInside",
  "driverSide2ndAxleTireOutside",
  "passengerSide2ndAxleTireInside",
  "passengerSide2ndAxleTireOutside",
  "driverSide1stTrailerAxleTireInside",
  "driverSide1stTrailerAxleTireOutside",
  "passengerSide1stTrailerAxleTireInside",
  "passengerSide1stTrailerAxleTireOutside",
  "driverSide2ndTrailerAxleTireInside",
  "driverSide2ndTrailerAxleTireOutside",
  "passengerSideTrailer2ndAxleTireInside",
  "passengerSideTrailer2ndAxleTireOutside",
  "driverSide3rdAxleTrailerTireInside",
  "driverSide3rdAxleTrailerTireOutside",
  "passengerSideTrailer3rdAxleTireInside",
  "passengerSideTrailer3rdAxleTireOutside",
];