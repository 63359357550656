import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
  InputAdornment
} from "@material-ui/core";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { useClients } from "../../../../hooks/Users/useClients";
import { InputMask } from "../../../../components/InputMask";
import { Loading } from "../../../../components/Loading";
import { inCreationOrEditing, onlyNumbers, tranformStringIntoZipCode } from "../../../../utils";
import { useStyles, CustomSwitch } from "./styles";
import { asyncCheckCNPJ } from "../services/checkCNPJ";
import { useAddress, Address as AddressProps } from "../../../../hooks/useAddress";

const listStatus = ["Ativo", "Inativo", "Em Análise"];
const listSendEmail = ["Sim", "Não"];

export const ClientForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const {
    loading,
    setLoading,
    clientData,
    getClient,
    addClient,
    updateClient,
  } = useClients();

   const {
    getAllAddress,
    getAddressFromCEP,
    loadingAddress,
    address
  } = useAddress()

  const [cnpjExists, setCnpjExists] = useState(false)
  const [loadingCNPJ, setLoadingCNPJ] = useState(false)
  const [selectedCEP, setSelectedCEP] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<AddressProps>({} as AddressProps);
  const [listOfAdresses, setListOfAdresses] = useState<AddressProps[]>([]);

  const getAllAdresses = async () => {
    const {data} = await  getAllAddress({});

    if(data) {
      setListOfAdresses(data as any)
    }
  }

   const handleSearchAddressFromZipCode = async(cep: string) => {
    if(cep.length >= 8) {
       await getAddressFromCEP(cep);
    }
  }

  useEffect(() => {
    getAllAdresses()
  }, []);

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar cliente";
    } else if (location.pathname.includes("edit")) {
      return "Editar cliente";
    } else {
      return "Cliente";
    }
  };

  useEffect(() => {
    if (id) {
      getClient(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  // const handleSelectAddress = (selectedCEP: string) => {
  //   const getItem = listOfAdresses?.find(item => item.CEP === selectedCEP)

  //   setSelectedAddress(getItem as AddressProps)
  // }

  useEffect(() => {
    if(address.CEP) {
      setSelectedAddress(address as AddressProps)
    }
  }, [address])

  useEffect(() => {
    if(clientData) {

      setSelectedAddress(clientData.endereco as AddressProps)
    }
  }, [clientData, listOfAdresses])

  return (
    <Formik
      enableReinitialize
      initialValues={clientData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addClient({...values, endereco: selectedAddress});
        } else {
          updateClient({...values, endereco: selectedAddress});
        }
      }}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>Clientes</Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/clients")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button className={classes.buttonSave} type="submit" disabled={cnpjExists || loadingCNPJ}>
                      {loading ? <Loading/> : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/clients")}
                    >
                      Descartar
                    </Button>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    {/* <Button
                      className={classes.buttonExport}
                      onClick={() => alert("Em Implementação!")}
                    >
                      Exportar
                    </Button> */}
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/clients/${id}/edit`)}
                    >
                      {loading ? <Loading/> : "Editar"}
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
              {loading ? (
                <Loading />
              ) : (
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados da Empresa
                      </Typography>
                    </Grid>

                    {!location.pathname.includes("new") ? (
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          Data de cadastro
                        </InputLabel>
                        <TextField
                          name="createdAt"
                          value={values.createdAt}
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Nome*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        CNPJ*
                      </InputLabel>
                      <TextField
                        name="cnpj"
                        value={values.cnpj}
                        className={cnpjExists ? classes.inputError : ""}
                        onChange={e => {
                          if(onlyNumbers(e.target.value).length !== 14){
                            return
                          }
                          handleChange(e);

                          setTimeout(async() => {
                            const isItTrue = await asyncCheckCNPJ(onlyNumbers(e.target.value), setLoadingCNPJ);
                            setCnpjExists(isItTrue)
                          }, 1000)
                        }}
                        placeholder="CNPJ*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                        InputProps={{
                          inputComponent: InputMask as any,
                        }}
                      />
                       {cnpjExists &&
                          <InputLabel className={classes.inputLabelError}>
                            Já existe um cliente com este CNPJ
                          </InputLabel>
                        }
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        E-mail 1*
                      </InputLabel>
                      <TextField
                        name="emailFirst"
                        value={values.emailFirst}
                        onChange={handleChange}
                        placeholder="E-mail 1"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        E-mail 2
                      </InputLabel>
                      <TextField
                        name="emailSecond"
                        value={values.emailSecond}
                        onChange={handleChange}
                        placeholder="E-mail 2"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Enviar Ocorrências por E-mail?
                      </InputLabel>
                      <TextField
                        name="sendEmail"
                        value={values.sendEmail}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {listSendEmail.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Telefone*
                      </InputLabel>
                      <TextField
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        placeholder="(99) 99999-9999"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                        required
                        InputProps={{
                          inputComponent: InputMask as any,
                        }}
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Status*
                      </InputLabel>
                      <TextField
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!inCreationOrEditing(location)}
                      >
                        {listStatus.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                <Grid item lg={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>
                  <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Endereço da Empresa
                      </Typography>
                    </Grid>
                  {/* <Grid item lg={3}>
                    <InputLabel className={classes.inputLabel}>CEP*</InputLabel>
                    <TextField
                      name="zipCode"
                      value={selectedCEP || values.endereco?.CEP}
                      onChange={e => {
                        setSelectedCEP(e.target.value)
                        //setSelectedVehicleIndex(0)
                        handleSelectAddress(e.target.value)
                      }}
                      placeholder="Selecionar"
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled={returnedTitlePage(location) === "Cliente"}
                      select
                      required
                    >
                      {listOfAdresses?.map(value => (
                        <MenuItem key={value?.CEP} value={value?.CEP}>
                          {tranformStringIntoZipCode(value?.CEP)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        CEP*
                      </InputLabel>
              
                      <TextField
                        name="CEP"
                        value={selectedCEP || values.endereco?.CEP}
                        onChange={e => {
                          //handleSelectAddress(e.target.value)
                          setSelectedCEP(e.target.value)
                          handleSearchAddressFromZipCode(onlyNumbers(e.target.value))
                        }}
                        placeholder="CEP"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location) || loadingAddress}
                        InputProps={{
                          inputComponent: InputMask as any,
                          endAdornment: (
                            <InputAdornment position="end">
                              {loadingAddress ? <Loading color="#606060"/> : null}
                            </InputAdornment>
                        )
                        }}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Bairro*
                      </InputLabel>
                      <TextField
                        name="bairro"
                        value={selectedAddress?.bairro}
                        placeholder="Bairro"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                          setSelectedAddress(prev => ({
                            ...prev,
                            bairro: e.target.value
                          }))
                        }}
                        required
                        disabled={!inCreationOrEditing(location) || loadingAddress}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Cidade*
                      </InputLabel>
                      <TextField
                        name="cidade"
                        value={selectedAddress?.cidade}
                        placeholder="Cidade"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                          setSelectedAddress(prev => ({
                            ...prev,
                            cidade: e.target.value
                          }))
                        }}
                        required
                        disabled={!inCreationOrEditing(location) || loadingAddress}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Estado*
                      </InputLabel>
                      <TextField
                        name="estado"
                        value={selectedAddress?.estado}
                        placeholder="Estado"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                          setSelectedAddress(prev => ({
                            ...prev,
                            estado: e.target.value
                          }))
                        }}
                        required
                        disabled={!inCreationOrEditing(location) || loadingAddress}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Logradouro*
                      </InputLabel>
                      <TextField
                        name="logradouro"
                        value={selectedAddress?.logradouro}
                        placeholder="Logradouro"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                          setSelectedAddress(prev => ({
                            ...prev,
                            logradouro: e.target.value
                          }))
                        }}
                        required
                        disabled={!inCreationOrEditing(location) || loadingAddress}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Número*
                      </InputLabel>
                      <TextField
                        name="numero"
                        value={selectedAddress?.numero}
                        placeholder="Número"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                          setSelectedAddress(prev => ({
                            ...prev,
                            numero: e.target.value
                          }))
                        }}
                        required
                        disabled={!inCreationOrEditing(location) || loadingAddress}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Complemento
                      </InputLabel>
                      <TextField
                        name="complemento"
                        value={selectedAddress?.complemento}
                        placeholder="Complemento"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                          setSelectedAddress(prev => ({
                            ...prev,
                            complemento: e.target.value
                          }))
                        }}
                        disabled={!inCreationOrEditing(location) || loadingAddress}
                      />
                    </Grid>
                  </Grid>
                </Paper>
            </Grid>

             <Grid item lg={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>

            <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Tipo de Cadastro
                      </Typography>
                    </Grid>
                    <Grid item lg={12}>
                      <div className={classes.switchContainer}>
                        <CustomSwitch
                          name="client"
                          checked={values.registerType.client}
                          onChange={handleChange("registerType.client")}
                          disabled={!inCreationOrEditing(location)}
                        />
                        <Typography className={classes.switchText}>
                          Cliente
                        </Typography>
                      </div>
                      <div className={classes.switchContainer}>
                        <CustomSwitch
                          name="sender"
                          checked={values.registerType.sender}
                          onChange={handleChange("registerType.sender")}
                          disabled={!inCreationOrEditing(location)}
                        />
                        <Typography className={classes.switchText}>
                          Remetente
                        </Typography>
                      </div>
                      <div className={classes.switchContainer}>
                        <CustomSwitch
                          name="destination"
                          checked={values.registerType.destination}
                          onChange={handleChange("registerType.destination")}
                          disabled={!inCreationOrEditing(location)}
                        />
                        <Typography className={classes.switchText}>
                          Destinatário
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
            </Grid>
        
                </Paper>
              )}
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
