// import NoContentImg from 'assets/no-content.svg';
import { TbMoodEmpty } from "react-icons/tb"
import React, { FC } from 'react';

import {Container} from './styles';

export interface NoContentProps {
  bgColor: string, 
  noContentText: string;
  color: string;
}

const NoContent: FC<NoContentProps> = ({bgColor, noContentText, color}) => {
  return (
    <Container bgColor={bgColor} color={color}>
      {/* <img alt='no-content' src={NoContentImg} /> */}
      <TbMoodEmpty color={color} size={60}/>
      <span>{noContentText}</span>
    </Container>
  );
};

export default NoContent;
