import { useEffect } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { useServiceOrder } from "../../../../../hooks/useServiceOrder";
import { Loading } from "../../../../../components/Loading";

import { useStyles } from "./styles";
import { ListRoutes } from "./ListRoutes";
import { ListVehicles } from "./ListVehicles";
import { ServiceOrder } from "../../../../../types/managementServiceOrderTypes";
import { SectionChecklist } from "./Checklist";
import { LOCAL_STORAGE_KEY } from "../../../../../consts";
import { SERVICE_ORDER_STATUS } from "../../../../../consts/serviceOrderStatus";

type StepOverviewDataProps = {
  setServiceOrderData: React.Dispatch<React.SetStateAction<ServiceOrder>>;
  serviceOrderData: ServiceOrder;
};

export const StepOverview = ({
  serviceOrderData,
}: StepOverviewDataProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { id } = useParams();
  const { loading, setLoading, getServiceOrder } = useServiceOrder();

  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
    
  const isAdmin = userRole?.toString()?.includes('Admin')
  
  useEffect(() => {
    if (id) {
      getServiceOrder(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [location]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={1} className={classes.formContainer}>
      <Grid item style={{width: "100%"}}>
        <Typography className={classes.overviewSectionTitle}>
          Dados da operação
        </Typography>
      </Grid>
      {!location.pathname.includes("new") ? (
        <>
          <Grid item style={{minWidth: 140}}>
            <Typography className={classes.overviewFieldTitle}>
              Número da ordem de serviço
            </Typography>
            <Typography>{serviceOrderData.osNumber}</Typography>
          </Grid>
          <Grid item style={{minWidth: 140}}>
            <Typography className={classes.overviewFieldTitle}>
              Emissão
            </Typography>
            <Typography>{serviceOrderData.emissionDate}</Typography>
          </Grid>
        </>
      ) : null}

      <Grid item style={{minWidth: 140}}>
        <Typography className={classes.overviewFieldTitle}>
          Tipo operação
        </Typography>
        <Typography>{serviceOrderData?.schedule?.name || ""}</Typography>
      </Grid>
      <Grid item style={{width: "100%"}}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>
      { isAdmin &&     
      <>
        <Grid item style={{width: "100%"}}>
          <Typography className={classes.overviewSectionTitle}>
            Dados do tipo de programação
          </Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Nome da programação
          </Typography>
          <Typography>{serviceOrderData.schedule.name}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>Via</Typography>
          <Typography>{serviceOrderData.schedule.via}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>Modal</Typography>
          <Typography>{serviceOrderData.schedule.modal}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Tipo de carga
          </Typography>
          <Typography>{serviceOrderData.schedule.typeLoad}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>Situação</Typography>
          <Typography>{serviceOrderData.schedule.situation}</Typography>
        </Grid>
        <Grid item style={{width: "100%"}}>
          <Divider style={{ margin: "16px 0" }} />
        </Grid>

        <Grid item style={{width: "100%"}}>
          <Typography className={classes.overviewSectionTitle}>
            Operação
          </Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            CTAC/DI/Booking
          </Typography>
          <Typography>{serviceOrderData.ctac}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Solicitante/Pagador
          </Typography>
          <Typography>{serviceOrderData.requester}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>Cliente</Typography>
          <Typography>{serviceOrderData.client.name}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Navio/Avião
          </Typography>
          <Typography>{serviceOrderData.shipAirplaneName}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Número do Container
          </Typography>
          <Typography>{serviceOrderData.containerNumber}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>Carga</Typography>
          <Typography>{serviceOrderData.load}</Typography>
        </Grid>
        <Grid item style={{width: "100%"}}>
          <Divider style={{ margin: "16px 0" }} />
        </Grid>

        <Grid item style={{width: "100%"}}>
          <Typography className={classes.overviewSectionTitle}>
            Observações
          </Typography>
          <Typography>{serviceOrderData.observation}</Typography>
        </Grid>
        <Grid item style={{width: "100%"}}>
          <Divider style={{ margin: "16px 0" }} />
        </Grid>
      </>
      }

      <ListRoutes routes={serviceOrderData.schedule.routes} />

      <Grid item style={{width: "100%"}}>
        <Typography className={classes.overviewSectionTitle}>
          Motorista e Veículos
        </Typography>
      </Grid>
      <Grid item style={{width: "100%"}}>
        <Typography className={classes.overviewSectionSubtitle}>
          Motorista
        </Typography>
      </Grid>
      <Grid item style={{minWidth: 140}}>
        <Typography className={classes.overviewFieldTitle}>Nome</Typography>
        <Typography>{serviceOrderData.driver.name}</Typography>
      </Grid>
     {isAdmin && (
      <>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>CPF</Typography>
          <Typography>{serviceOrderData.driver.cpf}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Telefone 1
          </Typography>
          <Typography>{serviceOrderData.driver.phoneFirst}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Telefone 2
          </Typography>
          <Typography>{serviceOrderData.driver.phoneSecond}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>E-mail</Typography>
          <Typography>{serviceOrderData.driver.email}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>Crachá</Typography>
          <Typography>{serviceOrderData.driver.badge}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>CNH</Typography>
          <Typography>{serviceOrderData.driver.cnhNumber}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Categoria
          </Typography>
          <Typography>{serviceOrderData.driver.cnhCategory}</Typography>
        </Grid>
        <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Validade da CNH
          </Typography>
          <Typography>{serviceOrderData.driver.cnhExpirationDate}</Typography>
        </Grid>
      </>
     )}

      <ListVehicles vehicles={serviceOrderData.vehicles} isAdmin={isAdmin} />

      {location.pathname.includes("new") && <SectionChecklist checklist={serviceOrderData.checklist} />}

      {serviceOrderData.status === SERVICE_ORDER_STATUS.CANCELLED && (

      <Grid item style={{minWidth: 140}}>
          <Typography className={classes.overviewFieldTitle}>
            Justificativa de Cancelamento
          </Typography>
          <Typography>
            {serviceOrderData?.cancelJustification}
          </Typography>
        </Grid>
      )}

    </Grid>
  );
};
