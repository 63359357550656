import { useEffect, useState, FC, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { TablePagination } from "../../components/TablePagination";
import { useServiceOrder } from "../../hooks/useServiceOrder";
import { TableActions } from "../../components/TableActions";
import { useStyles } from "./styles";
import { warningMessage } from "../../components/Messages";
import { api } from "../../services/api";
import { useTypeSchedule } from "../../hooks/useTypeSchedule";
import { formatTypeScheduleList, returnedColor, suportedStatus } from "./utils";
import { checkIfStringIsNumeric } from "../../utils";
import moment from "moment";
import CustomToggleDatePicker from "../../components/CustomToggleDatePicker";
import GenericTable from "../../components/GenericTable";
import TruckIcon from "./truckIcon";
import { ModalConfirm } from "../../components/Modals/ConfirmModal";
import GenericExport from "../../components/GenericExport";
import { LOCAL_STORAGE_KEY } from "../../consts";
import { CancelModal } from "./CancelModal";

const transformQueryIntoObject  = (queryString: string) => {
  if(!queryString) {
    return {
      start: null,
      end: null,
    }
  }

  let start = queryString.toString().slice(0, 10)
  let end = queryString.toString().slice(14, 24)

  return {
    start, 
    end
  }

}

const deleteFalseValueOnObject = (obj: object) => {
  let objToArr = Object.entries(obj)

  let justTruthValue = objToArr.filter(item => {
    return item[1]
  })

  return Object.fromEntries(justTruthValue)
}

const tableHeader = [
  {key: 'name', label: 'Ordem de Serviço'},
  {key: 'driver', label: 'Motorista'},
  {key: 'type', label: 'Tipo'},
  {key: 'emissao_data', label: 'Data de Emissão'},
  {key: "Coletado", label: "Coletado"},
  {key: "No cliente", label: "No Cliente"},
  {key: "Entregue", label: "Entregue"},
  {key: 'returnStatus', label: 'Status'},
];

const tableHeaderForClient = [
  tableHeader[0],
  tableHeader[1],
  tableHeader[3],
  tableHeader[7],
];

const tableHeaderToExport = [
  {key: 'numero', label: 'Número da OS'},
  {key: 'motorista_name', label: 'Motorista'},
  {key: 'motorista_cnh', label: 'CNH do Motorista'},
  {key: 'veiculo_name', label: 'Veículo'},
  {key: 'veiculo_placa', label: 'Placa do Veículo'},
  {key: 'programacao_name', label: 'Programação'},
  {key: 'checklist_name', label: 'Checklist'},
  {key: 'emissao_data', label: 'Data de Emissão'},
  {key: 'cliente_name', label: 'Cliente'},

  {key: 'navio_aviao', label: 'Navio/Avião'},
  {key: 'solicitante', label: 'Solicitante'},
  {key: 'ctac', label: 'CTAC/DI/Booking'},
  {key: 'observacao', label: 'Oservação'},
  {key: 'carga', label: 'Carga'},

  {key: 'status', label: 'Status'},
];

export const Home = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [allServiceOrders,  setAllServiceOrders] = useState<any>([])
  const [status, setStatus] = useState("Todos")
  const [numero, setNumero] = useState<string>("")
  const [programacao, setProgramacao] = useState("Todos")
  const [totalItems, setTotalItems] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get("page");
  const numeroQuery = searchParams.get("numero");
  const statusQuery = searchParams.get("status");
  const programacaoQuery = searchParams.get("programacao");
  const date = searchParams.get("emissao");
  const scheduleWeek = searchParams.get("semana-programacao");

  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
  
  const isAdmin = userRole?.toString()?.includes('Admin')

  const handleAddParamsToQuery = (param: string, value: string) => {
    const objectToQuery = {
      page,
      numero,
      status,
      programacao,
      emissao: date,
      'semana-programacao': scheduleWeek
    }

    let objectToUse: any = {}

    Object.entries(objectToQuery).forEach(([key, valuee]) => {
      if(key === param) {
        objectToUse[key] = value
        return
      }
      if(valuee) {
        if(key === param) {
          //objectToUse[key] = value
          return
        }

        if(param !== "page" && key === "page") {
          return
        }
        objectToUse[key] = valuee
      }
    })

    if(!value) {
      delete objectToUse[param]
    }

    setSearchParams(objectToUse);
  }

  const {
    deleteServiceOrder,
    getServiceOrders,
    loadingData,
    refreshServiceOrders,
    unCancelServiceOrder,
    loadingOnCancel,
    cancelServiceOrder
  } = useServiceOrder();

  const {
    listTypeSchedule,
    getAllTypeSchedule,
  } = useTypeSchedule();

  useEffect(() => {
    if(isAdmin){
      getAllTypeSchedule();
    }
  }, [isAdmin]);

  const filterToExport = {
    status: statusQuery === "Todos" ? "" : statusQuery,
    emissao_data: date && date.includes("to") ? null : date,
    numero: checkIfStringIsNumeric(numeroQuery || numero) ? numeroQuery || numero : undefined,
    programacao_id: programacaoQuery === "Todos" ? "" : programacaoQuery,
    motorista_name:  checkIfStringIsNumeric(numeroQuery || numero) ? undefined : numeroQuery || numero,
    from: date && date.includes("to") ? transformQueryIntoObject(date as string).start : "",
    to: date && date.includes("to") ? transformQueryIntoObject(date as string).end : "",
  }

  const getAllServiceOrders = async () => {
    const {data, totalItems} = await  getServiceOrders(
      {
        status: statusQuery ?  statusQuery : status, 
        programacao: programacaoQuery ? (programacaoQuery === "Todos" ? "" : programacaoQuery) : (programacao === "Todos" ? "" : programacao), 
        dateOfEmission: date && date.includes("to") ? null : date as any,
        numero: checkIfStringIsNumeric(numeroQuery || numero) ? numeroQuery || numero : undefined,
        motorista: checkIfStringIsNumeric(numeroQuery || numero) ? undefined : numeroQuery || numero,
        paginationIndex: page ? Number(page) - 1 : 0,
        rowsPerPage,
        scheduleWeek: (scheduleWeek as any) || undefined,
        range: date && date.includes("to") ? {
          from: transformQueryIntoObject(date as string).start,
          to: transformQueryIntoObject(date as string).end
        } :  {from: null, to: null}
      });


    setTotalItems(totalItems)
    setAllServiceOrders(data.map((serviceOrder: any) => ({
      ...serviceOrder,
      returnStatus: (
        <Typography
          className={classes.rowItemStatus}
          style={{
            backgroundColor: returnedColor(serviceOrder.status as string),
            minWidth: 200,
          }}
        >
          {serviceOrder.status as string}
        </Typography>
      ),
      "No cliente": <TruckIcon status={serviceOrder?.statusFromClassification["No cliente"]} />,
      Coletado: <TruckIcon status={serviceOrder?.statusFromClassification?.Coletado} />,
      Entregue: <TruckIcon status={serviceOrder?.statusFromClassification?.Entregue} />,
    })))
  }

  useEffect(() => {
   getAllServiceOrders()
  }, [
    status, 
    numero, 
    programacao, 
    refreshServiceOrders, 
    searchParams, 
    rowsPerPage, 
    scheduleWeek,
    loadingOnCancel
  ]);

  const handleViewServiceOrder = (paramsRow: any) => {
    navigate(`/service-order/${paramsRow.id}`);
  };
  const handleEditServiceOrder = (paramsRow: any) => {
    if (paramsRow.status === "Finalizada") {
      warningMessage("Não é possível editar uma OS finalizada!");
    } else {
      navigate(`/service-order/${paramsRow.id}/edit`);
    }
  };
  const handleDeleteServiceOrder = (paramsRow: any) => {
    deleteServiceOrder(paramsRow.id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={3}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Ordens de Serviço
        </Typography>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchBarInputsContainer}>
              <TextField
                className={classes.searchBarInput}
                placeholder="Pesquisar pela OS ou Motorista"
                variant="outlined"
                value={numeroQuery || numero}
                onChange={e => {
                  setNumero(e.target.value)
                  handleAddParamsToQuery("numero", e.target.value)
                }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  style={{maxHeight: 40, display: "flex", alignItems: "center"}}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={statusQuery || status}
                  defaultValue="Todos"
                  onChange={e => {
                    setStatus(e.target.value as any)
                    handleAddParamsToQuery("status", e.target.value as any)
                  }}
                  label="Status"
                >
                  <MenuItem value="Todos">
                    Todos
                  </MenuItem>
                  {suportedStatus.map(item => 
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              {isAdmin && 
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
                  <Select
                    style={{maxHeight: 40, display: "flex", alignItems: "center"}}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={programacaoQuery || programacao}
                    defaultValue="Todos"
                    onChange={e => {
                      setProgramacao(e.target.value as any)
                      handleAddParamsToQuery("programacao", e.target.value as any)
                    }}
                    label="Tipo"
                  >
                    <MenuItem value="Todos">
                      Todos
                    </MenuItem>
                    {formatTypeScheduleList(listTypeSchedule).map(item =>  
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                    )}
                  </Select>
                </FormControl>
              }

              <CustomToggleDatePicker
                label="Data de emissão"
                style={{marginLeft: 10}}
                value={date?.includes("to") ? null : date}
                rangeValues={date?.includes("to") ? transformQueryIntoObject(date as string) as any : undefined}
                onChangeDate={e => {
                  handleAddParamsToQuery("emissao", e ? moment(new Date(e)).format('YYYY-MM-DD') : null as unknown as string)
                }}
                onClear={() => handleAddParamsToQuery("emissao", null as unknown as string)}
                onChangeDateRange={e => {
                  handleAddParamsToQuery(
                    "emissao", 
                    `${moment(new Date(e?.start as unknown as string)).format('YYYY-MM-DD')}-to-${moment(new Date(e?.end as unknown as string)).format('YYYY-MM-DD')}`
                    )
                }}
              />

              <TextField
                className={classes.scheduleWeekInput}
                placeholder="Semana da programação"
                variant="outlined"
                type="number"
                value={scheduleWeek}
                onChange={e => {
                  handleAddParamsToQuery("semana-programacao", e.target.value)
                }}
                size="small"
              />

            </div>
            <div className={classes.searchBarActionButtonsContainer}>
              <GenericExport
                headerToRows={tableHeaderToExport}
                endpoint="os"
                filter={deleteFalseValueOnObject(filterToExport)}
                title="Exportar lista de OS"  
              />
              {isAdmin && 
                <Button
                  startIcon={<AddIcon />}
                  className={classes.buttonRegister}
                  onClick={() => navigate("/service-order/new")}
                >
                  Cadastrar
                </Button>
              }
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item lg={12}>
        <GenericTable
          header={isAdmin ?  tableHeader : tableHeaderForClient}
          data={allServiceOrders}
          isLoading={loadingData}
          hideActions={!isAdmin}
          handleDelete={(item) => handleDeleteServiceOrder(item)}
          handleEdit={(item) => handleEditServiceOrder(item)}
          onClick={(item) => handleViewServiceOrder(item)}
          noContentText="Sem Ordem de serviço para mostrar"
          handleCancel={(item) => {
            if(item?.status === "Cancelada") {
              unCancelServiceOrder(item?.id)
              return
            }
             
          }}
          total={totalItems}
          onChangeIndex={(index) =>  handleAddParamsToQuery("page", String(index + 1))}
          onChangeRowsPerPage={(value) => setRowsPerPage(value)}
          customCancelModal={(props) => <CancelModal {...props} loading={loadingOnCancel} cancelServiceOrder={cancelServiceOrder} />}
        />
      </Grid>

      {isAdmin && 
        <Grid item lg={12}>
          <Button
            className={classes.buttons}
            style={{ backgroundColor: "#6FED8B", color: "#fff" }}
            onClick={() => {
              setStatus("Finalizada")
              handleAddParamsToQuery("status", "Finalizada")
            }}
          >
            Finalizadas
          </Button>
          <Button
            className={classes.buttons}
            style={{ backgroundColor: "#FF3C40", color: "#fff", marginLeft: 10 }}
            onClick={() => {
              setStatus("Cancelada")
              handleAddParamsToQuery("status", "Cancelada")
            }}
          >
            Canceladas
          </Button>
        </Grid>
      }
    </Grid>
  );
};
