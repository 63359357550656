import { api } from "../../../services/api"
import { errorMessage } from "../../Messages";

export const getStatus: any = async (code: string) => {
  let resp = {
    status: 0,
    state: ""
  }

  try {
    const { status, data } = await api.get(`export/${code}/get-state`)

    if(status === 200) {
      resp.status = status;
      resp.state = data?.state as any
    }

    if (status === 200) {
      switch (data?.state) {
      case 'generated':
        resp.status = status;
        resp.state = data?.state as any

        break;
      case 'generating':
        await new Promise(resolve => setTimeout(resolve, 1000));
        return await getStatus(code);
      default:
        errorMessage("Erro ao verificar o status da exportação");
        throw new Error();
        break;
      }
    }


  } catch (error) {
    errorMessage("Erro ao verificar o status da exportação");
    console.log("Deu erro ao tentar pedir isso:", error)
  }

  return resp
}