import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
    },
    content: {
      display: "flex",
      height: 200,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    divSpace: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    tipText: {
      marginTop: 12,
      marginBottom: 24,
      color: "#2F4D7C",
      fontWeight: 600,
    },
    paper: {
      color: theme.palette.text.secondary,
      marginTop: 12,
    },
    loginContentContainer: {
      display: "flex",
      width: 400,
      padding: 24,
      flexDirection: "column",
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#2F4D7C",

      fontWeight: 600,
    },
    buttonLogin: {
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    resetPasswdText: {
      marginLeft: 12,
      fontSize: 14,
      color: "#2FA9DF",
      cursor: "pointer",
      "&:hover": {
        color: "rgba(47,169,223,0.8)",
      },
    },
    resetPasswdContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 12,
    },
  })
);
