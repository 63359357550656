import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { useTypeVehicle } from "../../../../hooks/useTypeVehicle";
import { Loading } from "../../../../components/Loading";
import { inCreationOrEditing } from "../../../../utils";
import { useStyles, ImagesWrapper } from "./styles";

import {
  imageDocuments,
  imageTires,
  imageVehicle
} from "./typeVehicleForm.utils"
import { ImageCheckbox } from "../../../../components/ImageCheckbox";

const listStatus = ["Ativo", "Inativo"];

export const TypeVehicleForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const {
    loading,
    setLoading,
    typeVehicleData,
    getTypeVehicle,
    addTypeVehicle,
    updateTypeVehicle,
    handleChangeImage
  } = useTypeVehicle();

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar tipo de veículo";
    } else if (location.pathname.includes("edit")) {
      return "Editar tipo de veículo";
    }
    return "Tipo de veículo";
  };

  useEffect(() => {
    if (id) {
      getTypeVehicle(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  return (
    <Formik
      enableReinitialize
      initialValues={typeVehicleData}
      onSubmit={(values) => {
        if (location.pathname.includes("new")) {
          addTypeVehicle(values);
        } else {
          updateTypeVehicle(values);
        }
      }}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>
                Tipos de Veículos
              </Typography>
            </Grid>

            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/vehicle-type")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button className={classes.buttonSave} type="submit">
                      {loading ? <Loading /> : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/vehicle-type")}
                    >
                      Descartar
                    </Button>
                  </div>
                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/vehicle-type/${id}/edit`)}
                    >
                      {loading ? <Loading/> : "Editar"}
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
              {loading ? (
                <Loading />
              ) : (
                <Paper className={classes.paper}>
                  <Grid container spacing={1} className={classes.formContainer}>
                    <>
                      <Grid item lg={12}>
                        <Typography className={classes.formSectionTitle}>
                          Dados do tipo de veículo
                        </Typography>
                      </Grid>

                      {!location.pathname.includes("new") ? (
                        <Grid item lg={3}>
                          <InputLabel className={classes.inputLabel}>
                            ID
                          </InputLabel>
                          <TextField
                            name="id"
                            value={values.id}
                            placeholder="0000"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      ) : null}
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          Nome*
                        </InputLabel>
                        <TextField
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          placeholder="Nome*"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          disabled={!inCreationOrEditing(location)}
                        />
                      </Grid>
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          Status
                        </InputLabel>
                        <TextField
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          placeholder="Selecionar"
                          variant="outlined"
                          size="small"
                          fullWidth
                          select
                          disabled={!inCreationOrEditing(location)}
                        >
                          {listStatus.map((status) => (
                            <MenuItem key={status} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  </Grid>

                  <ImagesWrapper>
                    <Typography className={classes.formSectionTitle}>
                      Dados do tipo de veículo
                    </Typography>
                    <Typography
                      className={classes.formSectionTitle}
                      style={{ color: "#2F4D7C", marginTop: 20 }}
                    >
                      Veículo
                    </Typography>
                    <div className="content">

                   
                    {typeVehicleData?.imageDocuments
                      .map((image: any, index) => (
                        <ImageCheckbox
                          image={image}
                          switchName={imageDocuments[index]}
                          switchChecked={image?.obrigatorio}
                          switchOnChange={() => {
                            let img = [...typeVehicleData.imageDocuments] as any;

                            img[index] = {
                              ...image,
                              obrigatorio: !(image as any)?.obrigatorio
                            }    

                            handleChangeImage("imageDocuments", img)
                          }}
                          disabled={false}
                        />
                      ))
                      .sort((image: any, nextImage: any) => {
                        return image.order - nextImage.order;
                      })}
                    </div>

                    <Typography
                      className={classes.formSectionTitle}
                      style={{ color: "#2F4D7C", marginTop: 20  }}
                    >
                      Fotos do veículo
                    </Typography>
                    <div className="content">
                      {typeVehicleData.imageVehicle
                        .map((image: any, index) => (
                          <ImageCheckbox
                            image={image}
                            switchName={imageVehicle[index]}
                            switchChecked={image?.obrigatorio}
                            switchOnChange={() => {
                              let img = [...typeVehicleData.imageVehicle] as any;

                              img[index] = {
                                ...image,
                                obrigatorio: !(image as any)?.obrigatorio
                              }

                              handleChangeImage("imageVehicle", img)
                            }}
                            disabled={false}
                          />
                        ))
                        .sort((image: any, nextImage: any) => {
                          return image.order - nextImage.order;
                      })}
                    </div>   

                    <Typography
                      className={classes.formSectionTitle}
                      style={{ color: "#2F4D7C", marginTop: 20  }}
                    >
                      Fotos dos pneus
                    </Typography> 
                      <div className="content">
                        {typeVehicleData.imageTires
                          .map((image: any, index) => (
                            <ImageCheckbox
                              image={image}
                              switchName={imageTires[index]}
                              switchChecked={image?.obrigatorio}
                              switchOnChange={() => {
                                let img = [...typeVehicleData.imageTires] as any;

                                img[index] = {
                                  ...image,
                                  obrigatorio: !(image as any)?.obrigatorio
                                }

                                handleChangeImage("imageTires", img)
                              }}
                              disabled={false}
                            />
                          ))
                          .sort((image: any, nextImage: any) => {
                            return image.order - nextImage.order;
                        })}
                      </div>
                  </ImagesWrapper>

                </Paper>
              )}
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
