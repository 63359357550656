import {FC} from 'react'
import {
  Clear
} from "@material-ui/icons/";
import { chooseErrorMessageFromCode, chooseImage, formatSize } from './utils'
import { FileError } from '.'

export type SelectedFileProps = {
  file?: File,
  error?: FileError
  onDeleteFile?: () => void
}

const SelectedFile: FC<SelectedFileProps> = ({ file, error, onDeleteFile }) => {
  
  if(!file) {
    return <></>
  }

  return (
    <div style={{
      width: '100%',
      margin: '16px 0',
      padding: 10,
      borderRadius: 8,
      backgroundColor: error?.code ? '#FAC2C7' : '#F3F6FD',
      display: 'flex',
      alignItems: 'center'
    }}>
      
      {chooseImage(file.type)}

      <div
        style={{ 
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 8, 
          flex: 1,
        }}
      >
        <p style={{ fontSize: 12, font: 'Barlow, sans-serif', margin: 0 }}>
          {file?.name}
        </p>
        <p style={{ fontSize: 12, font: 'Barlow, sans-serif', margin: 0 }}>
          {file?.type}
          <strong 
            style={{
              color: '#FA0C22',
              marginLeft: 8,
              display: error?.code === 'file-invalid-type' ? 'inline' : 'none'
            }}
          >
            {chooseErrorMessageFromCode(error?.code || '')}
          </strong>
        </p>
        <p style={{ fontSize: 12, font: 'Barlow, sans-serif', margin: 0 }}>
          {formatSize(file?.size).size}{formatSize(file.size).unit}
          <strong 
            style={{
              color: '#FA0C22',
              marginLeft: 8,
              display: error?.code === 'file-too-large' ? 'inline' : 'none'
            }}
          >
            {chooseErrorMessageFromCode(error?.code || '')}
          </strong>
        </p>

      </div>
      <div style={{ cursor: 'pointer' }} onClick={() => onDeleteFile && onDeleteFile()}>
        <Clear/>
      </div>
    </div>
  )
}

export default SelectedFile