import {FC, useState} from 'react'
import { Button, Typography, Dialog, DialogContent } from "@material-ui/core";
import { DEFAULT_VEHICLE_FORM_CHECKLIST_OPTIONS } from '../../../consts/defaultVehicleFormChecklistOptions';
import VehicleFormChecklistModalOption from './optionComponent';
import { useStyles } from './styles';
import { ServiceOrder } from '../../../types/managementServiceOrderTypes';
import { VehicleFormChecklistModalOptionProps } from '../../../types/vehicleFormChecklist';

type VehicleFormChecklistModalProps = {
  openModal: boolean, 
  handleClose: () => void
  setServiceOrderData: React.Dispatch<React.SetStateAction<ServiceOrder>>;
  serviceOrderData: ServiceOrder;
  viewOnly?: boolean
}

export type HandleChangeList = 
(id: string, field: keyof VehicleFormChecklistModalOptionProps, value: any) => void

const VehicleFormChecklistModal: FC<VehicleFormChecklistModalProps> = ({ openModal, handleClose, serviceOrderData, setServiceOrderData, viewOnly }) => {
  const classes = useStyles();
  const [data, setData] = useState<typeof DEFAULT_VEHICLE_FORM_CHECKLIST_OPTIONS>(
    !!serviceOrderData?.vehicle_inspection_askings?.length ? 
    serviceOrderData?.vehicle_inspection_askings
    : DEFAULT_VEHICLE_FORM_CHECKLIST_OPTIONS
  ) 

  const handleChangeList = (id: string, field: keyof VehicleFormChecklistModalOptionProps, value: any) => {
    const changedData = data.map(optn => {
      if(optn.id === id) {
        return {
          ...optn,
          [field]: value
        }
      }

      return optn
    })

    setData(changedData)

  }
  
  const handleSubmit = () => {
    const SO = {
      ...serviceOrderData, 
      vehicle_inspection_askings: data,
      must_validate_vehicle_form_before: true,
    }
    setServiceOrderData(SO)
    handleClose()
  }

  return(
    <Dialog open={openModal} onClose={handleClose}>
      <DialogContent style={{ marginLeft: 12, marginRight: 12 }}>
        {data.map((optin, index) => (
          <VehicleFormChecklistModalOption 
            key={optin.id} 
            {...optin} 
            index={index + 1} 
            handleChangeList={handleChangeList}
            viewOnly={viewOnly}
          />
        ))}
      </DialogContent>
      <DialogContent
        style={{
          minHeight: 100,
          display: "flex",
          justifyContent: "space-between",
          padding: '20px',
        }}
      >
        <Button className={classes.buttonBack} onClick={handleClose} style={{ width: viewOnly ? '100%' : 200}}>
          {viewOnly ? 'Fechar' : 'Cancelar'}
        </Button>
        {!viewOnly &&
          <Button
            type='button'
            className={classes.buttonDelete}
            variant="text"
            onClick={() => {
              handleSubmit()
            }}
          >
            Salvar
          </Button>
        }
      </DialogContent>
    </Dialog>
  )
}

export default VehicleFormChecklistModal