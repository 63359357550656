import React, { createContext, useContext, useState, ReactNode } from "react";
import { Checklist, StepChecklist } from "../types/managementServiceOrderTypes";

interface AnalyzeVisionContextProps {
  sealCode: string;
  setSealCode: React.Dispatch<React.SetStateAction<string>>;
  maxGross: string;
  setMaxGross: React.Dispatch<React.SetStateAction<string>>;
  tare: string;
  setTare: React.Dispatch<React.SetStateAction<string>>;
  currentIndexStep: number;
  setCurrentIndexStep: React.Dispatch<React.SetStateAction<number>>;
  orderServiceData: any;
  setOrderServiceData: React.Dispatch<React.SetStateAction<any>>;
  currentStepList: StepChecklist[];
  setCurrentStepList: React.Dispatch<React.SetStateAction<StepChecklist[]>>;
}

const AnalyzeVisionContext = createContext<
  AnalyzeVisionContextProps | undefined
>(undefined);

const AnalyzeVisionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [sealCode, setSealCode] = useState<string>("");
  const [maxGross, setMaxGross] = useState<string>("");
  const [tare, setTare] = useState<string>("");
  const [currentIndexStep, setCurrentIndexStep] = useState(0);
  const [orderServiceData, setOrderServiceData] = useState<any>();
  const [currentStepList, setCurrentStepList] = useState<StepChecklist[]>([]);

  return (
    <AnalyzeVisionContext.Provider
      value={{
        sealCode,
        setSealCode,
        maxGross,
        setMaxGross,
        tare,
        setTare,
        currentIndexStep,
        setCurrentIndexStep,
        orderServiceData,
        setOrderServiceData,
        currentStepList,
        setCurrentStepList,
      }}
    >
      {children}
    </AnalyzeVisionContext.Provider>
  );
};

const useAnalyzeVision = () => {
  const context = useContext(AnalyzeVisionContext);
  if (!context) {
    throw new Error(
      "useAnalyzeVision must be used within an AnalyzeVisionProvider"
    );
  }
  return context;
};

export { AnalyzeVisionProvider, useAnalyzeVision };
