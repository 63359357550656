import axios from "axios";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../../components/Messages";
import { api } from "../../services/api";
import { Driver } from "../../types/managementUserTypes";
import { checkIfDateIsvalid, dateToShow } from "../../utils";

type DriverData = {
  id: string;
  createdAt: string;
  name: string;
  phone: string;
  cpf: string;
  cnh: string;
  company: string;
  status: boolean;
};

const SKIPLIMIT = 10;

const checkIsvalidDate = (date: string) => {
  if(!date) {
    return ""
  }

  if(date && date?.length < 8) {
    return ""
  }

  return date
}

export function useDrivers() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [driverData, setDriverData] = useState<Driver>({
    id: "",
    idUser: "",
    name: "",
    cpf: "",
    rg: "",
    phoneFirst: "",
    phoneSecond: "",
    email: "Sim",
    status: "Ativo",
    createdAt: "",
    badge: "",
    cnhNumber: "",
    cnhCategory: "Selecionar",
    cnhExpirationDate: "",
    profileType: "Selecionar",
    passwordResetFrequency: 1,
    imageCnh: {
      imageName: "",
      imagePath: "",
      fileName: "",
      isDocument: false,
      required: true,
      location: {
        latitude: 0,
        longitude: 0,
      },
      dateHour: "",
      dateScheduled: "",
      base64: "",
      order: 0,
    },
    imageAddress: {
      imageName: "",
      imagePath: "",
      fileName: "",
      isDocument: false,
      required: true,
      location: {
        latitude: 0,
        longitude: 0,
      },
      dateHour: "",
      dateScheduled: "",
      base64: "",
      order: 0,
    },
  });

  const [listProfileType, setListProfileType] = useState([
    { id: "", name: "" },
  ]);

  const getAll = useCallback((id: string) => {
    const driver = api.get(`motoristas/${id}`);
    const listRoles = api.get("roles");


    axios
      .all([driver, listRoles])
      .then(
        axios.spread((...responses) => {
          const driver = responses[0];
          const listRoles = responses[1];

          setListProfileType(
            listRoles.data.data
              .map((profile: any) => {
                return {
                  id: profile?._id,
                  name: profile?.nome,
                };
              })
              .filter((profile: any) => profile.name !== "Admin")
          );

          const userData = driver?.data?.data;

          setDriverData((prevState) => ({
            ...prevState,
            id: userData?._id,
            idUser: userData?.user?._id || userData?.user_id?._id,
            name: userData?.user?.nome || userData?.user_id?.nome,
            profileType: userData?.user?.role_id || userData?.user_id?.role_id,
            cnhNumber: userData?.cnh,
            cnhExpirationDate: checkIfDateIsvalid(userData?.cnh_validade),
            cnhCategory: userData?.cnh_categoria,
            badge: userData?.cracha,
            createdAt:  userData?.createdAt ? `${new Date(
              userData?.createdAt
            ).toLocaleDateString()} - ${new Date(userData?.createdAt)
              .toISOString()
              .slice(11, 16)}` : "",
            imageCnh: {
              imageKey: userData?.imagem_cnh?.image_key,
              imagePath: userData?.imagem_cnh?.imagem_path,
              isDocument: userData?.imagem_cnh?.isDocument
            } as any,
            imageAddress: {
              imageKey: userData?.imagem_comprovante_endereco?.image_key,
              imagePath: userData?.imagem_comprovante_endereco?.imagem_path,
              isDocument: userData?.imagem_comprovante_endereco?.isDocument
            } as any,
          }));
          setLoading(false);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  const addDriver = async (driverData: Driver) => {
    setLoading(true);
    try {
      await api.post("motoristas", {
        cnh: driverData.cnhNumber,
        cnh_validade: driverData.cnhExpirationDate,
        cnh_categoria: driverData.cnhCategory,
        user_id: driverData.idUser,
        cracha: driverData.badge,
        status: driverData.status === "Ativo",
        cpf: driverData.cpf
      });
      navigate("/drivers");
      successMessage("Motorista adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error)
      if(error?.response?.data?.message[0]) {
        if(error?.response?.data?.message[0] === "!") {
          errorMessage("Não foi possível adicionar motorista");
          setLoading(false);
          return;
        }

        errorMessage(error?.response?.data?.message[0][0]);
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível adicionar motorista");
      setLoading(false);
    }
  };

  const updateDriver = async (driverData: Driver) => {
    setLoading(true);
    try {
      await api.patch(`motoristas/${id}`, {
        cnh: driverData.cnhNumber,
        cnh_validade: driverData.cnhExpirationDate,
        cnh_categoria: driverData.cnhCategory,
        user_id: driverData.idUser,
        cracha: driverData.badge,
        // imagem_cnh: {
        //   imagem_name: "",
        //   imagem_path: "",
        //   file_name: driverData.imageCnh.fileName,
        //   obrigatorio: true,
        //   ordem: 0,
        // },
        // imagem_comprovante_endereco: {
        //   imagem_name: "",
        //   imagem_path: "",
        //   file_name: driverData.imageAddress.fileName,
        //   obrigatorio: true,
        //   ordem: 0,
        // },
        //status: driverData.status === "Ativo",
      });
      navigate("/drivers");
      successMessage("Motorista atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar motorista");
      setLoading(false);
    }
  };

  const getAllProfileType = async () => {
    try {
      const { data } = await api.get("roles");
      setListProfileType(
        data.data
          .map((profile: any) => {
            return {
              id: profile._id,
              name: profile.nome,
            };
          })
          .filter((profile: any) => profile.name !== "Admin")
      );
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDriver = async (id: string) => {
    setLoading(true);
    try {
      await api.delete(`motoristas/${id}`);
      getAllDrivers();
      setRefresh(prev => !prev)
      successMessage("Motorista excluído com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSearchDriverCreated = async (cpf: string) => {
    try {
      const { data } = await api.get(`/users/dados-motorista/${cpf}`);

      setDriverData((prevState) => ({
        ...prevState,
        id: data?.data?.motorista?._id,
        idUser: data?.data?.user?._id,
        name: data?.data?.user?.nome,
        profileType: data?.data?.user?.role_id,
        createdAt: `${new Date(
          data?.data?.user?.createdAt
        ).toLocaleDateString()} - ${new Date( data?.data?.user?.createdAt)
          .toISOString()
          .slice(11, 16)}`,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchUserDriver = async (cpf: string) => {
    try {
      const { data } = await api.get(`/users/cpf/${cpf}`);

      setDriverData((prevState) => ({
        ...prevState,
        // id: data.data._id,
        idUser: data.data._id,
        name: data.data.nome,
        profileType: data.data.role_id,
        cpf: data.data.cpf,
        createdAt: `${new Date(
          data.data.createdAt
        ).toLocaleDateString()} - ${new Date(data.data.createdAt)
          .toISOString()
          .slice(11, 16)}`,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const [listDrivers, setListDrivers] = useState<Driver[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllDrivers(value);
  };


  interface GetAllDriversList {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number;
  }

  const getAllDriversList = async <T>({name, paginationIndex, rowsPerPage=10} : GetAllDriversList) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)

    const nameToQuery = `${name ? `&nome=${name}` : ''}`;
    const pageLimit =  `${rowsPerPage ? `&limit=${rowsPerPage}` : ''}`;

    try {
      const response = await api.get(`motoristas?skip=${paginationIndex}${nameToQuery}${pageLimit}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result?._id,
            name: result?.user?.nome,
            createdAt: result.createdAt ? new Date(result?.createdAt)?.toLocaleDateString() : "",
            phone: result?.user?.telefone,
            cpf: result?.user?.cpf,
            cnh: result?.cnh,
            company: "",
            status: result.status,
            badge: result?.cracha,
            secondPhone: result?.telefone2,
            cnhCategory: result?.cnh_categoria,
            cnhExpirationDate: dateToShow(result?.cnh_validade),
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],  
      totalItems,
    };
  }

  const getAllDrivers = async (currentPage: number = 1) => {
    try {
      const { data } = await api.get(
        `motoristas?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListDrivers(
        data.data.results.map((result: any) => {
          return {
            id: result._id,
            name: result.user_id.nome,
            createdAt: new Date(result.createdAt).toLocaleDateString(),
            phone: result.user_id.telefone,
            cpf: result.user_id.cpf,
            cnh: result.cnh,
            company: "",
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    driverData,
    listDrivers,
    // getDriver,
    getAll,
    addDriver,
    updateDriver,
    deleteDriver,
    getAllDrivers,
    getAllProfileType,
    handleSearchDriverCreated,
    listProfileType,
    handleSearchUserDriver,
    loadingData,
    getAllDriversList,
    refresh
  };
}
