import { errorMessage, successMessage } from "../../../../components/Messages";
import { api } from "../../../../services/api";


interface EndOccurrence {
  id: string, 
  order: number,
  handleData: (data: any) => void,
  os_id: string;
  refresh: () => void
}

export const endOccurrence = async ({id, order, handleData, os_id, refresh} : EndOccurrence) => {
  try {
    const response = await api.post(`ocorrencias/${id}/end?ordem=${order}`, {
      os_id,
    })

    handleData(response?.data?.data)
    successMessage("Ocorrência finalizada com sucesso!");

    refresh()


  } catch (error: any) {
    errorMessage("Erro ao tentar finalizar a ocorrência, tente novamente");
  }
}
