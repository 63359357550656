import { api } from "../../../../services/api"

export const asyncCheckCNPJ = async (CNPJ: string, handleLoading: (isLoading: boolean) => void) => {
  if(!CNPJ) {
    return false
  }

  handleLoading(true)
 
  try {
    const response = await api.get(`clientes/cnpj/${CNPJ}`)

    if(response.data.statusCode === 200){
      handleLoading(false)
      return true
    }
    handleLoading(false)
    return false

  } catch (error) {
    handleLoading(false)
    return false
  }
}