import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import { Layout } from "../components/Layout";

import { Login } from "../pages/Login";

import { Home } from "../views/Home";
import { Monitoring } from "../views/ServiceOrder/Monitoring";
import { Notifications } from "../views/ServiceOrder/Notifications";
import { ServiceOrderForm } from "../views/ServiceOrder/ServiceOrderForm";
import { Occurrency } from "../views/ServiceOrder/Occurrency";

import { Status } from "../views/ServiceOrderManagement/Status";
import { StatusForm } from "../views/ServiceOrderManagement/Status/StatusForm";
import { TypeLoad } from "../views/ServiceOrderManagement/TypeLoad";
import { TypeLoadForm } from "../views/ServiceOrderManagement/TypeLoad/TypeLoadForm";
import { TypeModal } from "../views/ServiceOrderManagement/TypeModal";
import { TypeModalForm } from "../views/ServiceOrderManagement/TypeModal/TypeModalForm";
import { Checklist } from "../views/ServiceOrderManagement/Checklist";
import { ChecklistForm } from "../views/ServiceOrderManagement/Checklist/ChecklistForm";
import { TypeVehicle } from "../views/ServiceOrderManagement/TypeVehicle";
import { TypeVehicleForm } from "../views/ServiceOrderManagement/TypeVehicle/TypeVehicleForm";
import { Occurrences } from "../views/ServiceOrderManagement/Occurrences";
import { OccurrencyForm } from "../views/ServiceOrderManagement/Occurrences/OccurrencyForm";
import { TypeSchedule } from "../views/ServiceOrderManagement/TypeSchedule";
import { TypeScheduleForm } from "../views/ServiceOrderManagement/TypeSchedule/TypeScheduleForm";
//import { Address } from "../views/ServiceOrderManagement/Address";

import { Users } from "../views/UsersManagement/Users";
import { UserForm } from "../views/UsersManagement/Users/UserForm";
import { Clients } from "../views/UsersManagement/Clients";
import { ClientForm } from "../views/UsersManagement/Clients/ClientForm";
import { Drivers } from "../views/UsersManagement/Drivers";
import { DriverForm } from "../views/UsersManagement/Drivers/DriverForm";
import { Vehicles } from "../views/UsersManagement/Vehicles";
import { VehicleForm } from "../views/UsersManagement/Vehicles/VehicleForm";
import { LOCAL_STORAGE_KEY } from "../consts";
import Profile from "../views/Profile";
//import { AddressForm } from "../views/ServiceOrderManagement/Address/AddressForm";


export const AppRoutes = () => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
  
  const isAdmin = userRole?.toString()?.includes('Admin')
  
  return (
    <BrowserRouter>
      <Layout >
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/service-order/:id" element={<ServiceOrderForm />} />
          <Route path="/monitoring" element={<Monitoring />} />

          <Route path="/profile" element={<Profile />} />
          
          {isAdmin && (
            <>
              <Route path="/notifications" element={<Notifications />} />

              <Route path="/service-order/new" element={<ServiceOrderForm />} />
              <Route
                path="/service-order/:id/edit"
                element={<ServiceOrderForm />}
              />
              <Route
                path="/service-order/occurrency/:id"
                element={<Occurrency />}
              />

              <Route path="/vehicle-type" element={<TypeVehicle />} />
              <Route path="/vehicle-type/new" element={<TypeVehicleForm />} />
              <Route path="/vehicle-type/:id" element={<TypeVehicleForm />} />
              <Route path="/vehicle-type/:id/edit" element={<TypeVehicleForm />} />
              <Route path="/load-type" element={<TypeLoad />} />
              <Route path="/load-type/new" element={<TypeLoadForm />} />
              <Route path="/load-type/:id" element={<TypeLoadForm />} />
              <Route path="/load-type/:id/edit" element={<TypeLoadForm />} />
              <Route path="/modal-type" element={<TypeModal />} />
              <Route path="/modal-type/new" element={<TypeModalForm />} />
              <Route path="/modal-type/:id" element={<TypeModalForm />} />
              <Route path="/modal-type/:id/edit" element={<TypeModalForm />} />
              <Route path="/schedule-type" element={<TypeSchedule />} />
              <Route path="/schedule-type/new" element={<TypeScheduleForm />} />
              <Route path="/schedule-type/:id" element={<TypeScheduleForm />} />
              <Route
                path="/schedule-type/:id/edit"
                element={<TypeScheduleForm />}
              />
              {/* <Route path="/adresses" element={<Address/>} />
              <Route path="/adresses/new" element={<AddressForm/>} />
              <Route path="/adresses/:id" element={<AddressForm />} />
              <Route path="/adresses/:id/edit" element={<AddressForm />} /> */}
              <Route path="/status" element={<Status />} />
              <Route path="/status/new" element={<StatusForm />} />
              <Route path="/status/:id" element={<StatusForm />} />
              <Route path="/status/:id/edit" element={<StatusForm />} />
              <Route path="/occurrences" element={<Occurrences />} />
              <Route path="/occurrences/new" element={<OccurrencyForm />} />
              <Route path="/occurrences/:id" element={<OccurrencyForm />} />
              <Route path="/occurrences/:id/edit" element={<OccurrencyForm />} />
              <Route path="/checklist" element={<Checklist />} />
              <Route path="/checklist/new" element={<ChecklistForm />} />
              <Route path="/checklist/:id" element={<ChecklistForm />} />
              <Route path="/checklist/:id/edit" element={<ChecklistForm />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/new" element={<UserForm />} />
              <Route path="/users/:id" element={<UserForm />} />
              <Route path="/users/:id/edit" element={<UserForm />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/new" element={<ClientForm />} />
              <Route path="/clients/:id" element={<ClientForm />} />
              <Route path="/clients/:id/edit" element={<ClientForm />} />
              <Route path="/vehicles" element={<Vehicles />} />
              <Route path="/vehicles/new" element={<VehicleForm />} />
              <Route path="/vehicles/:id" element={<VehicleForm />} />
              <Route path="/vehicles/:id/edit" element={<VehicleForm />} />
              <Route path="/drivers" element={<Drivers />} />
              <Route path="/drivers/new" element={<DriverForm />} />
              <Route path="/drivers/:id" element={<DriverForm />} />
              <Route path="/drivers/:id/edit" element={<DriverForm />} />
           </>
          )}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};
