import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    marginTop: 8,
  },
  box: {
    background: '#F2F7FA',
    width: 500,
    padding: '24px 12px',
    borderRadius: 12,
    margin: '10px 0',
    transition: 'ease all 0.5s'
  },
  secundaryBox: {
    background: '#D9ECF5',
    width: '100%',
    padding: 12,
    paddingTop: 0,
    marginTop: 12,
    borderRadius: 12,
    //margin: '10px 0',
    transition: 'ease all 0.5s'
  },
  checkboxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  quetionText: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 6
  },
  text: {
    fontSize: 14,
    paddingBottom: 6,
    color: "#4E4E4E"
  },
  input: {
    marginTop: 10,
    backgroundColor: '#C6DDE7'
  },
  subQuetionText: {
    fontSize: 14,
    fontWeight: 600,
    paddingTop: 12,
    color: "#4E4E4E",
    paddingBottom: 6
  },
  checkboxInput: {
    width: 60,
    marginLeft: 8,
  },
  inputLabel: {
    marginBottom: theme.spacing(1),
    color: "#4E4E4E",
  },
  buttonBack: {
    width: 200,
    fontSize: 14,
    height: 40,
    fontWeight: "bold",
    marginRight: 8,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 30,
    paddingLeft: 30,
    color: "#FFF",
    textTransform: "capitalize",
    transition: ".5s ease",
    backgroundColor: "#FF3C40",
    "&:hover": {
      backgroundColor: "rgba(255,60,64,0.8)",
    },
    borderRadius: 5,
    textDecoration: "none",
  },
  buttonDelete: {
    width: 200,
    height: 40,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 8,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 30,
    paddingLeft: 30,
    color: "#fff",
    textTransform: "capitalize",
    transition: ".5s ease",
    backgroundColor: "#2EA949",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "rgba(46,169,73,0.8)",
    },
    borderRadius: 5,
    textDecoration: "none",
  },
}));
