import { api } from "../../../../services/api"

export const asyncCheckCPF = async (CPF: string, handleLoading: (isLoading: boolean) => void) => {
  if(!CPF) {
    return false
  }

  handleLoading(true)
 
  try {
    const response = await api.get(`users/cpf/${CPF}`)

    if(response.data.statusCode === 200){
      handleLoading(false)
      return true
    }

    handleLoading(false)
    return false

  } catch (error) {
    handleLoading(false)
    return false
  }
}