import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { useTypeLoad } from "../../../hooks/useTypeLoad";
import { useStyles } from "./styles";
import GenericTable from "../../../components/GenericTable";
import GenericExport from "../../../components/GenericExport";

const tableHeader = [
  {key: 'name', label: 'Nome'},
  {key: 'status', label: 'Status'},
];

const tableHeaderToExport = [
  {key: 'nome', label: 'Nome'},
  {key: 'createdAt', label: 'Data de criação'},
  {key: 'updatedAt', label: 'Última atualização'},
  {key: 'status', label: 'Status'},
];

export const TypeLoad = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [data, setData] = useState<any>()
  const [totalItems, setTotalItems] = useState(0)
  const [name, setName] = useState("")
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    deleteTypeLoad,
    getAllTypeOfLoad,
    loadingData,
    refresh
  } = useTypeLoad();

  // useEffect(() => {
  //   getAllTypeLoad();
  // }, []);

  // const [selectedLoadTypes, setSelectedLoadTypes] = useState<LoadType[]>([]);
  // const handleSelectedLoadType = (selectedLoadType: LoadType) => {
  //   const updatedSelectedLoadTypes = [...selectedLoadTypes];
  //   const alreadySelected = updatedSelectedLoadTypes.find(
  //     (user) => user.id === selectedLoadType.id
  //   );
  //   if (alreadySelected) {
  //     setSelectedLoadTypes(
  //       updatedSelectedLoadTypes.filter(
  //         (user) => user.id !== selectedLoadType.id
  //       )
  //     );
  //   } else {
  //     setSelectedLoadTypes([...updatedSelectedLoadTypes, selectedLoadType]);
  //   }
  // };


  const getAllData = async () => {
    const {data, totalItems: total} = await getAllTypeOfLoad({name, paginationIndex, rowsPerPage})

    if(data) {
      setData(data.map((item: any) => ({
        ...item,
        status: (
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",

              width: 200,
              padding: "4px 0px",
              borderRadius: "4px",

              fontSize: 12,
              color: "#252525",
              opacity: "0.8",
              backgroundColor: (item?.status as Boolean)
                ? "#6FED8B"
                : "#FF3C40",
            }}
          >
            {(item?.status as Boolean) ? "Ativo" : "Inativo"}
          </Typography> 
          )
      })))
      setTotalItems(total)
    }

    //setLoadingDate(loading)
  }

  useEffect(() => {
    setData([])
    getAllData()
  }, [name, paginationIndex, refresh, rowsPerPage]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={3}>
        <Typography style={{ color: "#5A5A5A", fontSize: 24, fontWeight: 600 }}>
          Tipos de Carga
        </Typography>
      </Grid>

      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchBarInputsContainer}>
              <TextField
                className={classes.searchBarInput}
                placeholder="Pesquisar por nome"
                variant="outlined"
                size="small"
                value={name}
                onChange={e => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.searchBarActionButtonsContainer}>
              <GenericExport 
                headerToRows={tableHeaderToExport}
                endpoint="tipo-carga"
                title="Exportar lista de tipos de cargas"
              />
              <Button
                startIcon={<AddIcon />}
                className={classes.buttonRegister}
                onClick={() => navigate("/load-type/new")}
              >
                Cadastrar
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item lg={12}>
        <GenericTable
          header={tableHeader}
          data={data || []}
          isLoading={loadingData}
          handleDelete={(item) => deleteTypeLoad(item?.id)}
          handleEdit={(item) => navigate(`/load-type/${item?.id}/edit`)}
          onClick={(item) => navigate(`/load-type/${item?.id}`)}
          total={totalItems}
          onChangeIndex={(index) =>  setPaginationIndex(index)}
          onChangeRowsPerPage={(value) => setRowsPerPage(value)}
        />
      </Grid>
    </Grid>
  );
};
