import styled from 'styled-components';

export const Container = styled.div<{ quantity: number }>`
  min-width: 100%;
  
  display: grid;

  flex: 1;

  overflow-x: auto;
  overflow-y: hidden;

  grid-template-columns: ${({quantity}) => `repeat(${quantity}, auto)`};
`;

export const Header = styled.div<{headerBgColor: string, headerColor: string, enableBorderToHeader: boolean, color: string}>`
  background-color: ${({headerBgColor}) => headerBgColor};
  color: ${({headerColor}) => headerColor};
  min-height: 60px;
  border: none;
  border-bottom: 1px solid ${({enableBorderToHeader}) => enableBorderToHeader ? '#4f4f4f30' : 'transparent'};
  
  font-size: 13px;
  color: ${({ color }) => color || "#4f4f4f"};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;

  @media (min-width: 1024px){
    font-size: 15px;
  }
  @media (min-width: 700px){
    min-height: 70px;
  }
  p{
    display: flex;
    align-items: center;
    
    :hover {
      div {
        opacity: 1;
      }
    }

  }

`;

export const HeaderIconSortWrapper = styled.div<{ show: boolean, down: boolean }>`
  opacity: ${({show}) => show ? 1 : 0};
  transition: all 0.3s ease-in-out;
  transform:  ${({down}) => down ? 'rotate(180deg)' : ''};

  display: flex;
  align-items: center;
  margin-left: 4px;
`;

export const Item = styled.div<{ theLast: boolean, bgColor: string, isHeader?: boolean, color: string }>`
  min-height: 55px;

  border-bottom: ${({theLast}) => theLast ? 'none' : '1px solid #4f4f4f20'} ;

  background-color: ${({bgColor}) => bgColor};

  text-transform: ${({isHeader}) => isHeader ? 'uppercase' : 'nome'};

  font-size: 12px;
  color: ${({ color }) => color || "#4f4f4f"};
  font-weight: normal,;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;

  @media (min-width: 1024px){
    font-size: 15px;
  }
  @media (min-width: 700px){
    min-height: 65px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
    display: flex;

    svg {
      cursor: pointer
    }
  }
`;
