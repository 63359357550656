import { VehicleFormChecklistModalOptionProps } from "../types/vehicleFormChecklist"

const DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION = 'Apresenta algum espaço irregular que possa ser inserido algo que não faça parte do transporte?'

export const DEFAULT_VEHICLE_FORM_CHECKLIST_OPTIONS: VehicleFormChecklistModalOptionProps[] = [
  {
    id: '01',
    main_question: 'O para-choque do veículo está em boas condições de uso?',
    second_question: 'Apresenta alguma abertura irregular que não faça parte do para-choque?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '02',
    main_question: 'O motor está em boas condições?',
    second_question: 'Apresenta algum espaço irregular que não faça parte do motor?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '03',
    main_question: 'Os pneus estão em bom estado? Sem rasgo?',
    second_question: 'Existem alguma abertura que possa ser inserido algo que não faça parte do transporte?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '04',
    main_question: 'A cabine está em boas condições?',
    second_question: 'Apresenta alguma abertura irregular que possa ser inserido algo que não faça parte do transporte?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '05',
    main_question: 'O piso da unidade tratora está em boas condições?',
    second_question: 'Existem alguma abertura que possa ser inserido algo que não faça parte do transporte?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '06',
    main_question: 'O tanque de combustível está em boas condições?',
    second_question: 'Apresenta alguma abertura irregular que possa ser inserido algo que não faça parte do transporte?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '07',
    main_question: 'O reservatório de água está em boas condições?',
    second_question: 'Apresenta alguma abertura irregular que possa ser inserido algo que não faça parte do transporte?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '08',
    main_question: 'O eixo de transmissão está em boas condições?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '09',
    main_question: 'A quinta roda está em boas condições?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '10',
    main_question: 'O sistema de exaustão está em boas condições?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '11',
    main_question: 'O chassi está conforme?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '12',
    main_question: 'A lateral direita está em boas condições?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '13',
    main_question: 'A lateral esquerda está em boas condições?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '14',
    main_question: 'A parede frontal está em boas condições?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '15',
    main_question: 'O teto está em boas condições?',
    second_question: 'Apresenta alguma abertura irregular ou furos?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '16',
    main_question: 'As portas do veículo estão em boas condições?',
    second_question: 'Apresenta alguma abertura irregular?',
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
  {
    id: '17',
    main_question: 'Piso do compartimento de carga está conforme?',
    second_question: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTION_SECUNDARY_QUESTION,
    main_question_answer: '',
    second_question_answer: '',
    main_observation: '',
    second_observation: '',
    first_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    },
    second_image: {
      imagem_name: '',
      imagem_path: '',
      obrigatorio: false
    }
  },
]