import { PiTruck } from "react-icons/pi"

interface TruckIconProps {
  status: "PENDENTE" | "EM ANDAMENTO" | "CONCLUIDO" | "",
}

const chooseColor = (status: "PENDENTE" | "EM ANDAMENTO" | "CONCLUIDO") => {
  if(status === "CONCLUIDO") {
    return "#6FED8B"
  }

  if(status === "EM ANDAMENTO") {
    return "#FED278"
  }

  return "#D3D3D3"
}

const TruckIcon = ({ status }: TruckIconProps) => {
  if(!status){
    return <></>
  }

  return <PiTruck color={chooseColor(status)} size={30}/>
}

export default TruckIcon