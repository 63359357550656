import { v4 as uuidv4 } from 'uuid';
import {
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { ServiceOrder } from "../../../../../types/managementServiceOrderTypes";
import { useStyles } from "./styles";
import moment from "moment";
import { Address, useAddress } from "../../../../../hooks/useAddress";
import { onlyNumbers, tranformStringIntoZipCode } from "../../../../../utils";
import { useClients } from "../../../../../hooks/Users/useClients";
import CustomClientListInput from "../../../../../components/CustomClientListInput";

import {
  Add as AddIcon,
} from "@material-ui/icons";
moment.locale("pt-br");

type StepRouteProps = {
  setServiceOrderData: React.Dispatch<React.SetStateAction<ServiceOrder>>;
  serviceOrderData: ServiceOrder;
};

interface ClientWithAddress {
  id: string;
  name: string;
  endereco: Address
}

const newRoute = {
  id: "",
  name: "",
  sender: "",
  zipCode: "",
  state: "",
  city: "",
  neighborhood: "",
  street: "",
  number: "",
  complement: "",
  reference: "",
  collectDate: ""
}

export const StepRoute = ({
  setServiceOrderData,
  serviceOrderData,
}: StepRouteProps) => {
  const classes = useStyles();
  const [listOfAdresses, setListOfAdresses] = useState<Address[]>([]);
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(0);
  const [listOfClients, setListOfClients] = useState<ClientWithAddress[]>([])
  const [refresh, setRefresh] = useState(false);
  const [newRouteName, setNewRouteName] = useState("");

  const {
    getAllAddress,
  } = useAddress()

  const {
    clientListWidthAddress
  } = useClients()

  const getAllData = async () => {
    const {data} = await  getAllAddress({});
    const clients = await clientListWidthAddress();

    if(data) {
      setListOfAdresses(data as any)
    }

    if(clients) {
      setListOfClients(clients)
    }
  }

  useEffect(() => {
    getAllData()
  }, []);

  const handleChangeRoute = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setServiceOrderData((prevState) => {
      let routesCopy = [...prevState.schedule.routes];
      routesCopy[index] = {
        ...routesCopy[index],
        [event.target.name]: event.target.value,
      };

      return {
        ...prevState,
        schedule: {
          ...prevState.schedule,
          routes: routesCopy,
        },
      };
    });
  };

  const handleAddRoute = (id: string) => {
    setRefresh(true);
    let routesCopy = [...serviceOrderData?.schedule?.routes];

    routesCopy.push({
      ...newRoute,
      id,
      name: newRouteName
    } as any)
    
    setServiceOrderData({
      ...serviceOrderData,
      schedule: {
        ...serviceOrderData.schedule,
        routes: routesCopy,
      }
    });
    setTimeout(() => {
      setRefresh(false);
    }, 10)
  }

  const handleSelectAddress = (selectedCEP: string, index: number) => {
    const getItem = listOfClients?.find(item => item?.endereco?.CEP === selectedCEP)

    setServiceOrderData((prevState) => {
      let routesCopy = [...prevState.schedule.routes];
      routesCopy[index] = {
        ...routesCopy[index],
        zipCode: getItem?.endereco?.CEP || "",
        state: getItem?.endereco?.estado || "",
        city: getItem?.endereco?.cidade || "",
        neighborhood: getItem?.endereco?.bairro || "",
        street: getItem?.endereco?.logradouro || "",
        number: getItem?.endereco?.numero?.toString() || "",
        complement: getItem?.endereco?.complemento || routesCopy[index]?.complement || "",
      };

      return {
        ...prevState,
        schedule: {
          ...prevState.schedule,
          routes: routesCopy,
        },
      };
    });
  }

  if(refresh) {
    return (
      <Grid container spacing={1} className={classes.formContainer}>

      </Grid>
    )
  }

  return (
    <Grid container spacing={1} className={classes.formContainer}>
      <Grid item lg={12}>
        <Typography className={classes.formSectionTitle}>
          Dados do remetente
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <TextField
          value={serviceOrderData?.schedule?.routes[0]?.name}
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={3}>
        <CustomClientListInput
          label="Remetente*"
          value={serviceOrderData?.schedule?.routes[0]?.sender}
          required
          name="sender"
          onChange={(event, address) => {
            handleChangeRoute(event, 0)
            if(address){
              setSelectedVehicleIndex(0)

              const getAdresses = listOfClients?.find(item => item.name === event.target.value)
              handleSelectAddress(getAdresses?.endereco?.CEP || "", 0)
            }
          }}
        />

      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>CEP*</InputLabel>
        <TextField
          name="zipCode"
          value={serviceOrderData?.schedule?.routes[0]?.zipCode}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Selecionar"
          variant="outlined"
          size="small"
          fullWidth
          required
        >
          {/* {listOfAdresses?.map(value => (
            <MenuItem key={value?.CEP} value={value?.CEP}>
              {tranformStringIntoZipCode(value?.CEP)}
            </MenuItem>
          ))} */}
        </TextField>
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Estado*</InputLabel>
        <TextField
          name="state"
          value={serviceOrderData?.schedule?.routes[0]?.state}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Estado"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Cidade*</InputLabel>
        <TextField
          name="city"
          value={serviceOrderData?.schedule?.routes[0]?.city}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Cidade"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Bairro*</InputLabel>
        <TextField
          name="neighborhood"
          value={serviceOrderData?.schedule?.routes[0]?.neighborhood}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Bairro"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={5}>
        <InputLabel className={classes.inputLabel}>Logradouro*</InputLabel>
        <TextField
          name="street"
          value={serviceOrderData?.schedule?.routes[0]?.street}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Logradouro"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={1}>
        <InputLabel className={classes.inputLabel}>Número*</InputLabel>
        <TextField
          name="number"
          value={serviceOrderData?.schedule?.routes[0]?.number}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Número"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Complemento*</InputLabel>
        <TextField
          name="complement"
          value={serviceOrderData?.schedule?.routes[0]?.complement}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Complemento"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={9}>
        <InputLabel className={classes.inputLabel}>Referência</InputLabel>
        <TextField
          name="reference"
          value={serviceOrderData?.schedule?.routes[0]?.reference}
          onChange={(e) => handleChangeRoute(e, 0)}
          placeholder="Referência"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Data/Hora coleta*</InputLabel>
        <TextField
          name="collectDate"
          value={serviceOrderData?.schedule?.routes[0]?.collectDate}
          onChange={(e) => {
            handleChangeRoute(e, 0);
          }}
          placeholder="Data/Hora coleta"
          variant="outlined"
          size="small"
          fullWidth
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Divider */}
      <Grid item lg={12}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>

      <Grid item lg={12}>
        <Typography className={classes.formSectionTitle}>
          Dados da fazenda
        </Typography>
      </Grid>
      {serviceOrderData?.schedule?.routes.length > 2 &&
        serviceOrderData?.schedule?.routes
          .filter(
            (_, index) =>
              index !== 0 &&
              index !== serviceOrderData?.schedule?.routes.length - 1
          )
          .map((aditionalRoute, index) => (
            <Fragment key={`aditionalRoute-${index + 1}`}>
              <Grid item lg={12}>
                <TextField
                  value={aditionalRoute.name}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item lg={3}>
                <CustomClientListInput
                  label="Remetente*"
                  value={aditionalRoute.sender}
                  required
                  name="sender"
                  onChange={(event, address) => {
                    handleChangeRoute(event, index + 1)
                    if(address) {
                        setSelectedVehicleIndex(index + 1)

                        const getAdresses = listOfClients?.find(item => item.name === event.target.value)
                        handleSelectAddress(getAdresses?.endereco?.CEP || "", index + 1)
                    }
                  }}
                />
              </Grid>
              <Grid item lg={3}>
                <InputLabel className={classes.inputLabel}>CEP*</InputLabel>
                <TextField
                  name="zipCode"
                  value={aditionalRoute.zipCode}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Selecionar"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                >
                  {/* {listOfAdresses?.map(value => (
                    <MenuItem key={value?.CEP} value={value?.CEP}>
                      {tranformStringIntoZipCode(value?.CEP)}
                    </MenuItem>
                  ))} */}
                </TextField>
              </Grid>
              <Grid item lg={3}>
                <InputLabel className={classes.inputLabel}>Estado*</InputLabel>
                <TextField
                  name="state"
                  value={aditionalRoute.state}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Estado"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3}>
                <InputLabel className={classes.inputLabel}>Cidade*</InputLabel>
                <TextField
                  name="city"
                  value={aditionalRoute.city}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Cidade"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3}>
                <InputLabel className={classes.inputLabel}>Bairro*</InputLabel>
                <TextField
                  name="neighborhood"
                  value={aditionalRoute.neighborhood}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Bairro"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item lg={5}>
                <InputLabel className={classes.inputLabel}>
                  Logradouro
                </InputLabel>
                <TextField
                  name="street"
                  value={aditionalRoute.street}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Logradouro"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item lg={1}>
                <InputLabel className={classes.inputLabel}>Número*</InputLabel>
                <TextField
                  name="number"
                  value={aditionalRoute.number}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Número"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3}>
                <InputLabel className={classes.inputLabel}>
                  Complemento*
                </InputLabel>
                <TextField
                  name="complement"
                  value={aditionalRoute.complement}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Complemento"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item lg={9}>
                <InputLabel className={classes.inputLabel}>
                  Referência
                </InputLabel>
                <TextField
                  name="reference"
                  value={aditionalRoute.reference}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Referência"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3}>
                <InputLabel className={classes.inputLabel}>
                  Data/Hora coleta*
                </InputLabel>
                <TextField
                  name="collectDate"
                  value={aditionalRoute.collectDate}
                  onChange={(e) => handleChangeRoute(e, index + 1)}
                  placeholder="Data/Hora coleta"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Fragment>
          ))}

      {/* Divider */}
      <Grid item lg={12}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>

      <Grid item lg={12}>
        <Typography className={classes.formSectionTitle}>
          Dados do destinatário
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <TextField
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.name
          }
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>

      <Grid item lg={3}>
        <CustomClientListInput
          label="Destinatário*"
          required
          name="sender"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.sender
          }
          onChange={(event, address) => {
            handleChangeRoute(event, Number(serviceOrderData?.schedule?.routes.length - 1))
            if(address){
              setSelectedVehicleIndex(Number(serviceOrderData?.schedule?.routes.length - 1))

              const getAdresses = listOfClients?.find(item => item.name === event.target.value)
              handleSelectAddress(getAdresses?.endereco?.CEP || "", Number(serviceOrderData?.schedule?.routes.length - 1))
            }
          }}
        /> 
      </Grid>

        <Grid item lg={3}>
          <InputLabel className={classes.inputLabel}>CEP*</InputLabel>
          <TextField
            name="zipCode"
            value={
              serviceOrderData?.schedule?.routes[
                Number(serviceOrderData?.schedule?.routes.length - 1)
              ]?.zipCode
            }
            onChange={(e) =>
              handleChangeRoute(
                e,
                Number(serviceOrderData?.schedule?.routes.length - 1)
              )
            }
            placeholder="Selecionar"
            variant="outlined"
            size="small"
            fullWidth
            required
          >
            {listOfAdresses?.map(value => (
              <MenuItem key={value?.CEP} value={value?.CEP}>
                {tranformStringIntoZipCode(value?.CEP)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Estado*</InputLabel>
        <TextField
          name="state"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.state
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Estado"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Cidade*</InputLabel>
        <TextField
          name="city"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.city
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Cidade"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Bairro*</InputLabel>
        <TextField
          name="neighborhood"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.neighborhood
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Bairro"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={5}>
        <InputLabel className={classes.inputLabel}>Logradouro*</InputLabel>
        <TextField
          name="street"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.street
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Logradouro"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={1}>
        <InputLabel className={classes.inputLabel}>Número*</InputLabel>
        <TextField
          name="number"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.number
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Número"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Complemento*</InputLabel>
        <TextField
          name="complement"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.complement
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Complemento"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={9}>
        <InputLabel className={classes.inputLabel}>Referência</InputLabel>
        <TextField
          name="reference"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.reference
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Referência"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>Data/Hora coleta*</InputLabel>
        <TextField
          name="collectDate"
          value={
            serviceOrderData?.schedule?.routes[
              Number(serviceOrderData?.schedule?.routes.length - 1)
            ]?.collectDate
          }
          onChange={(e) =>
            handleChangeRoute(
              e,
              Number(serviceOrderData?.schedule?.routes.length - 1)
            )
          }
          placeholder="Data/Hora coleta"
          variant="outlined"
          size="small"
          fullWidth
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item lg={12} style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
        <Grid item lg={3}>
          <TextField
            value={newRouteName}
            onChange={(e) =>
              setNewRouteName(e.target.value)
            }
            placeholder="Nome da nova Rota"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>  
        <Button
          startIcon={<AddIcon />}
          className={classes.button}
          onClick={() => {
            handleAddRoute(uuidv4());
            setNewRouteName("")
          }}
          disabled={!newRouteName}
        >
          Adicionar Rota
        </Button>
      </Grid>  
    </Grid>
  );
};
