import { Button, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import ImageComponent from "../../../../../../components/ImageComponent";
import DropZoneModal from "../../../../../../components/Dropzone/modal";
import { useState } from "react";

type ModalDeleteProps = {
  required: boolean;
  isVideo?: boolean;
  imageName: string;
  imagePath: string;
  onChangeImage: (key: string, type: string) => void;
  isUsingVision?: boolean
};

const chooseType = (type: string) => {
  if (type.includes("image")) {
    return "image";
  }
  if (type.includes("video")) {
    return "video";
  }

  return "document";
};

const StepValidationImage = ({
  required,
  isVideo,
  imageName,
  imagePath,
  onChangeImage,
  isUsingVision
}: ModalDeleteProps) => {
  const classes = useStyles();

  const [previewName, setPreviewName] = useState("");

  return (
    <div
      style={{
        background: "#ECF5F9",
        padding: 12,
        borderRadius: 6,
        width: 200,
      }}
    >
      <Grid item style={{ width: "100%" }}>
        <Typography className={classes.overviewSectionSubtitle}>
          {required ? "Obrigatório" : "Opcional"}
        </Typography>
        <Typography className={classes.overviewSectionSubtitle}>
          {isVideo ? "Video:" : "Imagem:"}
          <Typography className={classes.imageName}>{imageName}</Typography>
        </Typography>
      </Grid>
      {imagePath && (
        <ImageComponent
          imageKey={imagePath}
          imageStyles={{ maxWidth: 400 }}
          onlyImage
          isVideo={isVideo}
        />
      )}
      <DropZoneModal
        onSave={(key, type, preview) => {
          setPreviewName(preview);
          onChangeImage(key, chooseType(type));
        }}
        isUsingVision={isUsingVision}
        label="Importar Arquivo"
        //disabled={!fileName}
        fileKey={imageName || ""}
      >
        <Button style={{ width: "100%", marginTop: 12 }}>
          Importar Arquivo
        </Button>
      </DropZoneModal>
      {previewName && (
        <Typography
          className={classes.imageName}
          style={{ wordWrap: "break-word" }}
        >
          {previewName}
        </Typography>
      )}
    </div>
  );
};

export default StepValidationImage;
