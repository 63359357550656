import { FC, Dispatch, SetStateAction } from "react"
import {
  Grid,
  Paper
} from "@material-ui/core";

import { useStyles } from "./styles";
import { SectionChecklist } from "../../Steps/Overview/Checklist";
import { ServiceOrder } from "../../../../../types/managementServiceOrderTypes";

export type ChecklistTabProps = {
  checklist: any 
  serviceOrder: ServiceOrder
  setServiceOrderData: Dispatch<SetStateAction<ServiceOrder>>;
}

const ChecklistTab: FC<ChecklistTabProps> = ({ checklist, serviceOrder, setServiceOrderData }) => {
  const classes = useStyles();
  return(
    <Grid container spacing={1} className={classes.formContainer}>
      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <SectionChecklist 
            checklist={checklist} 
            serviceOrder={serviceOrder} 
            setServiceOrderData={setServiceOrderData}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ChecklistTab