import styled from 'styled-components';

export const Container = styled.div<{ borderRadius: string }>`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  border-radius: ${({borderRadius}) => borderRadius};

  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
export const Body = styled.section`
  width: 100%;
  flex: 1;
`;

export const Footer = styled.section`
  width: 100%;
  min-height: 40px;
  max-height: 55px;
`;

export const NoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;


export const LoadingContainer = styled.div`
  width: 100%;
  height: 120px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
` 
