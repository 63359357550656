import styled from "@emotion/styled"

export const Container = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;

  border: 1px solid #D3D3D3;
  border-radius: 4px;

  .ant-picker-focused{
    box-shadow: none !important;
  }

  .ant-picker-active-bar{
    background-color: #2F4D7C !important;
  }

  :hover, :focus, :active {
    border-color: #616161;
    box-shadow: none !important;
    border-inline-end-width: 1px;
    //border: 0 !important;
  }

  .ant-select-selector {
    border: none !important;
    height: 38px !important;
    background-color: red;
    //border-radius: 1px;
    border-radius: 4px 0 0 4px;
    border: 0 !important;

    span {
      margin-top: 4px;
    }
  }

  .select{
    border-radius: 4px 0 0 4px !important;
    margin-right: -1px;
    width: auto !important;
    max-width: 100px;
    border: 0 !important;
  }

  .datepicker{
    border-radius: 0 4px 4px 0 !important;
    max-width: 220px;
    height: 38px;
    border: 0 !important;
    border-left: 1px solid #d9d9d9;
  }

  .ant-select-selector-item {
    border: none;
    height: 40px !important;
    display: flex;
    align-items: center;

    color: blue;

    background-color: red;

    border: 0 !important;

    ::after{
      color: red
    }
  }

  .css-dev-only-do-not-override-14wwjjs 
  {
    height: 38px !important;
    border-radius: 0px;
    border: 1px solid #d9d9d9 ;

    outline: none;

    border: 0 !important;

    :hover, :focus, :active, &:focused {
      border-color: #616161;
      box-shadow: none !important;
      border-inline-end-width: 1px;
      border: 0 !important;
    }
  }
`

export const Label = styled.span`
  font-size: 12px;

  position: absolute;
  top: -10px;
  left: 5px;
  z-index: 2;
  padding: 0 4px;

  background-color: #fff;
`

//#757575 
// #121212