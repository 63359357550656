import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    imageTitle: {
      color: "#6A6A6A",
      fontWeight: 500,
      fontSize: 14,
    },
    imageSubtitle: {
      color: "#CDCDCD",
      fontWeight: 500,
      fontSize: 14,
    },
    paper: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
      padding: 10,
    },
  })
);
