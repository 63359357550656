import { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Paper,
  InputAdornment,
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  ImageOutlined as ImageIcon,
  Search as SearchIcon,
} from "@material-ui/icons/";
import { Formik, Form as FormikForm } from "formik";

import { VehiclePhotos } from "./VehiclePhotos";

import { useVehicles } from "../../../../hooks/Users/useVehicles";
import { inCreationOrEditing, onlyNumbers } from "../../../../utils";
import { useStyles } from "./styles";
import { InputMask } from "../../../../components/InputMask";
import { useDrivers } from "../../../../hooks/Users/useDrivers";
import { useClients } from "../../../../hooks/Users/useClients";
import { asyncCheckPlaca } from "../services/checkPlaca";
import { Loading } from "../../../../components/Loading";

const listStatus = ["Ativo", "Inativo", "Em Análise"];

export const VehicleForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const {
    loading,
    vehicleData,
    setVehicleData,
    getVehicle,
    addVehicle,
    updateVehicle,
    selectList,
    getAllVehicleType,
    updateImages,
    listOfVeVehicleType
  } = useVehicles();

  const {
    getAllClientsList
  } = useClients();
  const { driverData, handleSearchDriverCreated, getAllDriversList } = useDrivers();
  const { clientData, handleSearchClient } = useClients();
  const [placaExists, setPlacaExists] = useState(false);
  const [loadingPlaca, setLoadingPlaca] = useState(false)
  const [CPFOrCNPJ, setCPFOrCNPJ] = useState("CPF");
  const [allClients, setAllClients] = useState([])
  const [selectedClient, setSelectedClient] = useState("")
  const [allDrivers, setAllDrivers] = useState([])
  const [selectedDriver, setSelectedDriver] = useState("")

  const [searchFields, setSearchFields] = useState({
    cpf: "",
    cnpj: "",
  });

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar veículo";
    } else if (location.pathname.includes("edit")) {
      return "Editar veículo";
    } else {
      return "Veículo";
    }
  };

  const [showDataSection, setShowDataSection] = useState(true);

  const getAllData = async () => {
    const {data} = await getAllClientsList<any>({ paginationIndex: 0, rowsPerPage: 0 })
    const {data: drivers} = await getAllDriversList<any>({ paginationIndex: 0, rowsPerPage: 0 })

    if(data) {
      setAllClients(data as any)
    }

    if(drivers) {
      setAllDrivers(drivers as any)
    }
  }

  useEffect(() => {
    getAllVehicleType();
    getAllData()
  }, [id]);

  useEffect(() => {
    if (id) {
      getVehicle(id);
    }
  }, [id, location]);

  useEffect(() => {
    if(vehicleData) {
      setCPFOrCNPJ(onlyNumbers(vehicleData?.owner?.cpfCnpj)?.length > 11 ? "CNPJ" : "CPF")
    }
  }, [vehicleData])

  return (
    <Formik
      enableReinitialize
      initialValues={vehicleData}
      onSubmit={(values) => {
        
        if (location.pathname.includes("new")) {
          addVehicle({
            ...values,
            responsibleDriver: {
              ...values.responsibleDriver,
              id: selectedDriver,
            },
            client: { ...values.client, id: selectedClient },
          });
        } else {
          updateVehicle({
            ...values,
            responsibleDriver: {
              ...values.responsibleDriver,
              id: selectedDriver || values.responsibleDriver.id,
            },
            client: {
              ...values.client,
              id: selectedClient || values.client.id,
            },
          });
        }
      }}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography className={classes.pageTitle}>Veículos</Typography>
            </Grid>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarLeftContent}>
                    <IconButton
                      onClick={() => navigate("/vehicles")}
                      className={classes.buttonBack}
                    >
                      <KeyboardArrowLeftIcon fontSize="medium" />
                    </IconButton>
                    <Typography className={classes.pageSubtitle}>
                      {returnedTitlePage(location)}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "flex" : "none",
                    }}
                  >
                    <Button type="submit" className={classes.buttonSave} disabled={placaExists || loadingPlaca || loading}>
                      {loading ? <Loading /> : "Salvar"}
                    </Button>
                    <Button
                      className={classes.buttonDiscard}
                      onClick={() => navigate("/vehicles")}
                    >
                      Descartar
                    </Button>
                  </div>

                  <div
                    style={{
                      display: inCreationOrEditing(location) ? "none" : "flex",
                    }}
                  >
                    {/* <Button
                      className={classes.buttonExport}
                      onClick={() => alert("Em Implementação!")}
                    >
                      Exportar
                    </Button> */}
                    <Button
                      className={classes.buttonSave}
                      onClick={() => navigate(`/vehicles/${id}/edit`)}
                    >
                      {loading ? <Loading/> : "Editar"}
                    </Button>
                  </div>
                </div>

                <div
                  className={classes.actionTab}
                  style={{
                    display:  "flex"
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 8,
                      color:
                        showDataSection
                          ? "#2F4D7C"
                          : "",
                    }}
                    className={
                      location.pathname.includes("new")
                        ? classes.actionTabTextNew
                        : classes.actionTabTextNew
                    }
                    onClick={() => {
                      if (true) {
                        setShowDataSection(true);
                      }
                      return;
                    }}
                  >
                    Dados
                  </Typography>
                  <Typography
                    style={{
                      color: !showDataSection ? "#2F4D7C" : "",
                    }}
                    className={
                      location.pathname.includes("new")
                        ? classes.actionTabTextNew
                        : classes.actionTabTextNew
                    }
                    onClick={() => {
                      if (true) {
                        setShowDataSection(false);
                      }
                      return;
                    }}
                  >
                    Fotos do veículo
                  </Typography>
                </div>
              </Paper>
            </Grid>

            <Grid item lg={12}>
              <Paper className={classes.paper}>
                {showDataSection ? (
                  <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados do veículo
                      </Typography>
                    </Grid>

                    {!location.pathname.includes("new") ? (
                      <Grid item lg={3}>
                        <InputLabel className={classes.inputLabel}>
                          Data de cadastro
                        </InputLabel>
                        <TextField
                          name="createdAt"
                          value={values.createdAt}
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Tipo*
                      </InputLabel>
                      <TextField
                        name="vehicleType"
                        value={values.vehicleType}
                        onChange={event => {
                          let getItem = listOfVeVehicleType?.find(item => item.id === event.target.value)

                          updateImages({
                            imageDocuments: getItem?.imageDocuments,
                            imageTires: getItem?.imageTires,
                            imageVehicle: getItem?.imageVehicle,
                            vehicle: {...values, vehicleType: event.target.value}
                          })
                        }}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!location.pathname.includes("new")}
                      >
                        {listOfVeVehicleType?.map((vehicleType) => (
                          <MenuItem key={vehicleType.id} value={vehicleType.id}>
                            {vehicleType.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Nome*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Renavam*
                      </InputLabel>
                      <TextField
                        name="renavam"
                        value={values.renavam}
                        onChange={handleChange}
                        placeholder="Renavam*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Chassi*
                      </InputLabel>
                      <TextField
                        name="chassi"
                        value={values.chassi}
                        onChange={handleChange}
                        placeholder="Chassi*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Placa*
                      </InputLabel>
                      <TextField
                        name="plate"
                        value={values.plate}
                        className={placaExists ? classes.inputError : ""}
                        onChange={e => {
                          handleChange(e);
                          if(onlyNumbers(e.target.value).length >= 6){
                            return
                          }

                          setTimeout(async() => {
                            const isItTrue = await asyncCheckPlaca(e.target.value.replace("-", ""), setLoadingPlaca);
                            setPlacaExists(isItTrue)
                          }, 1000)
                        }}
                        placeholder="Placa*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                        InputProps={{
                          inputComponent: InputMask as any,
                        }}
                      />
                      {placaExists &&
                        <InputLabel className={classes.inputLabelError}>
                        Já existe um Veículo com esta placa
                        </InputLabel>
                      }
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Cor
                      </InputLabel>
                      <TextField
                        name="color"
                        value={values.color}
                        onChange={handleChange}
                        placeholder="Cor"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Cidade*
                      </InputLabel>
                      <TextField
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        placeholder="Cidade"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Estado UF*
                      </InputLabel>
                      <TextField
                        name="state_UF"
                        value={values.state_UF}
                        onChange={handleChange}
                        placeholder="Estado UF"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Status*
                      </InputLabel>
                      <TextField
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!inCreationOrEditing(location)}
                      >
                        {listStatus.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>


                    <Grid
                      item
                      lg={3}
                      style={{
                        padding: 4,
                        display: inCreationOrEditing(location)
                          ? "flex"
                          : "none",
                        flexDirection: "column",
                      }}
                    >
                      <InputLabel className={classes.inputLabel}>
                        Selecionar Empresa
                      </InputLabel>
                        <TextField
                        name="client"
                        value={selectedClient || vehicleData?.client?.id}
                        onChange={e => {
                          setSelectedClient(e.target.value);
                        }}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {allClients?.map((value: any) => (
                          <MenuItem key={value?.id} value={value?.id}>
                            {value?.name}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      lg={3}
                      style={{
                        padding: 4,
                        display: inCreationOrEditing(location)
                          ? "flex"
                          : "none",
                        flexDirection: "column",
                      }}
                    >
                      <InputLabel className={classes.inputLabel}>
                        Selecionar Motorista*
                      </InputLabel>
                        <TextField
                        name="driver"
                        value={selectedDriver || vehicleData?.responsibleDriver.id}
                        onChange={e => {
                          setSelectedDriver(e.target.value);
                        }}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!inCreationOrEditing(location)}
                      >
                        {allDrivers?.map((value: any) => (
                          <MenuItem key={value?.id} value={value?.id}>
                            {value?.name}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Dados do Propietário
                      </Typography>
                    </Grid>

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Nome*
                      </InputLabel>
                      <TextField
                        name="ownerName"
                        value={values.owner.name}
                        onChange={handleChange("owner.name")}
                        placeholder="Nome*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={1}>
                      <InputLabel className={classes.inputLabel}>
                        CPF/CNPJ*
                      </InputLabel>
                      <TextField
                        name="cpfOrCNPJ"
                        value={CPFOrCNPJ}
                        onChange={e => setCPFOrCNPJ(e.target.value)}
                        placeholder="Selecionar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        required
                        disabled={!inCreationOrEditing(location)}
                      >
                        {["CPF", "CNPJ"].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        {CPFOrCNPJ}*
                      </InputLabel>
                      <TextField
                        name={CPFOrCNPJ.toLowerCase()}
                        value={values.owner.cpfCnpj}
                        onChange={handleChange("owner.cpfCnpj")}
                        placeholder="CPF/CNPJ"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={!inCreationOrEditing(location)}
                        InputProps={{
                          inputComponent: InputMask as any,
                        }}
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        {CPFOrCNPJ === "CPF" ? "RG" : "IE"}
                      </InputLabel>
                      <TextField
                        name="rg"
                        value={values.owner.rg}
                        onChange={handleChange("owner.rg")}
                        placeholder="RG/IE*"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Data de nascimento
                      </InputLabel>
                      <TextField
                        name="birthDate"
                        value={values.owner.birthDate}
                        onChange={handleChange("owner.birthDate")}
                        placeholder="00/00/0000"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!inCreationOrEditing(location)}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item lg={12}>
                      <Typography className={classes.formSectionTitle}>
                        Atualização de dados
                      </Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <InputLabel className={classes.inputLabel}>
                        Frequência de atualização cadastral*
                      </InputLabel>
                      <TextField
                        name="updateRegisterFrequency"
                        value={values.updateRegisterFrequency}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        disabled={!inCreationOrEditing(location)}
                      >
                        {selectList.updateRegisterFrequency.map((frequency) => (
                          <MenuItem key={frequency.id} value={frequency.id}>
                            {frequency.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                ) : (
                  <VehiclePhotos
                    values={values}
                    handleChange={setVehicleData}
                    disabled={!inCreationOrEditing(location)}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
