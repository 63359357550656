import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../../components/Messages";
import { api } from "../../services/api";
import { Client } from "../../types/managementUserTypes";
import { onlyNumbers, removeEmptyValuesFromObject } from "../../utils";
import { LOCAL_STORAGE_KEY } from "../../consts";

type ClientData = {
  id: string;
  createdAt: string;
  name: string;
  cnpj: string;
  email: string;
  phone: string;
  status: boolean;
};

const SKIPLIMIT = 10;

export function useClients() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [clientData, setClientData] = useState<Client>({
    id: "",
    name: "",
    cnpj: "",
    emailFirst: "",
    emailSecond: "",
    sendEmail: "Sim",
    phone: "",
    status: "Ativo",
    registerType: {
      client: false,
      destination: false,
      sender: false,
    },
    createdAt: "",
    endereco_id: "",
    endereco: {
      CEP: '',
      bairro: '',
      estado: '',
      numero: '',
      logradouro: '',
      cidade: '',
      complemento: '',
    }
  });

  const getClient = async (id: string) => {
    try {
      const { data } = await api.get(`clientes/${id}`);
      setClientData({
        id: data.data._id,
        name: data.data.nome,
        cnpj: data.data.cnpj,
        emailFirst: data.data.email,
        emailSecond: data.data.email2,
        phone: data.data.telefone,
        endereco: data.data.endereco,
        sendEmail: data.data.notificacao ? "Sim" : "Não",
        createdAt: `${new Date(
          data.data.createdAt
        ).toLocaleDateString()} - ${new Date(data.data.createdAt)
          .toISOString()
          .slice(11, 16)}`,
        status: data.data.status ? "Ativo" : "Inativo",
        registerType: {
          client: data.data.tipo_cadastro.cliente,
          destination: data.data.tipo_cadastro.destinatario,
          sender: data.data.tipo_cadastro.remetente,
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addClient = async (clientData: Client) => {
    setLoading(true);
    const dataToSend = {
      nome: clientData.name,
      cnpj: clientData.cnpj,
      email: clientData.emailFirst,
      email2: clientData.emailSecond,
      endereco: clientData?.endereco,
      password: '12345678',
      telefone: onlyNumbers(clientData.phone),
      notificacao: clientData.sendEmail === "Sim",
      status: clientData.status === "Ativo",
      tipo_cadastro: {
        cliente: clientData.registerType.client,
        destinatario: clientData.registerType.destination,
        remetente: clientData.registerType.sender,
      },
    }
    removeEmptyValuesFromObject(dataToSend)

    try {
      await api.post("clientes", dataToSend);
      navigate("/clients");
      successMessage("Cliente adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
       if(error?.response?.data?.message[0]) {
        errorMessage(error.response.data.message[0][0])
        setLoading(false);
        return;
      }
      console.log(error);
      errorMessage("Não foi possível adicionar cliente");
      setLoading(false);
    }
  };

  const updateClient = async (clientData: Client) => {
    setLoading(true);
    try {
      await api.patch(`clientes/${id}`, {
        nome: clientData.name,
        cnpj: clientData.cnpj,
        email: clientData.emailFirst,
        email2: clientData.emailSecond,
        endereco: clientData.endereco,
        password: '12345678',
        telefone: onlyNumbers(clientData.phone),
        notificacao: clientData.sendEmail === "Sim",
        status: clientData.status === "Ativo",
        tipo_cadastro: {
          cliente: clientData.registerType.client,
          destinatario: clientData.registerType.destination,
          remetente: clientData.registerType.sender,
        },
      });
      navigate("/clients");
      successMessage("Cliente atualizado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error?.response?.data?.message[0]) {
        errorMessage(error.response.data.message[0][0])
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível atualizar cliente");
      setLoading(false);
    }
  };

  const deleteClient = async (id: string) => {
    try {
      await api.delete(`clientes/${id}`);
      getAllClients();
      successMessage("Cliente excluído com sucesso!");
      setRefresh(prev => !prev)
    } catch (error: any) {
      console.log(error);
      if(error?.response?.data?.message[0]) {
        errorMessage(error.response.data.message[0][0])
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível exluir o cliente, por favor, tente novamente");
      setLoading(false);
    }
  };

  const handleSearchClient = async (cnpj: string) => {
    try {
      const { data } = await api.get(`/clientes/cnpj/${cnpj}`);
      setClientData({
        ...clientData,
        id: data.data._id,
        name: data.data.nome,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [listClients, setListClients] = useState<ClientData[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllClients(value);
  };

  interface GetAllClientsList {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number;
  }

  const clientListWidthAddress = async () => {
    let result: any[] = [];
    try {
      const response = await api.get('clientes');

      if(response.status === 200) {
        response?.data?.data?.data?.forEach((item: any) => {
          if(item?.endereco) {
            result.push({
              id: item._id,
              name: item.nome,
              endereco: item?.endereco
            })
          }
        })
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return result
  }

  const getAllClientsList = async <T>({name, paginationIndex, rowsPerPage=10} : GetAllClientsList) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)

    const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
            
    const isClient = userRole?.toString()?.includes('Cliente')
          
    if (isClient) {
      return {
        data: result as T[],  
        totalItems,
      }
    }

    const nameToQuery = `${name ? `&nome=${name}` : ''}`;
    const pageLimit =  `${rowsPerPage ? `&limit=${rowsPerPage}` : ''}`;

    try {
      const response = await api.get(`clientes?skip=${paginationIndex}${nameToQuery}${pageLimit}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result._id,
            name: result.nome,
            email: result.email,
            createdAt: new Date(result.createdAt).toLocaleDateString(),
            cnpj: result.cnpj,
            phone: result.telefone,
            status: result.status,
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],  
      totalItems,
    };
  }

  const getAllClients = async (currentPage: number = 1) => {
    try {
      const { data } = await api.get(
        `clientes?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListClients(
        data.data.data.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            email: result.email,
            createdAt: new Date(result.createdAt).toLocaleDateString(),
            cnpj: result.cnpj,
            phone: result.telefone,
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    clientData,
    listClients,
    getClient,
    addClient,
    updateClient,
    deleteClient,
    getAllClients,
    handleSearchClient,
    loadingData,
    getAllClientsList,
    refresh,
    clientListWidthAddress
  };
}
