import { Switch } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 12,
    },
    actionBarLeftContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: 24,
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    pageSubtitle: {
      color: "#252525",
      fontSize: 20,

      marginLeft: theme.spacing(2),
    },
    paper: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    switchContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    switchText: {
      color: "#1D1D1D",
      marginLeft: theme.spacing(1),
    },
    inputError: {
      borderRadius: 5,
      border: "1px solid red !important",
      outline: "none",
      "&:focus": {
        border: "1px solid red !important",
      },
    },
    inputLabelError: {
      marginTop: 5,
      marginBottom: theme.spacing(1),
      color: "red",
    },
    buttonBack: {
      borderRadius: "20%",
      color: "#FFF",
      backgroundColor: "#2F4D7C",
      transition: ".5 ease",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
    },
    buttonSave: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonDiscard: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      marginLeft: theme.spacing(2),
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#FF3C40",
      "&:hover": {
        backgroundColor: "rgba(255,60,64,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonExport: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      marginRight: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#76A1E8",
      "&:hover": {
        backgroundColor: "#6991CF",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
  })
);

export const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#34B870",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#F66969",
    },
    checked: {},
  })
)(Switch);
