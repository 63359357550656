import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import { useStyles } from "./styles";
import { useServiceOrder } from "../../../../../hooks/useServiceOrder";
import { inCreationOrEditing } from "../../../../../utils";
import { useTypeSchedule } from "../../../../../hooks/useTypeSchedule";
import { ServiceOrder } from "../../../../../types/managementServiceOrderTypes";
import { useClients } from "../../../../../hooks/Users/useClients";

type StepOperationDataProps = {
  setServiceOrderData: React.Dispatch<React.SetStateAction<ServiceOrder>>;
  serviceOrderData: ServiceOrder;
  handleSearchClient: (cnpj: string) => Promise<void>;
};

function compareNumbers(a: any, b: any) {
  return a?.order - b?.order;
}

export const StepOperationData = ({
  setServiceOrderData,
  serviceOrderData,
  handleSearchClient,
}: StepOperationDataProps) => {
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const { setLoading, getServiceOrder } = useServiceOrder();
  const { getAllTypeSchedule, listTypeSchedule } = useTypeSchedule();
  const [allClients, setAllClients] = useState<{id: string, name: string}[]>([])
  const [selectedClient, setSelectedClient] = useState("")

  const {
    getAllClientsList
  } = useClients();

  const getAllData = async () => {
    const {data} = await getAllClientsList<any>({ paginationIndex: 0, rowsPerPage: 0 })

    if(data) {
      setAllClients(data as any)
    }
  }

  useEffect(() => {
    getAllData()
  }, []);


  const handleChangeSchedule = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedSchedule = listTypeSchedule.filter(
      (schedule) => schedule.id === event.target.value
    )[0];

    setServiceOrderData((prevState) => ({
      ...prevState,
      schedule: {
        ...selectedSchedule, 
        routes: selectedSchedule?.routes?.sort(compareNumbers) as any
      },
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setServiceOrderData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    getAllTypeSchedule();
    if (id) {
      getServiceOrder(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  return (
    <Grid container spacing={1} className={classes.formContainer}>
      <div style={{ width: '100%',  margin: 5 }}>
        <Typography className={classes.formSectionTitle}>
          Dados do tipo de programação
        </Typography>
      </div>
      <Grid item lg={2}>
        <InputLabel className={classes.inputLabel}>
          Nome da programação*
        </InputLabel>
        <TextField
          value={serviceOrderData.schedule.id}
          onChange={handleChangeSchedule}
          placeholder="Selecionar"
          variant="outlined"
          size="small"
          fullWidth
          select
          disabled={!location.pathname.includes("new")}
        >
          <MenuItem value="Selecionar" disabled>
            Selecionar
          </MenuItem>
          {listTypeSchedule.map((schedule) => (
            <MenuItem key={schedule.id} value={schedule.id}>
              {schedule.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item lg={2}>
        <InputLabel className={classes.inputLabel}>Via</InputLabel>
        <TextField
          value={serviceOrderData.schedule.via}
          placeholder="Via"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={2}>
        <InputLabel className={classes.inputLabel}>Modal</InputLabel>
        <TextField
          value={serviceOrderData.schedule.modal}
          placeholder="Modal"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={2}>
        <InputLabel className={classes.inputLabel}>Tipo de carga</InputLabel>
        <TextField
          value={serviceOrderData.schedule.typeLoad}
          placeholder="Tipo de carga"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={2}>
        <InputLabel className={classes.inputLabel}>Situação</InputLabel>
        <TextField
          value={serviceOrderData.schedule.situation}
          placeholder="Situação"
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item lg={2}>
        <InputLabel className={classes.inputLabel}>Semana da programação*</InputLabel>
        <TextField
          name="scheduleWeek"
          value={serviceOrderData.scheduleWeek}
          placeholder="Semana da programação"
          variant="outlined"
          size="small"
          type="number"
          fullWidth
          required
          disabled={!inCreationOrEditing(location)}
          onChange={handleChange}
        />
      </Grid>

      <div style={{ width: '100%',  margin: 5 }}>
        <Typography className={classes.formSectionTitle}>Operação</Typography>
      </div>
      <Grid item lg={4}>
        <InputLabel className={classes.inputLabel}>CTAC/DI/Booking</InputLabel>
        <TextField
          name="ctac"
          value={serviceOrderData.ctac}
          onChange={handleChange}
          placeholder="CTAC/DI/Booking"
          variant="outlined"
          size="small"
          fullWidth
          disabled={!inCreationOrEditing(location)}
        />
      </Grid>
      <Grid item lg={4}>
        <InputLabel className={classes.inputLabel}>
          Solicitante/Pagador
        </InputLabel>
        <TextField
          name="requester"
          value={serviceOrderData.requester}
          onChange={handleChange}
          placeholder="Solicitante/Pagador"
          variant="outlined"
          size="small"
          fullWidth
          disabled={!inCreationOrEditing(location)}
        />
      </Grid>
      <Grid container>

        <Grid item lg={4} style={{ padding: 4 }}>
          <InputLabel className={classes.inputLabel}>
            Selecionar Cliente*
          </InputLabel>
          <TextField
            name="cnpj"
            value={selectedClient || serviceOrderData?.client?.id}
            onChange={e => {
              setSelectedClient(e.target.value);
              const getItem = allClients?.find((item) => item.id === e.target.value) as unknown as {id: string, name: string}

              setServiceOrderData((prevState) => ({
                ...prevState,
                client: { id: getItem?.id, name: getItem?.name },
              }));
            }}
            placeholder="Selecionar"
            variant="outlined"
            size="small"
            fullWidth
            select
            required
            disabled={!inCreationOrEditing(location)}
          >
            {allClients?.map((value: any) => (
              <MenuItem key={value?.id} value={value.id}>
                {value?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>              
      </Grid>

      <Grid item lg={4}>
        <InputLabel className={classes.inputLabel}>Navio/Avião</InputLabel>
        <TextField
          name="shipAirplaneName"
          value={serviceOrderData.shipAirplaneName}
          onChange={handleChange}
          placeholder="Especifique o navio ou avião"
          variant="outlined"
          size="small"
          fullWidth
          disabled={!inCreationOrEditing(location)}
        />
      </Grid>
      <Grid item lg={4}>
        <InputLabel className={classes.inputLabel}>
          Número do container
        </InputLabel>
        <TextField
          name="containerNumber"
          value={serviceOrderData.containerNumber}
          onChange={handleChange}
          placeholder="0000"
          variant="outlined"
          size="small"
          fullWidth
          disabled={!inCreationOrEditing(location)}
        />
      </Grid>
      <Grid item lg={4}>
        <InputLabel className={classes.inputLabel}>Carga</InputLabel>
        <TextField
          name="load"
          value={serviceOrderData.load}
          onChange={handleChange}
          placeholder="Especifique a carga"
          variant="outlined"
          size="small"
          fullWidth
          disabled={!inCreationOrEditing(location)}
        />
      </Grid>

      <div style={{ width: '100%', margin: 5 }}>
        <Typography className={classes.formSectionTitle}>
          Observações
        </Typography>
      </div>
      <div style={{ width: '100%', margin: 5 }}>
        <TextField
          name="observation"
          value={serviceOrderData.observation}
          onChange={handleChange}
          placeholder="Observações"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          minRows={4}
          disabled={!inCreationOrEditing(location)}
        />
      </div>
    </Grid>
  );
};
