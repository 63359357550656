import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  Checklist,
  ServiceOrder,
  StepChecklist as Step,
} from "../../../../../types/managementServiceOrderTypes";
import { useChecklist } from "../../../../../hooks/useChecklist";
import { inCreationOrEditing } from "../../../../../utils";
import { CustomSwitch, useStyles } from "./styles";
import VehicleFormChecklistModal from "../../../../../components/Modals/VehicleFormChecklistModal";

type StepChecklistProps = {
  setServiceOrderData: React.Dispatch<React.SetStateAction<ServiceOrder>>;
  serviceOrderData: ServiceOrder;
};

export const StepChecklist = ({
  setServiceOrderData,
  serviceOrderData,
}: StepChecklistProps) => {
  const location = useLocation();
  const classes = useStyles();
  const [vehicleFormCheklist, setVehicleFormCheklist] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [selectedChecklist, setSelectedChecklist] = useState<Checklist>();

  const { getAllChecklist, listChecklist } = useChecklist();

  const handleChangeChecklist = (event: ChangeEvent<HTMLInputElement>) => {
    const selected = listChecklist.filter(
      (checklist) => checklist.id === event.target.value
    )[0];

    setSelectedChecklist({
      ...selected,
      steps: selected?.steps?.map((step) => ({
        ...step,
        sendToEmail: false,
        showToClient: false,
      })),
    });
  };

  const handleToggleEmailAndClientView = (
    checklist: Checklist,
    index: number,
    key: "showToClient" | "sendToEmail",
    value: boolean
  ) => {
    setSelectedChecklist({
      ...checklist,
      steps: checklist?.steps.map((step, i) => {
        if (i === index) {
          return {
            ...step,
            [key]: value,
          };
        }
        return step;
      }),
    });
  };

  useEffect(() => {
    getAllChecklist();
  }, []);

  useEffect(() => {
    if (selectedChecklist) {
      setServiceOrderData((prevState) => {
        return {
          ...prevState,
          checklist: selectedChecklist || serviceOrderData.checklist,
        };
      });
    }
  }, [selectedChecklist]);

  useEffect(() => {
    if (serviceOrderData && !selectedChecklist) {
      setSelectedChecklist(serviceOrderData.checklist);
    }
  }, [serviceOrderData]);

  const printLocationInfo = (latitude: any, longitude: any) => {
    if (!latitude || !longitude) {
      return " Localização: Esperando conclusão da etapa";
    }

    return `
    Localização: 
     ${latitude || "00.00000"}, ${longitude || "00.00000"}`;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const toggleMustValidateVehicleFormBefore = (toggle: boolean) => {
    setServiceOrderData((prev) => ({
      ...prev,
      must_validate_vehicle_form_before: toggle,
    }));
  };

  return (
    <Grid container spacing={1} className={classes.formContainer}>
      <VehicleFormChecklistModal
        openModal={openModal}
        handleClose={handleCloseModal}
        serviceOrderData={serviceOrderData}
        setServiceOrderData={setServiceOrderData}
      />
      <Grid item lg={12}>
        <Typography className={classes.formSectionTitle}>Checklist</Typography>

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <CustomSwitch
            name="sendToEmail"
            checked={serviceOrderData.must_validate_vehicle_form_before}
            onChange={(e) => {
              //setVehicleFormCheklist(prev => !prev)
              toggleMustValidateVehicleFormBefore(
                !serviceOrderData.must_validate_vehicle_form_before
              );
            }}
            disabled={!inCreationOrEditing(location)}
          />
          <Typography className={classes.switchText}>
            {vehicleFormCheklist ? "Desabilitar" : "Habilitar"} Formulário do
            Veículo
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        lg={12}
        style={{ display: vehicleFormCheklist ? "grid" : "none" }}
      >
        <InputLabel
          className={classes.vehicleFormBtnText}
          onClick={() => setOpenModal(true)}
        >
          Visualizar ou customizar Formulário do Veículo
        </InputLabel>
      </Grid>
      <Grid item lg={3}>
        <InputLabel className={classes.inputLabel}>
          Selecionar tipo de checklist*
        </InputLabel>
        <TextField
          value={serviceOrderData.checklist.id}
          onChange={handleChangeChecklist}
          placeholder="Selecionar"
          variant="outlined"
          size="small"
          fullWidth
          select
          disabled={!location.pathname.includes("new")}
        >
          <MenuItem value="Selecionar" disabled>
            Selecionar
          </MenuItem>
          {listChecklist
            .filter((checklist) => checklist.status)
            .map((checklist) => (
              <MenuItem key={checklist.id} value={checklist.id}>
                {checklist.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      {/* Divider */}
      <Grid item lg={12}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>

      {selectedChecklist?.steps
        .sort((step: any, nextStep: any) => {
          return step.order - nextStep.order;
        })
        .map((step, index) => (
          <Fragment key={`step-${index + 1}`}>
            <Grid item lg={12}>
              <Typography className={classes.formSectionTitle}>
                {step.name}
              </Typography>
              <div
                className={classes.switchContainer}
                style={{ width: "100%" }}
              >
                <CustomSwitch
                  name="sendToEmail"
                  checked={step?.sendToEmail}
                  onChange={() => {
                    handleToggleEmailAndClientView(
                      selectedChecklist,
                      index,
                      "sendToEmail",
                      !step?.sendToEmail
                    );
                  }}
                  disabled={!inCreationOrEditing(location)}
                />
                <Typography className={classes.switchText}>
                  Notificar etapa via email
                </Typography>
              </div>
              <div
                className={classes.switchContainer}
                style={{ width: "100%" }}
              >
                <CustomSwitch
                  name="showToClient"
                  checked={step?.showToClient}
                  onChange={() => {
                    handleToggleEmailAndClientView(
                      selectedChecklist,
                      index,
                      "showToClient",
                      !step?.showToClient
                    );
                  }}
                  disabled={!inCreationOrEditing(location)}
                />
                <Typography className={classes.switchText}>
                  Mostrar etapa No cliente
                </Typography>
              </div>
              <Fragment>
                <Typography className={classes.imageSubtitle}>
                  Data/Hora: Esperando conclusão da etapa
                </Typography>
                <Typography className={classes.imageSubtitle}>
                  {printLocationInfo(
                    step.location.latitude,
                    step.location.longitude
                  )}
                </Typography>
              </Fragment>
            </Grid>
            {step.images.length > 0 && (
              <Grid item lg={12}>
                <Typography className={classes.formSectionTitle}>
                  Imagens/Videos
                </Typography>
              </Grid>
            )}
            {step?.images?.length > 0 &&
              step?.images?.map((image, indexImage) => (
                <Fragment key={`image-${indexImage + 1}`}>
                  <Grid item lg={2}>
                    <Typography className={classes.imageTitle}>
                      {image?.imageName}
                    </Typography>
                    <Typography className={classes.imageSubtitle}>
                      Data/Hora {image?.dateHour || "00/00/0000"}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}

            <Grid item lg={12}>
              <Divider style={{ margin: "16px 0" }} />
            </Grid>
          </Fragment>
        ))}
    </Grid>
  );
};
