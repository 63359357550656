import {useState, FC, useEffect} from "react";
import { DatePicker, Select, ConfigProvider } from 'antd';

import {Container, Label} from "./styles"
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';
import locale from 'antd/locale/pt_BR';
import { CustomToggleDatePickerProps, DataPickerValue, IncomingValue } from "./types";

const CustomToggleDatePicker: FC<CustomToggleDatePickerProps> = 
  ({value, rangeValues, onClear, onChangeDate, onChangeDateRange, style, label}) => {

  const [dateOrRange, setDateOrRange] = useState("Data");
  const [date, setDate] = useState<any>(null);
  const [dangeDate, setRangeDate] = useState<DataPickerValue>({
    start: null,
    end: null,
  })
  const handleChange = (value: string) => {
    setDateOrRange(value)
  };

  const { RangePicker } = DatePicker;

  const handleChangeDate = (value: IncomingValue) => {
    setDate(value)
    onChangeDate && onChangeDate(value?.$d as any)
  }

  const handleChangeDateRange = (incomingValue: IncomingValue[]) => {
    if(!incomingValue ) {
      onClear && onClear();
      setRangeDate({
        start: null,
        end: null,
      })
      return
    }
  
    if(incomingValue?.length > 1 && incomingValue[1]) {

      setRangeDate({
        start: incomingValue[0]?.$d,
        end: incomingValue[1]?.$d,
      })
    }
  }

  useEffect(() => {
    if(rangeValues?.end) {
      setDateOrRange("Intervalo")
    } else setDateOrRange("Data")
  }, [])

  useEffect(() => {
    if(dangeDate.end) {
      onChangeDateRange && onChangeDateRange(dangeDate as any)
    }
  }, [dangeDate])


  useEffect(() => {
    if(value) {
      setDate(dayjs(value));
    }
    if(rangeValues) {
      setRangeDate(rangeValues as any)
    }
  }, [])

  return (
    <Container style={style || {}}>
      {label && <Label>{label}</Label>}
      <Select
        className='select'
        value={dateOrRange}
        style={{ width: 120 }}
        onChange={handleChange}
        options={[
          { value: 'Data', label: 'Por data' },
          { value: 'Intervalo', label: 'Intervalo' },
        ]}
      />
      <ConfigProvider  locale={locale}>
        {dateOrRange === "Data" && (
          <DatePicker 
            value={date} 
            className='datepicker' 
            placeholder="Data"
            format="DD-MM-YYYY"
            onChange={handleChangeDate as any}
          />
        )}
        {dateOrRange === "Intervalo" && ( 
          <RangePicker 
            value={
              [
                dangeDate.start ? dayjs(dangeDate.start) : null,
                dangeDate.end ? dayjs(dangeDate.end) : null,
              ]
            }
            format="DD-MM-YYYY"
            className='datepicker' 
            placeholder={["Início", "Fim"]} 
            onChange={handleChangeDateRange as any}
          />
        )}
      </ConfigProvider>
    </Container>
  );
};

export default CustomToggleDatePicker;