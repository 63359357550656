import { Fragment } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import moment from "moment";
moment.locale("pt-br");

type ListRoutesProps = {
  routes: {
    id: string;
    name: string;
    sender: string;
    zipCode: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    number: string;
    complement: string;
    reference: string;
    collectDate: string;
  }[];
};

export const ListRoutes = ({ routes }: ListRoutesProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item style={{width: "100%"}}>
        <Typography className={classes.overviewSectionTitle}>Rotas</Typography>
      </Grid>
      {routes.map((route, index) => (
        <Fragment key={`route-${index}`}>
          <Grid item style={{width: "100%"}}>
            <Typography className={classes.overviewSectionSubtitle}>
              Rota {`${1 + index}`}
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.name}
            </Typography>
          </Grid>
          <Grid item style={{width: "100%"}}>
            <Typography className={classes.overviewSectionSubtitle}>
              Endereço
            </Typography>
          </Grid>
          <Grid item style={{minWidth: 140}}>
            <Typography className={classes.overviewSectionField}>
              {index === 0 ? "Remetente" : "Destinatário "}
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.sender}
            </Typography>
            <Typography
              style={{ marginTop: 8 }}
              className={classes.overviewSectionField}
            >
              Logradouro
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.street}
            </Typography>
          </Grid>
          <Grid item style={{minWidth: 140}}>
            <Typography className={classes.overviewSectionField}>
              CEP
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.zipCode}
            </Typography>
            <Typography
              style={{ marginTop: 8 }}
              className={classes.overviewSectionField}
            >
              Número
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.number}
            </Typography>
          </Grid>
          <Grid item style={{minWidth: 140}}>
            <Typography className={classes.overviewSectionField}>
              Estado
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.state}
            </Typography>
            <Typography
              style={{ marginTop: 8 }}
              className={classes.overviewSectionField}
            >
              Complemento
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.complement}
            </Typography>
          </Grid>
          <Grid item style={{minWidth: 140}}>
            <Typography className={classes.overviewSectionField}>
              Cidade
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.city}
            </Typography>
            <Typography
              style={{ marginTop: 8 }}
              className={classes.overviewSectionField}
            >
              Referência
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.reference}
            </Typography>
          </Grid>
          <Grid item style={{minWidth: 140}}>
            <Typography className={classes.overviewSectionField}>
              Bairro
            </Typography>
            <Typography className={classes.overviewSectionValue}>
              {route.neighborhood}
            </Typography>
            <Typography
              style={{ marginTop: 8 }}
              className={classes.overviewSectionField}
            >
              Data/Hora coleta
            </Typography>

            <Typography className={classes.overviewSectionValue}>
              {moment(route.collectDate).format("DD/MM/YYYY - HH:mm")}
            </Typography>
          </Grid>
        </Fragment>
      ))}
      <Grid item style={{width: "100%"}}>
        <Divider style={{ margin: "16px 0" }} />
      </Grid>
    </>
  );
};
