import { errorMessage, warningMessage } from "../../../../../components/Messages"
import { api } from "../../../../../services/api"
import { DriverInfo } from "../../../Monitoring"

type ResponseType = {
  data: DriverInfo[] | null
}

export const getLastDriverCoords = async (id: string) => {
  const response: ResponseType = {
    data: null
  }

  try{
    const res = await api.get(`monitoramento-os/id/${id}`)

    response.data = res?.data?.data

  }catch (error: any) {
    console.log("Erro:", error)
      if(error.statusCode === 404 || error?.response?.status === 404) {
        warningMessage("Sem deslocamento do motorista para ser mostrado")
        return
      }
      errorMessage("Erro ao tentar buscar as coordenadas do motorista")
  }

  return response
}
