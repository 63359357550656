import { api } from "./api"

export const getPresignedImage = async(urlKey: string) => {
  let response = {
    key: "",
    url: ""
  }

  if(!urlKey) {
    return response
  }

  if(urlKey && urlKey.includes("http")) {
    return { key: "", url: urlKey }
  }

  try {
    const res = await api.post(`imagem/get-presigned?key=${urlKey}`)
    response = res.data

  } catch (error) {
    console.log("Erro ao tentar pegar a url GERADAAA:", error)
  }

  return response
}
