import { Switch } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageText: {
      color: "#6A6A6A",
      fontWeight: 500,
      fontSize: 14,
      width: "70%",
      maxWidth: 200,
    },
    imageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      gap: 10,

      width: 200,
      minHeight: 200,
      marginTop: 8,
      marginBottom: 8,

      background: "#FFFFFF",

      border: "2px dashed #BCBCBC",
      borderRadius: 8,
    },

    switchContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    switchText: {
      color: "#1D1D1D",
      marginLeft: theme.spacing(1),
    },
  })
);

export const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#34B870",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#F66969",
    },
    checked: {},
  })
)(Switch);
