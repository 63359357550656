import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../components/Messages";
import { api } from "../services/api";
import { TypeVehicle } from "../types/managementServiceOrderTypes";
import { IMAGENS_DOCUMENTOS, IMAGENS_PNEUS, IMAGENS_VEICULO } from "../consts";

// import {
//   IMAGENS_DOCUMENTOS,
//   IMAGENS_PNEUS,
//   IMAGENS_VEICULO,
//   SKIPLIMIT,
// } from "../../consts";

type VehicleType = {
  id: string;
  name: string;
  status: boolean;
};

const SKIPLIMIT = 10;

export function useTypeVehicle() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [typeVehicleData, setTypeVehicleData] = useState<TypeVehicle>({
    id: "",
    name: "",
    status: "Ativo",
    imageDocuments: IMAGENS_DOCUMENTOS as any,
    imageTires: IMAGENS_PNEUS as any,
    imageVehicle: IMAGENS_VEICULO as any,
  });

  const handleChangeImage = (
    imageKey: "id" | "name" | "status" | "imageDocuments" | "imageTires" | "imageVehicle", 
    data: any
    ) => {
    let result = {...typeVehicleData}

    result[imageKey] = data
    setTypeVehicleData(result)
  }

  const getTypeVehicle = async (id: string) => {
    try {
      const { data } = await api.get(`veiculo-tipos/${id}`);
      setTypeVehicleData({
        id: data.data._id,
        name: data.data.nome,
        status: data.data.status ? "Ativo" : "Inativo",
        imageDocuments: data?.data?.imagem_documentos,
        imageTires: data?.data?.imagem_pneus,
        imageVehicle: data?.data?.imagem_veiculo
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addTypeVehicle = async (typeVehicleData: TypeVehicle) => {
    setLoading(true);
    try {
      await api.post("veiculo-tipos", {
        nome: typeVehicleData.name,
        status: typeVehicleData.status === "Ativo",
        imagem_documentos: typeVehicleData.imageDocuments,
        imagem_pneus: typeVehicleData.imageTires,
        imagem_veiculo: typeVehicleData.imageVehicle
      });
      navigate("/vehicle-type");
      successMessage("Tipo de veículo adicionado com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error.response.data.message[0]) {
        errorMessage(error.response.data.message[0])
        setLoading(false);
        return;
      }
      errorMessage("Não foi possível adicionar tipo de veículo");
      setLoading(false);
    }
  };

  const updateTypeVehicle = async (typeVehicleData: TypeVehicle) => {
    setLoading(true);
    try {
      await api.patch(`veiculo-tipos/${id}`, {
        nome: typeVehicleData.name,
        status: typeVehicleData.status === "Ativo",
        imagem_documentos: typeVehicleData.imageDocuments,
        imagem_pneus: typeVehicleData.imageTires,
        imagem_veiculo: typeVehicleData.imageVehicle
      });
      navigate("/vehicle-type");
      successMessage("Tipo de veículo atualizado com sucesso!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível atualizar tipo de veículo");
      setLoading(false);
    }
  };

  const deleteTypeVehicle = async (id: string) => {
    try {
      await api.delete(`veiculo-tipos/${id}`);
      getAllTypeVehicle();
      successMessage("Tipo de veículo excluído com sucesso!");
      setRefresh(prev => !prev);
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      if (err.response?.status === 409) {
        return errorMessage(
          "Não é possível excluir pois este Tipo de Veículo está sendo usado."
        );
      }
      errorMessage("Não foi possível excluir tipo de veículo");
      setLoading(false);
    }
  };

  const [listTypeVehicle, setListTypeVehicle] = useState<VehicleType[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllTypeVehicle(value);
  };

  interface GetAllTypeOfVehicle {
    name?: string;
    paginationIndex: number;
    rowsPerPage?: number
  }

  const getAllTypeOfVehicle = async <T>({name, paginationIndex, rowsPerPage=10} : GetAllTypeOfVehicle) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)


    const nameToQuery = `${name ? `&nome=${name}` : ''}`;

    try {
      const response = await api.get(`veiculo-tipos?limit=${rowsPerPage}&skip=${paginationIndex}${nameToQuery}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result._id,
            name: result.nome,
            status: result.status,
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }

      setLoadingData(false)
       
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],
      loading,  
      totalItems,
    };
  }


  const getAllTypeVehicle = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `veiculo-tipos?skip=${SKIPLIMIT * (currentPage - 1)}&limit=${SKIPLIMIT}`
      );
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListTypeVehicle(
        data.data.data.map((result: any) => {
          return {
            id: result._id,
            name: result.nome,
            status: result.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loadingData,
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    typeVehicleData,
    listTypeVehicle,
    getTypeVehicle,
    addTypeVehicle,
    updateTypeVehicle,
    deleteTypeVehicle,
    getAllTypeVehicle,
    getAllTypeOfVehicle,
    refresh,
    handleChangeImage
  };
}
