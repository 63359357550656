import { api } from "../api";
import { getPresignedUrl } from "./getPresignedUrl"
import { getUploadedImageUrl } from "./getUploadedImageUrl";
import { uplodaFromPresignedURL } from "./uploadImage";

export const upload = async (key: string, formDataImage: any, handleLoading: (isLoading: boolean) => void, contentType?: string) => {
  handleLoading(true);

  let result = {
    status: 0,
    imageKey: ""
  }

  const keyWithoutSpaces = key?.replace(/\s/g, '')
  
  const response = await getPresignedUrl(keyWithoutSpaces, contentType || "");

  if(response?.key && response?.url) {
    const res = await uplodaFromPresignedURL(response.url, formDataImage, contentType || "")

    result.imageKey = response.key
    result.status = res?.status || 0

  } 

  handleLoading(false);

  return result
}