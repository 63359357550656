import styled from 'styled-components'
import { Switch } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

export const Container = styled.div`
  flex: 1;
  min-height: 90%;
`


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 12,
    },
    actionBarLeftContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: 24,
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    pageSubtitle: {
      color: "#252525",
      fontSize: 20,

      marginLeft: theme.spacing(2),
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: 12
    },
    formContainer: {
      padding: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
    inputLabel: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    buttonBack: {
      borderRadius: "20%",
      color: "#FFF",
      backgroundColor: "#2F4D7C",
      transition: ".5 ease",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
    },
    buttonSave: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonDiscard: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      //marginLeft: theme.spacing(2),
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#FF3C40",
      "&:hover": {
        backgroundColor: "rgba(255,60,64,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonConfirm: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      marginLeft: 8,
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#fff",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2EA949",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "rgba(46,169,73,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonCreateStep: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonExport: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      marginRight: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#76A1E8",
      "&:hover": {
        backgroundColor: "#6991CF",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    stepContainer: {
      padding: 4,
    },
    stepTitle: {
      color: "#2F4D7C",
      fontWeight: "bold",
    },
    inputLabelStep: {
      marginBottom: theme.spacing(1),
    },
    switchContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    switchText: {
      color: "#1D1D1D",
      marginLeft: theme.spacing(1),
      marginRight: 10,
    },
    buttonTest: {
      display: "flex",

      width: 180,
      fontSize: 12,
      fontWeight: "bold",

      marginTop: 4,

      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 24,
      paddingLeft: 24,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonTestFab: {
      display: "flex",

      width: 180,
      fontSize: 14,
      fontWeight: "bold",

      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 24,
      paddingLeft: 24,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
  })
);